.announcement-bar {
  background: #a1decd;
}
.announcement-bar .message {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: #232323;
  padding: 7px 15px;
}
.announcement-bar .message a {
  color: #232323;
}
.announcement-bar .message a:hover {
  text-decoration: underline;
  text-underline-offset: 0.2rem;
}
.header-4,
.header-4 .header-mobile {
  background: #ffffff;
  padding: 5px 0;
}
.header-4 .container {
  position: initial;
}
.header-4 .menu-dropdown {
  padding: 28px 0 17px;
}
.header-4 .header-wrapper {
  display: none;
}
.header-4 .header__logo {
  position: relative;
  top: -2px;
}
.header-4 .header-center .menu-lv-item .menu-lv-1__action {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #000000;
  text-transform: uppercase;
  padding: 30px 16px 28px;
}
.header-4 .header-center .menu-lv-item .menu-lv-1__action .label {
  right: -4px;
  top: 10px;
  border-radius: 0;
}
.header-4 .header-center .site-nav-title {
  color: #232323;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.header-4 .header-center .site-nav-link {
  font-size: 16px;
  color: #232323;
}
.header-4 .header-center .megamenu_style_2 .site-nav-banner .site-nav-banner--item {
  width: 100%;
}
.header-4 .header-center .megamenu_style_2 .site-nav-banner .site-nav-banner--item .banner {
  margin-bottom: 20px;
}
.header-4 .header-center .list-menu--disclosure li a {
  font-size: 16px;
}
.header-4 .header-right .header__iconItem .header__icon {
  width: 22px;
  height: 22px;
  margin: 0 12px;
}
.header-4 .header-right .header__iconItem .header__icon svg {
  width: 100%;
  height: 100%;
  fill: #000;
}
.header-4 .header-right .header__iconItem .header__icon.header__icon--cart {
  width: 25px;
  height: 25px;
}
.header-4 .header-right .header__iconItem .header__icon.hamburger-icon {
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 0;
  margin-left: 7px;
}
.header-4 .header-right .header__iconItem .header__icon.hamburger-icon .icon-line {
  background-color: #fff;
  width: 24px;
  height: 2px;
  margin: 3px 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.header-4 .header-right .header__iconItem .header__icon.hamburger-icon .icon-line::after,
.header-4 .header-right .header__iconItem .header__icon.hamburger-icon .icon-line::before {
  background-color: #fff;
  width: 24px;
  height: 2px;
  margin: 3px 0;
  position: absolute;
  left: 0;
  right: 0;
  content: '';
  -webkit-transition: -webkit-transform 0.3s 0.2s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  transition-delay: 0.2s;
}
.header-4 .header-right .header__iconItem .header__icon.hamburger-icon .icon-line::before {
  top: 5px;
}
.header-4 .header-right .header__iconItem .header__icon.hamburger-icon .icon-line::after {
  top: 12px;
}
.header-4 .header-right .header__iconItem .header__icon.hamburger-icon:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
.header-4 .header-right .header__iconItem .header__icon .cart-count-bubble {
  position: absolute;
  right: -9px;
  top: -10px;
  background: #232323;
}
.header-4 .header-right .header__iconItem .header__icon .cart-count-bubble .text-count {
  color: #fff;
  font-size: 14px;
  letter-spacing: 0;
}
.header-4 .header-mobile .header-mobile--icon .mobileMenu-toggle {
  color: #000;
}
.header-4 .header-mobile .header-mobile--icon .mobileMenu-toggle__Icon,
.header-4 .header-mobile .header-mobile--icon .mobileMenu-toggle__Icon::after,
.header-4 .header-mobile .header-mobile--icon .mobileMenu-toggle__Icon::before {
  background-color: #000;
}
.header-4 .header-mobile .header-mobile--icon svg {
  fill: #000;
}

.header-4 .header-mobile .header-mobile--icon .header__icon--cart .cart-count-bubble {
  background: #232323;
  color: #fff;
}

.header-4 .header-mobile .header-mobile--icon .header__icon--cart .cart-count-bubble span {
  color: #fff;
}

@media (min-width: 1025px) {
  .header-4 .header-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
  }
}
@media (min-width: 1200px) {
  .header-4 .header-left {
    width: 140px;
  }
}

.header-mobile .header-mobile--icon + .header-mobile--icon {
  margin-left: 10px;
}

.header-mobile--icon.item-mobile-logo img {
  width: 80%;
  margin: 0 auto;
}

body.search-popup-show .halo-search-popup {
  z-index: 999 !important;
}

/* body.search-popup-show .halo-search-popup{
    height: 100%;
    background: #00000085;
} */
