body.edit-cart-show .background-overlay-popup {
  background-color: rgba(35, 35, 35, 0.8) !important;
}
.page-cart {
  padding-bottom: 50px;
}
.page-cart .haloCalculatorShipping {
  padding: 0;
}
.page-cart .haloCalculatorShipping .progress-meter {
  background-color: #f44336 !important;
}
.page-cart .haloCalculatorShipping .message {
  color: #3c3c3c;
}
.page-cart .cart-countdown {
  background-color: #fbf8e5;
  padding: 13px 15px;
  margin-top: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.page-cart .cart-countdown .icon {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 10px 0 0;
  fill: currentColor;
  stroke: currentColor;
  color: #9d8766;
}
.page-cart .cart-countdown .count_down {
  color: #9d8766;
}
.page-cart .cart-countdown #time {
  font-weight: 700;
}
.page-cart .cart-page-wrapper {
  margin-top: 35px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: minmax(800px, 1fr) 1fr;
  grid-template-columns: minmax(800px, 1fr) 1fr;
  width: 100%;
}
.page-cart .cart-page-wrapper .cart-items .cart-rowHeading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #fafafa;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 0 16px 18px;
}
.page-cart .cart-page-wrapper .cart-items .cart-rowHeading .cart-rowItem {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #232323;
  margin-bottom: 0;
  cursor: pointer;
}
.page-cart .cart-page-wrapper .cart-items .cart-rowHeading .cart-product {
  padding-right: 41.5%;
}
.page-cart .cart-page-wrapper .cart-items .cart-rowHeading .cart-price {
  padding-right: 14.45%;
}
.page-cart .cart-page-wrapper .cart-items .cart-rowHeading .cart-qty {
  padding-right: 12.8%;
}
.page-cart .cart-page-wrapper .cart-items .previewCartItem {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 15.125% 35.45% 14.125% 22.5% 7% auto;
  grid-template-columns: 15.125% 35.45% 14.125% 22.5% 7% auto;
  border: 1px solid #e6e6e6;
  padding: 25px;
  margin-top: 10px;
}
.page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-image {
  width: 90px;
  height: 120px;
}
.page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-image img {
  width: 100%;
  height: 100%;
}
.page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-content {
  width: 100%;
  max-width: 210px;
  font-size: 12px;
  letter-spacing: 0.02em;
  line-height: 20px;
  font-weight: 400;
  padding-top: 5px;
}
.page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-content a {
  color: #232323;
}
.page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-content .previewCartItem-options {
  line-height: 25px;
  color: #969696;
  margin-top: 8px;
}
.page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-content .previewCartItem-options svg {
  fill: #969696;
  width: 16px;
  height: 16px;
  margin-left: 8px;
  position: relative;
  top: -4px;
}
.page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-content .previewCartItem-options svg:hover {
  fill: #232323;
}
.page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-content .card-vendor {
  margin-top: 3px;
}
.page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-content .card-vendor a {
  color: #969696;
}
.page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-price {
  font-size: 14px;
  font-weight: 500;
  color: #232323;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.page-cart .cart-page-wrapper .cart-items .previewCartItem .quantity {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.page-cart .cart-page-wrapper .cart-items .previewCartItem .quantity .previewCartItem-qty {
  width: 110px;
  margin-top: 0;
}
.page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartTotals {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}
.page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-remove {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 27px;
}
.page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-remove svg,
.page-cart .cart-page-wrapper .shipping-wrapper .icon {
  width: 16px;
  height: 16px;
}
.page-cart .cart-page-wrapper .cart-items .gift-card-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 26px;
}
.page-cart .cart-page-wrapper .cart-items .gift-card-container .gift-card {
  position: relative;
  top: -3px;
}
.page-cart .cart-page-wrapper .cart-items .gift-card-container .gift-card svg {
  width: 18px;
  height: 18px;
  margin-right: 12px;
}
.page-cart .cart-page-wrapper .cart-items .gift-card-container .button {
  padding: 5px 0;
  text-align: center;
  min-width: 52px;
  font-weight: 600;
  letter-spacing: 0.02em;
}
.page-cart .cart-page-wrapper .cart-items .gift-card-container .content-gift {
  line-height: 25px;
  color: #464646;
  margin-right: 10px;
}
.page-cart .cart-page-wrapper .cart-items .cart-note label,
.page-cart .cart-page-wrapper .cart-items .gift-card-container .content-gift .only,
.page-cart .cart-page-wrapper .cart-total-shipping .cart-total-label .desc,
.page-cart .cart-page-wrapper .cart-total-subtotal .cart-total-label {
  font-weight: 600;
}
.page-cart .cart-page-wrapper .cart-items .cart-note {
  margin-top: 24px;
}
.page-cart .cart-page-wrapper .cart-items .cart-note .field__input,
.page-cart .cart-page-wrapper .cart-items .cart-note .form-input {
  min-height: 97px;
  border: none;
  -webkit-box-shadow: 0 0 0 0.1rem #c7c7c7 !important;
  box-shadow: 0 0 0 0.1rem #c7c7c7 !important;
  padding: 9px 0 0 11px;
}
.page-cart .cart-page-wrapper .cart-items .cart-note ::-webkit-input-placeholder {
  color: #3c3c3c;
}
.page-cart .cart-page-wrapper .cart-items .cart-securityImage .security-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 25px;
}
.page-cart .cart-page-wrapper .cart-items .cart-securityImage .security-content svg {
  width: 22px;
  height: 22px;
  margin-right: 4px;
}
.page-cart .cart-page-wrapper .cart-items .cart-securityImage .security-content .desc {
  color: #3c3c3c;
  line-height: 18px;
}
.page-cart .cart-page-wrapper .cart-items .cart-securityImage .list-unstyled {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
}
.page-cart .cart-page-wrapper .cart-items .cart-securityImage .list-unstyled li {
  margin-right: 20px;
}
.page-cart .cart-page-wrapper .cart-content-item.cart-total {
  margin-left: 30px;
}
.page-cart .cart-page-wrapper .shipping-wrapper .shipping-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.page-cart .cart-page-wrapper .shipping-wrapper .dropdown-shipping {
  width: calc(100% + 2px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  background: #fff;
  position: absolute;
  top: 10px;
  left: -1px;
  opacity: 0;
  z-index: -1;
  overflow: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: 0 1px 4px #00000026;
  box-shadow: 0 1px 4px #00000026;
}
.page-cart .cart-page-wrapper .shipping-wrapper .dropdown-shipping.show {
  top: calc(100% + 10px);
  opacity: 1;
  z-index: 9;
  overflow: visible;
}
.page-cart .cart-page-wrapper .shipping-wrapper .dropdown-shipping .dropdown-item.active {
  background-color: #e9ecef;
  color: #232323;
}
.page-cart .cart-page-wrapper .shipping-wrapper .disclosure {
  position: relative;
}
.page-cart .cart-page-wrapper .shipping-wrapper .disclosure__list {
  width: 100%;
  z-index: 2;
  background: #fff;
}
.page-cart .cart-page-wrapper .shipping-wrapper .disclosure__button {
  border: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: transparent;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 9px 12px;
}
.page-cart .cart-page-wrapper .shipping-wrapper .icon svg {
  width: 16px;
  height: 16px;
  stroke: initial;
  vertical-align: top;
  fill: #3c3c3c;
}
.page-cart .cart-page-wrapper .shipping-wrapper .text {
  color: #3c3c3c;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.02em;
}
.page-cart .cart-page-wrapper .shipping-wrapper .text + svg {
  width: 10px;
  height: 10px;
}
.page-cart .cart-page-wrapper .shipping-wrapper .dropdown-toggle::after {
  display: none;
}
.page-cart .cart-page-wrapper .list-unstyled {
  margin-bottom: 14px;
}
.page-cart .cart-page-wrapper .list-unstyled .cart--totals-title {
  font-weight: 600;
  letter-spacing: 0.05em;
  /* padding: 7px 0; */
  /* border-bottom: 2px solid #3c3c3c; */
  text-transform: uppercase;
}
.page-cart .cart-page-wrapper .cart-total-subtotal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  /* padding: 30px 0 12px; */
  padding: 15px 20px;
  border-bottom: 1px solid #e6e6e6;
}
.page-cart .cart-page-wrapper .cart-total-subtotal .previewCartTotals-label {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #232323;
  letter-spacing: 0.02em;
}
.page-cart .cart-page-wrapper .cart-total-subtotal .money {
  font-size: 16px;
  font-weight: 600;
  color: #232323;
}
.page-cart .cart-page-wrapper .cart-total-subtotal .cart-total-value {
  font-size: 16px;
  font-weight: 600;
}
.page-cart .cart-page-wrapper .cart-total-shipping .cart-total-label {
  padding: 11px 0 14px;
}
.page-cart .cart-page-wrapper .cart-total-shipping .cart-city .shipping-wrapper,
.page-cart .cart-page-wrapper .cart-total-shipping .cart-nation {
  border: 1px solid #cbcbcb;
}
.page-cart .cart-page-wrapper .cart-total-shipping .cart-city {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 15px 0;
  width: 100%;
}
.page-cart .cart-page-wrapper .cart-total-shipping .cart-city .shipping-wrapper .disclosure__button {
  width: 100%;
  min-width: 200px;
}
.page-cart .cart-page-wrapper .cart-total-shipping .cart-city input#address_zip {
  width: 100%;
}
.page-cart .cart-page-wrapper .cart-total-shipping .cart-city .form-group {
  margin-left: 15px;
}
.page-cart .cart-page-wrapper .cart-total-shipping .cart-city .form-group input {
  border: 1px solid #cbcbcb;
  background: #fff;
  color: #232323;
  padding: 10px 12px;
}
.page-cart .cart-page-wrapper .cart-total-shipping #get-rates-container .button {
  max-width: 100%;
  width: 100%;
}
.page-cart .cart-page-wrapper .cart-total-shipping .sub-shipping {
  margin-top: 15px;
}
.page-cart .cart-page-wrapper .cart-total-shipping .sub-shipping .message-shipping {
  background-color: #fbf8e5;
  padding: 20px;
}
.page-cart .cart-page-wrapper .cart-total-shipping .sub-shipping .message-shipping .desc {
  line-height: 22px;
  color: #8b714a;
}
.page-cart .cart-page-wrapper .cart-total-shipping .sub-shipping .message-shipping .standard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5px;
}
.page-cart .cart-page-wrapper .cart-total-shipping .sub-shipping .message-shipping .standard-item {
  font-size: 12px;
  font-weight: 500;
  color: #8b714a;
  letter-spacing: 0.02em;
}
.page-cart .cart-page-wrapper .cart-total-shipping .sub-shipping .message-shipping .doc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #8b714a;
  position: relative;
  top: 8px;
  margin-right: 8px;
}
.page-cart .cart-page-wrapper .cart-total-shipping .sub-shipping .coupon {
  margin-top: 24px;
  border-bottom: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
}
.page-cart .cart-page-wrapper .cart-total-shipping .sub-shipping .coupon .desc {
  margin: 15px 0 8px;
  font-weight: 600;
}
.page-cart .cart-page-wrapper .cart-total-shipping .sub-shipping .coupon .sub-desc {
  margin: 8px 0 18px;
  color: #969696;
}
.page-cart .cart-page-wrapper .cart-total-grandtotal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  /* border-bottom: 1px solid #e6e6e6; */
  /* padding: 18px 0 20px; */
  padding: 15px 20px;
}
.page-cart .cart-page-wrapper .cart-total-grandtotal .previewCartTotals-label {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  font-weight: 600;
  color: #232323;
}
.page-cart .cart-page-wrapper .cart-total-grandtotal .previewCartTotals-value .money {
  font-size: 16px;
  font-weight: 700;
  color: #232323;
}
.page-cart .cart-page-wrapper .cart-total-grandtotal .cart-total-label .text {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.02em;
}
.page-cart .cart-page-wrapper .cart-total-grandtotal .cart-total-value .money {
  font-size: 16px;
  font-weight: 700;
}
.page-cart .cart-page-wrapper .cart-actions .form-field .text-checkbox {
  font-weight: 400;
}
.page-cart .cart-page-wrapper .cart-actions .form-field .text {
  position: relative;
  top: -3px;
  margin-left: 3px;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: #3c3c3c;
}
.page-cart .cart-page-wrapper .cart-actions .action-group .button {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.page-cart .cart-page-wrapper .cart-actions .action-group .button-proceed {
  margin: 2px 0 15px;
}
.page-cart .cart-page-product {
  border-top: 1px solid #e6e6e6;
  margin-top: 58px;
}
.page-cart .cart-page-product .title-product-cart {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: #232323;
  margin: 34px 0;
}
@media (max-width: 1024px) {
  .page-cart .cart-page-wrapper {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .page-cart .cart-page-wrapper .cart-content-item .content-cart-right {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
  }
  .page-cart .cart-page-wrapper .cart-content-item .content-cart-right .previewCartAction {
    padding-top: 40px;
  }
  .page-cart .cart-page-wrapper .cart-content-item.cart-total {
    margin-left: 0;
  }
  .page-cart .cart-page-wrapper .cart-items .previewCartItem {
    -ms-grid-columns: 15.125% 33.45% 15.125% 20% 12% auto;
    grid-template-columns: 15.125% 33.45% 15.125% 20% 12% auto;
  }
  .page-cart .cart-page-wrapper .cart-total-shipping .cart-city .shipping-wrapper {
    width: 100%;
    max-width: 70%;
  }
  .page-cart .cart-page-wrapper .cart-total-shipping .cart-city .form-group {
    width: 100%;
    max-width: 30%;
  }
}
@media (max-width: 768px) {
  .page-cart .cart-countdown .icon-error,
  .page-cart .cart-rowHeading {
    display: none !important;
  }
  .page-cart .cart-page-wrapper .cart-items .previewCartItem {
    -ms-grid-columns: 15.125% 35.45% 14.125% 22.5% 7% auto;
    grid-template-columns: 15.125% 35.45% 14.125% 22.5% 7% auto;
  }
  .page-cart .cart-page-wrapper .cart-content-item .content-cart-right {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
  }
}
@media (max-width: 767px) {
  .page-cart .cart-page-wrapper .cart-items .previewCartItem {
    -ms-grid-columns: 15% 50% auto;
    grid-template-columns: 15% 50% auto;
    padding: 15px;
  }
  .page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-image {
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    grid-row: 1/4;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
  }
  .page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-content {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2/3;
    margin-left: 20px;
    max-width: 210px;
  }
  .page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-price {
    -ms-grid-column: 2;
    -ms-grid-column-span: 4;
    grid-column: 2/6;
    margin: 10px 0 14px 20px;
  }
  .page-cart .cart-page-wrapper .cart-items .previewCartItem .quantity {
    -ms-grid-column: 3;
    -ms-grid-column-span: 2;
    grid-column: 3 / span 2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/2;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
  }
  .page-cart .cart-page-wrapper .cart-items .previewCartItem .quantity .previewCartItem-qty {
    left: 0;
  }
  .page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartTotals {
    display: none !important;
  }
  .page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-remove {
    -ms-grid-column: 4;
    -ms-grid-column-span: 1;
    grid-column: 4/5;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/2;
    position: relative;
  }
  .page-cart .cart-page-wrapper .cart-content-item .content-cart-right {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}
@media (max-width: 551px) {
  .page-cart .cart-page-wrapper .cart-items .previewCartItem {
    -ms-grid-columns: 110px 110px auto;
    grid-template-columns: 110px 110px auto;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
  .page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-image {
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    grid-row: 1/4;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
  }
  .page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-content {
    -ms-grid-column: 2;
    -ms-grid-column-span: 4;
    grid-column: 2/6;
    margin-left: 0;
    max-width: 300px;
  }
  .page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-price {
    -ms-grid-column: 2;
    -ms-grid-column-span: 4;
    grid-column: 2/6;
    margin-left: 0;
  }
  .page-cart .cart-page-wrapper .cart-items .previewCartItem .quantity {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2/3;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-row: 3/4;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
  }
  .page-cart .cart-page-wrapper .cart-items .previewCartItem .quantity .previewCartItem-qty {
    left: 0;
  }
  .page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartTotals {
    display: none !important;
  }
  .page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-remove {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    grid-column: 3/4;
    -ms-grid-row: 3;
    -ms-grid-row-span: 2;
    grid-row: 3/5;
    margin-left: 20px;
    position: relative;
  }
}
@media (max-width: 440px) {
  .page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-image {
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    grid-row: 1/4;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
  }
  .page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-content .previewCartItem-name .text,
  .page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-content .previewCartItem-options {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-price {
    -ms-grid-column: 2;
    -ms-grid-column-span: 4;
    grid-column: 2/6;
    margin-left: 0;
  }
  .page-cart .cart-page-wrapper .cart-items .previewCartItem .quantity {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2/3;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-row: 3/4;
  }
  .page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-remove {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    grid-column: 3/4;
    -ms-grid-row: 3;
    -ms-grid-row-span: 2;
    grid-row: 3/5;
    margin-left: 20px;
  }
}
@media (max-width: 320px) {
  .page-cart .cart-page-wrapper .cart-items .previewCartItem {
    -ms-grid-columns: 110px auto auto;
    grid-template-columns: 110px auto auto;
  }
  .page-cart .cart-page-wrapper .cart-items .previewCartItem .previewCartItem-remove {
    left: 0;
  }
}
