.product-layout-default {
  padding: 0px 0px 50px;
}

.product-layout-default .breadcrumb {
  padding: 18px 0px 30px;
}

.product-layout-default .halo-productView-right .productView-title {
  margin: 0;
}

.product-layout-default
  .halo-productView-right
  .productView-rating
  .spr-badge-starrating {
  position: relative;
  top: 1px;
}

.product-layout-default .halo-product-block {
  clear: both;
}

.product-layout-default .halo-product-block .product-header {
  border-top: 1px solid #e6e6e6;
  padding: 30px 0px 12px;
}

.product-layout-default .halo-product-block .product-header .title {
  font-size: 18px;
  letter-spacing: 0.02em;
  font-weight: 600;
  color: #232323;
}

@media (min-width: 1025px) {
  .product-layout-default .productView-information .halo-productView-right {
    width: 600px;
    padding-left: 60px;
    padding-top: 15px;
  }
  .product-layout-default .productView-information .halo-productView-left {
    width: calc(100% - 600px);
  }
  .product-layout-default
    .productView-information
    .halo-productView-left
    .productView-image
    img {
    width: 100%;
  }
  .product-layout-default .halo-product-block {
    margin-top: 50px;
  }
}
/*# sourceMappingURL=product-template-default.css.map */
