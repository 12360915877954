@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap');
@import url('component-product.css');
@import url('popup.css');
@import url('sidebar.css');
@import url('fancybox.css');
@import url('component-product.css');
@import url('page-cart.css');
@import url('product-template-default.css');
@import url('popup.css');
@import url('slick.min.css');
@import url('portfolio.css');
fieldset,
img,
legend {
  border: none;
}
.button {
  max-width: 100%;
}
a,
a:hover {
  text-decoration: none;
}
.icon,
.link .icon {
  fill: #232323;
}
.button,
label {
  line-height: 20px;
}
.slick-slider img,
body {
  visibility: visible !important;
}
.button,
svg {
  vertical-align: middle;
}
.button,
.header-language_currency .text,
table th {
  text-transform: uppercase;
}
.product-card .product-card-information .card-title,
.product-card .product-card-information .card-vendor {
  white-space: normal;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  /* letter-spacing: 0.02em; */
  font-size: 12px;
}
.break,
.card__badge .badge {
  word-break: break-word;
}
.link-underline:hover .text,
.product-card-menu .shop-now .text,
.text-underline .text {
  text-decoration: underline;
  text-underline-offset: 0.2rem;
}
.hidden,
.hide,
.no-js-inline,
.no-js:not(html),
html.no-js .no-js-hidden {
  display: none !important;
}
html.no-js .no-js:not(html) {
  display: block !important;
}
html.no-js .no-js-inline {
  display: inline-block !important;
}
:focus,
button:focus {
  outline: 0;
}
.wrapper-overlay {
  position: fixed;
  background-color: rgba(35, 35, 35, 0.8);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  display: none;
  z-index: 100;
}
.button,
.link,
svg {
  display: inline-block;
}
html {
  padding-bottom: 0 !important;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
body {
  font-size: 16px;
  color: #232323;
  background-color: #fff;
  margin: 0 auto;
  font-family: 'Nunito Sans', sans-serif;
}
img {
  height: auto;
}
.visually-hidden {
  position: absolute !important;
  overflow: hidden !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  word-wrap: normal !important;
}
.overflow-hidden {
  overflow: hidden;
}
.clearfix:after {
  content: '';
  display: block;
  clear: both;
}
.close svg {
  width: 17px;
  height: 17px;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 40px #fff inset;
  -webkit-text-fill-color: #232323;
}
input::-ms-clear,
select::-ms-expand {
  display: none;
}
input[type='search']::-webkit-search-cancel-button {
  display: none;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
input[type='checkbox'],
input[type='radio'] {
  display: none;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
input[type='checkbox'] + .form-label--checkbox,
input[type='radio'] + .form-label--radio {
  display: block;
  width: 100%;
  position: relative;
  padding: 0 0 0 23px;
  margin: 0;
  pointer-events: auto;
  cursor: pointer;
}
input[type='checkbox'] + .form-label--checkbox:before {
  content: '';
  position: absolute;
  top: 3px;
  left: 0;
  border: 1px solid #dbdbdb;
  width: 15px;
  height: 15px;
  background-color: transparent;
  border-radius: 0;
}
input[type='checkbox'] + .form-label--checkbox:after {
  content: '';
  width: 9px;
  height: 5px;
  position: absolute;
  opacity: 0;
  top: 6px;
  left: 3px;
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  background: rgba(255, 255, 255, 0);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: 0.1s;
  transition: 0.1s;
  -webkit-appearance: none;
}
input[type='checkbox']:checked + .form-label--checkbox:before {
  background-color: #000;
  border-color: #000;
}
input[type='checkbox']:checked + .form-label--checkbox:after {
  opacity: 1;
}
input[type='radio'] + .form-label--radio:before {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  -webkit-transition: 50ms ease-out;
  transition: 50ms ease-out;
  background-color: transparent;
  border: 1px solid #e6e6e6;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
input[type='radio']:checked + .form-label--radio:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
input[type='radio'] + .form-label--radio:after {
  content: '';
  display: block;
  position: absolute;
  top: 6px;
  left: 4px;
  -webkit-transition: 50ms ease-out;
  transition: 50ms ease-out;
  width: 0;
  height: 0;
  border: 4px solid #000;
  background-color: #000;
  border-radius: 50%;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}
input[type='number'] {
  -moz-appearance: textfield !important;
}
blockquote {
  border: none;
  background-color: #fafafa;
  color: #3c3c3c;
  font-size: 12px;
  font-style: normal;
  margin: 0;
  padding: 20px 35px;
}
select,
select + .icon-dropdown {
  background-color: rgba(255, 255, 255, 0);
}
input[type='image'] {
  width: auto;
}
input[type='color'],
input[type='date'],
input[type='datetime-local'],
input[type='datetime'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
textarea {
  margin: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.field__input,
.form-input,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
}
input[type='color'],
input[type='date'],
input[type='datetime-local'],
input[type='datetime'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'] {
  padding: 11px 12px 10px;
  line-height: 22px;
}
.custom-radio input[type='radio'],
input[type='checkbox'] {
  vertical-align: top;
  margin-right: 10px;
  display: none;
}
input[type='checkbox'] + label {
  padding-left: 26px;
  cursor: pointer;
  margin-bottom: 9px;
  position: relative;
}
input[type='checkbox'] + label:before {
  position: absolute;
  content: '';
  top: 2.5px;
  left: 0;
  border: 1px solid #d0d0d0;
  width: 14px;
  height: 14px;
  background-color: #fff;
}
input[type='checkbox'] + label:after {
  content: '';
  width: 9px;
  height: 5px;
  position: absolute;
  top: 6px;
  left: 3px;
  border: 2px solid #232323;
  border-top: none;
  border-right: none;
  background: rgba(255, 255, 255, 0);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: 0.1s;
  transition: 0.1s;
}
input[type='checkbox']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0) rotate(-45deg);
  transform: scale(0) rotate(-45deg);
}
input[type='checkbox']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1) rotate(-45deg);
  transform: scale(1) rotate(-45deg);
}
select {
  border-radius: 0;
  border: 1px solid #cbcbcb;
  display: block;
  width: 100%;
  padding: 7px 32px 5px 12px;
  outline: 0;
  position: relative;
  z-index: 2;
  appearance: none;
  min-height: 36px;
}
.form-success,
table {
  border: 1px solid #ebebeb;
}
select * {
  outline: 0;
  background: #fff;
  color: #3c3c3c;
  border: none;
}
select + .icon-dropdown {
  width: 30px;
  position: absolute;
  bottom: 0;
  right: 2px;
  height: 36px;
  top: inherit;
  z-index: 1;
  display: -moz-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
select + .icon-dropdown .fa {
  font-size: 16px;
}
label em {
  color: #f1152f;
  font-weight: 500;
  font-style: italic;
}
label.hiddenLabel {
  display: none;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.field__input,
.form-input {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  appearance: none;
}
ol,
ul {
  padding: 0;
  list-style: none;
  margin-bottom: 20px;
}
ol li,
ul li {
  /* margin-bottom: 0.25em; */
}
a:hover {
  color: #232323;
  outline: 0;
}
.errors ul {
  padding-left: 0;
}
.form-success {
  margin-bottom: 20px;
  padding: 5px 20px 5px 30px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: 1.25em;
  margin: 0;
  padding: 0;
}
h4,
h5,
h6 {
  margin-bottom: 1.5em;
}
h2 {
  font-size: 2em;
  margin-bottom: 1em;
}
h3 {
  font-size: 20px;
  margin-bottom: 1.25em;
}
h4 {
  font-size: 17px;
}
h5 {
  font-size: 15px;
}
h6 {
  font-size: 1em;
}
p:last-child,
p:last-of-type {
  margin-bottom: 0;
}
small {
  font-size: 12px;
}
table {
  min-width: 100%;
}
table td,
table th {
  border-bottom: 1px solid #ebebeb;
  padding: 9px 15px;
}
table th {
  font-size: 11px;
  font-weight: 700;
}
.button.disabled,
.button:disabled,
.button[aria-disabled='true'],
.button[disabled],
button.disabled,
button:disabled,
button[aria-disabled='true'],
button[disabled] {
  opacity: 0.6;
  cursor: default !important;
}
.pagination__list .pagination-arrow.disabled,
.slick-arrow.slick-disabled {
  opacity: 0.4;
}
.button {
  font-weight: 700;
  outline: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 0;
  border: 1px solid transparent;
  min-width: 160px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  padding: 12px 15px 11px;
  font-size: 14px;
  letter-spacing: 0.05em;
  cursor: pointer;
  text-align: center;
}
.desc,
.link {
  font-weight: 400;
}
a,
hr {
  border: none;
}
.breadcrumb a,
.desc,
.field__input,
.form-input,
label {
  letter-spacing: 0.02em;
}
.button.button-1,
.button.button-2:hover,
.button.button-3:hover {
  background-color: #232323;
  border-color: #232323;
  color: #fff;
}
.button.button-1:hover {
  background-color: #fff;
  border-color: #232323;
  color: #232323;
}
.button.button-2 {
  background-color: #fff;
  border-color: #727272;
  color: #232323;
}
.button.button-3 {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
  width: 270px;
  height: 50px;
}
.left,
.text-left {
  text-align: left;
}
.center,
.text-center {
  text-align: center;
}
.right,
.text-right {
  text-align: right;
}
.uppercase {
  text-transform: uppercase !important;
}
.lowercase {
  text-transform: lowercase !important;
}
.capitalize {
  text-transform: capitalize !important;
}
.content-box--absolute {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 15px;
  margin: 0 auto;
  width: 100%;
}
.content-box--full {
  max-width: 100% !important;
}
.display-empty:empty,
br:empty,
circle:empty,
hr:empty,
iframe:empty,
img:empty,
input:empty,
line:empty,
model-viewer:empty,
path:empty,
polyline:empty,
rect:empty,
shopify-installments-modal:empty,
shopify-payment-terms:empty,
textarea:empty,
use:empty {
  display: block;
}
a {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.link {
  vertical-align: top;
  line-height: 22px;
}
.link-underline .text,
.menu-lv-item .text {
  -webkit-transition: -webkit-text-decoration 0.1s;
  transition: text-decoration 0.1s;
  transition: text-decoration 0.1s, -webkit-text-decoration 0.1s;
}
.image-adapt {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}
hr {
  height: 0.1rem;
  display: block;
  background-color: #ebebeb;
}
details > * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.megamenu-custom-product-width-banner .product-card .card-action,
.visibility-hidden {
  visibility: hidden;
}
body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-track {
  background-color: transparent;
}
body::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 0;
}
body::-webkit-scrollbar-thumb:hover {
  background-color: #e0e0e0;
}
body:hover::-webkit-scrollbar-thumb {
  background-color: #aeaeae;
}
.disable-scrollbar::-webkit-scrollbar {
  display: none;
}
.disable-scrollbar::-webkit-scrollbar-track {
  display: none;
}
.disable-scrollbar::-webkit-scrollbar-thumb {
  display: none;
}
.disable-scrollbar::-webkit-scrollbar-thumb:hover {
  display: none;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  background-color: #f4f4f4;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #aeaeae;
}
.custom-scrollbar-x::-webkit-scrollbar {
  height: 3px;
}
.custom-scrollbar-x::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}
.custom-scrollbar-x::-webkit-scrollbar-thumb {
  background-color: #aeaeae;
}
:focus-visible {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
:focus,
:focus:not(:focus-visible) {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.focus-inset:focus-visible {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.focus-inset:focus,
.focus-inset:focus:not(:focus-visible) {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.focus-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0 !important;
}
svg {
  fill: currentColor;
  stroke: currentColor;
  fill: currentColor;
  stroke: currentColor;
  width: 1rem;
  height: 1rem;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.icon {
  stroke: transparent;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.container,
.img-box .image {
  display: block;
  position: relative;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex-all-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
}
.breadcrumb.position-center,
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.align-center,
.wrapper-mega-banner .halo-col-banner .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
/* .row {
  margin-left: -5px;
  margin-right: -5px;
  padding: 0;
} */

.row.column-5 .halo-row-item {
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
}
.product-carousel .product-item,
.row .halo-item {
  padding: 0 5px;
}
.row .halo-row-item {
  position: relative;
  padding: 0 5px;
}
.col5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}
.desc {
  line-height: 2;
  margin: 0;
}
.container {
  width: 100%;
  max-width: 98%;
  padding: 0 15px;
  margin: 0 auto;
}
.container.container-full {
  max-width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  overflow-x: hidden;
}
.container-1920 {
  max-width: 1920px;
  padding-left: 0;
  padding-right: 0;
}
.img-box .image {
  overflow: hidden;
}
.has-stickyHeader .header-default .header-top,
.has-stickyHeader .header-default .menu-lv-1 .label,
.header-default .header-bottom .Header-icon-fixed,
.header-language_currency .dropdown-toggle::after,
.img-box--mobile .image {
  display: none;
}
.fullwidth-banner .img-box,
.halo-block-video,
.halo-toolbar-bottom-mobile .halo-toolbar-wrapper .halo-toolbar-item,
.header-language_currency .disclosure,
.page-header,
.product-card .product-card-top {
  position: relative;
}
.has-stickyHeader .header-default .header-bottom .Header-icon-fixed,
.has-stickyHeader .header-default .header-bottom .header-log-fixed,
.img-box--mobile .image + .image {
  display: block;
}
[data-banner-carousel] .row:not(.slick-slider) {
  overflow: hidden;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.page {
  padding-bottom: 30px;
}
.page-header .page-title {
  margin-bottom: 22px;
  font-size: 24px;
  letter-spacing: 0.05em;
  color: #232323;
}
.page-header .content-box--absolute .breadcrumb {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  padding: 18px 0 0;
  margin: 0;
  letter-spacing: 0.02em;
}
.page-header .content-box--absolute .breadcrumb .separate svg {
  color: #fff;
  fill: #ffffff;
}
.menu-lv-1:hover > a:hover,
.page-header .content-box--absolute .breadcrumb a {
  color: #fff;
}
.page-header .content-box--absolute .page-title {
  color: #fff;
  padding: 12px 0;
}
.breadcrumb {
  color: #999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  padding: 18px 0 11px;
}
.breadcrumb a:hover,
label {
  color: #232323;
}
.breadcrumb a {
  line-height: 24px;
  color: #999;
}
.breadcrumb .separate {
  display: block;
  position: relative;
  padding: 0 7px;
}
.breadcrumb .separate svg {
  width: 9px;
  height: 10px;
  position: relative;
  top: -1px;
  color: #999;
  fill: #999999;
}
.contact-form .form-field {
  margin-top: 17px;
}
.field__label,
.form-label {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0 0 9px;
}
label {
  font-weight: 500;
  text-align: left;
}
.contact-form .form-input {
  padding-top: 10px;
  padding-bottom: 10px;
}
.field__input,
.form-input {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: block;
  width: 100%;
  height: auto;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  border: 1px solid #c7c7c7;
  color: #232323;
  background-color: #fff;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  outline: 0 !important;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0;
  overflow: hidden;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.red-asterisk {
  color: #f1152f;
  margin-left: 3px;
}
.slick-arrow {
  width: 35px;
  height: 35px;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  color: #323232;
  position: absolute;
  cursor: pointer;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9;
  outline: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  padding: 9px !important;
}
.slick-arrow:hover {
  background-color: #323232;
  color: #fff;
  border-color: #232323;
}
.slick-arrow:hover svg {
  fill: #ffffff;
  color: #fff;
}
.slick-arrow svg {
  width: 100%;
  height: 100%;
  color: #323232;
  fill: #323232;
  stroke: transparent;
}
.slick-arrow.slick-next {
  right: -10px;
}
.slick-arrow.slick-next svg {
  position: relative;
  top: -4px;
  left: 1px;
}
.slick-arrow.slick-prev {
  left: -10px;
}
.slick-arrow.slick-prev svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  position: relative;
  top: -4px;
  right: 1px;
}
.slick-dots {
  display: block;
  padding: 0;
  margin: 0 0 15px;
  text-align: center;
  letter-spacing: -0.33em;
  line-height: 0;
}
.slick-dots li {
  display: inline-block;
  font-size: 0px;
  margin: 0 7px;
  width: 12px;
  height: 12px;
  letter-spacing: normal;
}
.slick-dots li.slick-active button {
  background: rgba(255, 255, 255, 0);
  border-color: #505656;
}
.slick-dots button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #707979;
  background: #707979;
  padding: 0;
  outline: 0;
  cursor: pointer;
}
.halo-block-header {
  margin-bottom: 20px;
}
.halo-block-header .title {
  color: #232323;
  position: relative;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 7px;
  letter-spacing: 0.05em;
}
.halo-block-header .title .text {
  padding: 0 20px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  z-index: 2;
  max-width: 100%;
  background: #fff;
}
.halo-block-header .view_all {
  display: inline-block;
  border-bottom: 1px solid !important;
  line-height: 16px;
  color: #232323;
  font-weight: 500;
  margin-bottom: 20px !important;
  font-size: 12px;
  letter-spacing: 0.02em;
}
.halo-block-header .title:before {
  content: '';
  display: block;
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #232323;
}
.background-overlay,
.background-overlay-popup {
  position: fixed;
  background-color: rgba(35, 35, 35, 0.8);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  display: block;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.pagination__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.pagination__list .pagination-arrow:first-child {
  margin-right: 10px;
}
.pagination__list li {
  min-width: 4rem;
}
.pagination__list .pagination-arrow {
  position: relative;
  width: 35px;
  min-width: 35px;
}
.pagination__list .pagination__item-arrow {
  color: #232323;
  background: #fff;
  border: 1px solid #232323;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1;
  font-size: 0;
  letter-spacing: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  padding: 10px;
  width: 35px;
  height: 35px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  outline: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 50%;
}
.pagination__list .pagination__item-arrow .icon {
  fill: #3c3c3c;
  width: 100%;
  height: 100%;
  color: #323232;
  stroke: transparent;
}
.pagination__list .pagination__item-arrow:hover {
  color: #fff;
  background: #232323;
}
.pagination__list .pagination__item-arrow:hover .icon {
  fill: #ffffff;
}
.pagination__list .pagination__item--prev .icon {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.pagination__list .pagination__item--next .icon {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.pagination__list .pagination-num .pagination__item {
  color: #707070;
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.05s;
  transition: 0.05s;
}
.pagination__list .pagination-num .pagination__item.active,
.pagination__list .pagination-num .pagination__item:hover {
  background-color: #f8f8f8;
  color: #3c3c3c;
  font-weight: 600;
}
.footer-block__list-social,
.halo-product-block {
  margin-top: 35px;
}
.halo-product-block .halo-block-header {
  margin-bottom: 15px !important;
}
.halo-product-block .product-infinite-scroll {
  margin: 25px 0 70px;
}
.halo-product-block .product-infinite-scroll .button {
  max-width: 300px;
  width: 100%;
}
.halo-block-video .video-banner {
  position: absolute;
  bottom: 40px;
  right: 40px;
  z-index: 9;
}
.halo-block-video .video-banner .halo-modal-close {
  position: absolute;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  top: 60px;
  bottom: auto;
  right: 15px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.halo-block-video .video-banner .halo-modal-close svg {
  width: 28px;
  height: 28px;
  fill: #ffffff;
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 5px;
  color: #fff;
  background: #232323;
}
.halo-block-video .video-banner .modal-content-video,
.halo-block-video .video-banner .modal-video-content,
.halo-block-video .video-banner .video {
  height: 100%;
}
.halo-block-video .video-banner.open_video {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0 !important;
}
.halo-block-video .video-banner.open_video .halo-modal-close {
  top: 15px;
  z-index: 9;
  opacity: 1;
  visibility: visible;
}
.halo-block-video .video-banner.fixed_video {
  position: fixed;
  top: inherit !important;
  right: 0 !important;
  bottom: 0;
  max-width: 350px;
  max-height: 250px;
}
.halo-block-video .video-banner.fixed_video .halo-modal-close {
  top: 5px;
  right: 5px;
}
.halo-block-video .video-banner.fixed_video .halo-modal-close svg {
  width: 24px;
  height: 24px;
}
.video_icon {
  position: absolute;
  right: 64px;
  bottom: 48px;
  z-index: 9;
}
.video_icon svg {
  width: 40px;
  height: 40px;
  fill: #232323;
}
.video_icon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 50%;
  opacity: 0.3;
  background-color: #fff;
  -webkit-animation: 1.4s ease-in-out infinite zoom-animation;
  animation: 1.4s ease-in-out infinite zoom-animation;
}
@media (max-width: 1200px) {
  .slick-arrow.slick-next {
    right: 10px;
  }
  .slick-arrow.slick-prev {
    left: 10px;
  }
}
@media (max-width: 992px) {
  .halo-row--swipe {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 15px;
    margin-left: -15px;
    margin-right: -15px;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .halo-row--swipe .halo-row-item {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    margin-right: 15px;
    white-space: normal;
    width: 350px;
  }
  .halo-row--swipe .halo-row-item:last-child {
    margin-right: 0;
  }
}
@media (max-width: 768px) {
  .halo-row--swipe .halo-row-item {
    width: 330px;
  }
}
@media (min-width: 768px) {
  .img-box--mobile .image {
    display: block;
  }
  .img-box--mobile .image + .image {
    display: none;
  }
  .content-box--absolute {
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding-left: 0;
    padding-right: 0;
    max-width: 520px;
  }
  .content-box--left {
    left: 15px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .content-box--center {
    left: 50%;
  }
  .content-box--bottom {
    top: inherit;
    bottom: 0;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  .content-box--right {
    left: inherit;
    right: 15px;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
  .row {
    /* margin-left: -10px; */
    /* margin-right: -10px; */
  }
  .product-carousel .product-item,
  .row .halo-item,
  .row .halo-row-item,
  [data-banner-text-carousel] .product-item {
    padding: 0 10px;
  }
  .row.column-5 .halo-row-item {
    width: 33.333333%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .product-carousel .row:not(.slick-slider),
  [data-banner-text-carousel] .row:not(.slick-slider) {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow: hidden;
  }
  .product-carousel .row:not(.slick-slider) .slick-list,
  [data-banner-text-carousel] .row:not(.slick-slider) .slick-list {
    padding: 0;
  }
  .halo-block-header {
    margin-bottom: 45px;
  }
}
.header-default .header-bottom .Header-icon-fixed .cart-count-bubble,
.header__iconItem .header__icon--cart .cart-count-bubble {
  background-color: #ffe5e8;
  color: #323232;
  text-align: center;
  font-weight: 400;
  line-height: 22px;
  border-radius: 50%;
  min-width: 23px;
  min-height: 23px;
}
@media (min-width: 1200px) {
  .container.container-1170 {
    max-width: 1200px !important;
  }
  .slick-arrow.slick-prev {
    left: 5px;
  }
  .slick-arrow.slick-next {
    right: 5px;
  }
}
@media (min-width: 1400px) {
  .container.container-1370 {
    max-width: 1400px !important;
  }
}
@media (min-width: 1500px) {
  .container.container-1470 {
    max-width: 1500px !important;
  }
}
@media (min-width: 1600px) {
  .container.container-1570 {
    max-width: 1600px !important;
  }
  .slick-arrow.slick-prev {
    left: -2px;
  }
  .slick-arrow.slick-next {
    right: -2px;
  }
}
@media (min-width: 1800px) {
  .container.container-1770 {
    max-width: 1800px !important;
  }
}
@media (min-width: 1920px) {
  .container.container-1860 {
    padding-left: calc((100% - 1860px) / 2);
    padding-right: calc((100% - 1860px) / 2);
  }
}
.announcement-bar {
  background-color: #ffe8e8;
  color: #b05d5d;
}
.announcement-bar .message {
  padding: 7px 15px;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 22px;
  margin: 0;
}
.announcement-bar .announcement-close {
  display: block;
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  line-height: 1;
  font-size: 0;
  letter-spacing: 0;
}
.announcement-bar .announcement-close svg {
  width: 100%;
  height: 100%;
  stroke: transparent;
}
.announcement-bar a {
  color: #b05d5d;
}
.has-stickyHeader [data-header-sticky] {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.12);
  z-index: 20;
  will-change: transform;
}
.header .animate {
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
}
.has-stickyHeader [data-header-sticky].sticky-up {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.has-stickyHeader .header-default .header-bottom .header-bottom--wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.has-stickyHeader .header-default .header-bottom .header-nav {
  width: calc(100% - 224px);
  padding-left: 50px;
}
.header-default .header-bottom .container {
  position: initial;
}
.header-default .header-bottom .header-log-fixed {
  display: none;
  padding-left: 15px;
}
.header-default .header-bottom .header-log-fixed img {
  max-width: 50px;
}
.header-default .header-bottom .Header-icon-fixed .header__icon-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-right: 15px;
}
.header-default .header-bottom .Header-icon-fixed .header__icon {
  position: relative;
  border-left: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 9px;
  padding-bottom: 9px;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-default .header-bottom .Header-icon-fixed .header__icon svg {
  width: 22px;
  height: 22px;
  fill: #ffffff;
}
.header-default .header-bottom .Header-icon-fixed .cart-count-bubble {
  position: absolute;
  right: 2px;
  top: 7px;
  margin: 0;
  font-size: 12px;
  letter-spacing: 0.02em;
  display: inline-block !important;
  vertical-align: middle;
}
.header-top {
  /* display: none; */
  background: #fff;
  padding: 15px 0 16px;
}
.header-top .header-top--wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.header-top-right-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}
.header__iconItem,
.header__iconItem .customer-links,
.header__iconItem .header__icon {
  display: inline-block;
  vertical-align: middle;
}
.header-top-right-group .customer-service-text {
  color: #323232;
  font-size: 12px;
  margin-right: 13px;
  letter-spacing: 0.02em;
}
.header-top--right .free-shipping-text {
  color: #232323;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.05em;
  margin-bottom: 14px;
}
.header__iconItem .header__icon {
  font-size: 0;
  letter-spacing: 0;
  line-height: 1;
  width: auto;
  position: relative;
}
.header__iconItem .header__icon span {
  font-size: 12px;
  letter-spacing: 0.02em;
  display: inline-block !important;
  vertical-align: middle;
  color: #323232;
}
.header__iconItem .header__icon svg {
  fill: #232323;
  color: #232323;
}
.header__iconItem .header__icon--cart svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  position: relative;
  top: -2px;
}
.header__iconItem .header__icon--cart .cart-count-bubble {
  position: relative;
  margin-left: 8px;
  font-size: 12px;
  letter-spacing: 0.02em;
  display: inline-block !important;
  vertical-align: middle;
}
.header__iconItem .header__icon--cart .cart-count-bubble .text-count {
  position: relative;
  top: -1px;
  left: 0;
}
.header__iconItem .header__icon--wishlist {
  margin-left: 25px;
}
.header__iconItem .header__icon--wishlist svg {
  width: 19px;
  height: 18px;
  margin-right: 8px;
}
.header__iconItem .customer-links {
  margin-left: 25px;
  padding-top: 0;
  padding-bottom: 0;
}
.header__icon:hover svg {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.header-language_currency .halo-currency {
  margin-left: 5px;
}
.header-language_currency .halo-currency .text + svg {
  position: relative;
  top: 0;
}
.header-language_currency .halo-currency .btn-group .icon {
  position: relative;
  top: 2px;
}
.header-language_currency .halo-currency .currency-menu {
  padding: 9px 0;
  min-width: 100px;
  outline: 0;
  background: #fff;
  max-height: 300px;
  overflow: auto;
}
.header-language_currency .halo-currency .currency-menu .icon {
  width: 22px;
  height: 22px;
  margin-right: 4px;
}
.header-language_currency .halo-currency .currency-menu .icon svg {
  width: 100%;
  height: 100%;
  stroke: initial;
  vertical-align: top;
}
.header-language_currency .halo-currency .currency-item {
  font-size: 12px;
  font-weight: 500;
  color: #232323;
  line-height: 22px;
  letter-spacing: 0.02em;
  padding: 7px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-language_currency .halo-currency .currency-item .text {
  position: relative;
  color: #232323;
}
.header-language_currency .halo-currency .currency-item.active,
.header-language_currency .halo-currency .currency-item:hover {
  text-decoration: underline;
  text-underline-offset: 2px;
}
.header-language_currency .halo-currency .dropdown-toggle {
  cursor: pointer;
}
.header-language_currency .halo-language .div {
  min-width: 100px;
}
.header-language_currency .halo-language .div .disclosure__list {
  padding: 8px 0;
  margin: 0;
}
.header-language_currency .halo-language .div .disclosure__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 7px 15px;
  text-decoration: none;
  line-height: 1.8;
}
.header-language_currency .halo-language .div .disclosure__link .icon {
  width: 22px;
  height: 22px;
  margin-right: 6px;
}
.header-language_currency .halo-language .div .disclosure__link .icon svg {
  width: 22px;
  height: 22px;
}
.header-language_currency .halo-language .div .disclosure__link.disclosure__link--active,
.header-language_currency .halo-language .div .disclosure__link:hover {
  text-decoration: underline;
  text-underline-offset: 2px;
}
.header-language_currency .halo-currency,
.header-language_currency .halo-language {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.header-language_currency .dropdown-currency,
.header-language_currency .dropdown-language {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background: #fff;
  position: absolute;
  right: 0;
  top: 10px;
  opacity: 0;
  z-index: -1;
  overflow: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
}
.header-language_currency .dropdown-currency.show,
.header-language_currency .dropdown-language.show {
  top: calc(100% + 10px);
  opacity: 1;
  z-index: 9;
  overflow: visible;
}
.header-language_currency .disclosure__list {
  z-index: 2;
  background: #fff;
}
.header-language_currency .disclosure__button {
  border: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: transparent;
}
.header-language_currency .icon {
  width: 16px;
  height: 16px;
}
.header-language_currency .icon svg {
  width: 16px;
  height: 16px;
  stroke: initial;
  vertical-align: top;
}
.header-language_currency .text {
  color: #232323;
  font-weight: 500;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 12px;
}
.header-language_currency .text + svg {
  width: 10px;
  height: 9px;
}
.header-search {
  /* max-width: 160px; */
  /* margin-left: 20px; */
  position: relative;
}
.header-search .search-bar {
  background-color: #fafafa;
  border-bottom: 1px solid #e7e7e7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-search .search-bar .input-group-field {
  color: #323232;
  padding: 5px 11px;
  width: calc(100% - 30px);
  border: none;
  background-color: #fff0;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
}
.header-search .search-bar .icon-search {
  background: rgba(255, 255, 255, 0);
  min-width: 30px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 8px 10px;
}
.quickSearchResultsWrap {
  position: absolute;
  top: calc(100% - 30px);
  right: 0;
  left: auto;
  width: 570px;
  z-index: -1;
  opacity: 0;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 18px 30px 0;
  max-height: calc(80vh - 150px);
  overflow-x: hidden;
  overflow-y: auto;
  visibility: hidden;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}
.quickSearchResultsWrap.show {
  top: calc(100% + 5px);
  z-index: 5;
  opacity: 1;
  visibility: visible;
}
.header .menu-dropdown,
.list-menu--disclosure {
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  webkit-transition: all 350ms ease;
}
.quickSearchResultsWrap .search-block-title {
  line-height: 22px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #232323;
  position: relative;
  margin: 0 0 15px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e8e8e8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.quickSearchResultsWrap .search-block-title .text {
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
  vertical-align: top;
  width: auto;
  padding: 0 28px 0 0;
  position: relative;
  z-index: 2;
  background-color: #fff;
}
.quickSearchResultsWrap .search-block-title .halo-search-close svg {
  width: 16px;
  height: 16px;
  position: relative;
  top: -2px;
}
.quickSearchResultsWrap .list-item {
  display: block;
  font-size: 0;
  letter-spacing: 0;
  margin: 0 -5px;
  text-align: left;
}
.quickSearchResultsWrap .list-item .item {
  display: inline-block;
  vertical-align: top;
  padding: 0 5px;
  margin: 0 0 10px;
}
.quickSearchResultsWrap .list-item .item .link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 6px 10px 6px 7px;
  text-transform: lowercase;
  background-color: #f7f7f7;
  color: #868686;
  text-decoration: unset;
}
.quickSearchResultsWrap .list-item .item .link .text {
  display: block;
  margin: 0 0 0 4px;
}
.quickSearchResultsWrap .list-item .item .link svg {
  width: 20px;
  height: 20px;
  padding: 2px;
  fill: #232323;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.quickSearchResultsWrap .list-item .item .link:hover {
  color: #232323;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}
.quickSearchResultsWrap .quickSearchProduct {
  margin-top: 13px;
}
.quickSearchResultsWrap .quickSearchProduct .halo-product-block {
  margin-top: 20px;
}
.header-bottom {
  display: none;
  background: #232323;
}
.main-menu,
.main-menu > .container,
.menu-lv-item.has-megamenu {
  position: static;
}
.header__inline-menu {
  display: block;
  grid-area: navigation;
}
.list-menu {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0;
  letter-spacing: 0;
}
.menu-lv-item {
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.menu-lv-item .site-nav-list-dropdown > a,
.menu-lv-item > a {
  display: block;
  position: relative;
}
.menu-lv-1__action {
  color: #fff;
  padding: 11px 20px 12px;
}
.menu--mobile-lv-1__action,
.menu-lv-1__action {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
.list-menu--disclosure li a,
.site-nav-link,
.site-nav-title {
  font-size: 12px;
  letter-spacing: 0.02em;
}
.menu-lv-item .icon {
  display: block;
  position: absolute;
  height: 12px;
  width: 12px;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  fill: #232323;
  stroke: transparent;
}
.menu-lv-1__action .text {
  text-decoration-thickness: 1px !important;
}
.menu-lv-item:hover > a > .text {
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}
.menu-lv-1 .label {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  right: 7px;
  top: -9px;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17px;
  height: 17px;
  padding: 0 6px;
  text-transform: capitalize;
  text-align: center;
  border-radius: 1px;
}
.menu-lv-1 .label::before {
  content: '';
  position: absolute;
  opacity: auto;
  left: 50%;
  bottom: -9px;
  -webkit-transform: translate(-50%) rotate(180deg);
  transform: translate(-50%) rotate(180deg);
  border: 5px solid transparent;
}
.menu-lv-1 .label.hot-label {
  color: #fff;
  background-color: #ffbb49;
}
.menu-lv-1 .label.hot-label::before {
  border-bottom-color: #ffbb49;
}
.menu-lv-1 .label.sale-label {
  color: #fff;
  background-color: #ef6454;
}
.menu-lv-1 .label.sale-label::before {
  border-bottom-color: #ef6454;
}
.menu-lv-1 .label.new-label {
  color: #fff;
  background-color: #06bfe2;
}
.menu-lv-1 .label.new-label::before {
  border-bottom-color: #06bfe2;
}
.list-menu--disclosure {
  width: 200px;
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  padding: 7px 0 5px;
  position: absolute;
  top: calc(100% + 20px);
  visibility: visible;
  -webkit-transition: 350ms, _ 350ms;
  transition: 350ms, _ 350ms, _ 350ms;
}
.list-menu--disclosure li {
  padding: 0 20px;
  width: 100%;
}
.list-menu--disclosure li a {
  padding: 10px 0;
  color: #232323;
}
.list-menu--disclosure li + li > a {
  border-top: 1px solid #ebebeb;
}
.list-menu--disclosure-2 {
  right: calc(-100% + 30px);
  top: 0 !important;
}
.megamenu-custom-product-width-banner {
  position: relative;
  padding: 40px 0;
  bottom: -17px;
}
.header .header-bottom .megamenu_style_5 .megamenu-product-custom::before,
.megamenu-custom-product-width-banner::before {
  content: ' ';
  width: calc(100% + 200%);
  height: 100%;
  position: absolute;
  left: -100%;
  bottom: 0;
  z-index: -1;
  background: #fff;
}
.header .header-bottom .megamenu_style_4,
.megamenu-custom-product-width-banner .product-card .card-vendor-title,
.megamenu-custom-product-width-banner .product-card .product-card-bottom {
  padding: 0 !important;
}
.megamenu-custom-product-width-banner .banner {
  padding-left: 23px;
}
.wrapper-mega-banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  bottom: -17px;
  margin-top: -5px;
}
.wrapper-mega-banner::before {
  content: ' ';
  width: calc(100% + 200%);
  height: 100%;
  position: absolute;
  left: -100%;
  bottom: 0;
  z-index: -1;
  background: #f2f2f2;
}
.wrapper-mega-banner .col-brands .title {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  color: #00163a;
  margin-bottom: 15px;
}
.wrapper-mega-banner .col-brands .azbrandstable {
  list-style: none;
  margin: 0 !important;
  max-width: 320px;
  text-align: left;
}
.wrapper-mega-banner .col-brands .azbrandstable li {
  display: inline-block;
  width: 28px;
  text-align: left;
  margin-bottom: 0;
}
.wrapper-mega-banner .col-brands .azbrandstable li a {
  font-size: 14px;
  color: #00163a;
  font-weight: 400;
}
.wrapper-mega-banner .col-brands .azbrandstable li a:hover span {
  border-bottom: 1px solid;
}
.wrapper-mega-banner .col-brands .link-brands {
  font-size: 14px;
  color: #00163a;
  font-weight: 400;
  margin-top: 13px;
  display: block;
}
.wrapper-mega-banner .halo-col-banner .content-box .sub-title {
  font-size: 12px;
  font-weight: 900;
  color: #00163a;
  margin-bottom: 11px;
}
.wrapper-mega-banner .halo-col-banner .content-box .title {
  font-size: 26px;
  font-weight: 900;
  margin-bottom: 19px;
  text-transform: uppercase;
  font-style: italic;
}
.card__badge .badge,
.product-card .product-card-information .card-title [data-change-title],
.site-nav-link {
  text-transform: capitalize;
}
.wrapper-mega-banner .halo-col-banner .content-box .link {
  font-size: 14px;
  color: #00163a;
}
.header .menu-dropdown {
  position: absolute;
  top: auto;
  left: 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 70vh;
  text-align: left;
  margin-top: 20px;
  visibility: hidden;
  -webkit-box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 24px 0 34px;
  -webkit-transition: 350ms, _ 350ms;
  transition: 350ms, _ 350ms, _ 350ms;
}
.header .menu-dropdown .list-nav-title .site-nav-title {
  margin: 0 0 12px;
}
.header .menu-dropdown .list-nav-title .site-nav-title.sale {
  color: #e10600;
}
.site-nav-title {
  font-weight: 600;
  font-style: normal;
  line-height: 22px;
  margin: 0 0 5px;
  color: #232323;
  display: block;
}
.site-nav-link {
  font-weight: 400;
  line-height: 22px;
  margin: 0;
  padding: 4px 0;
  color: #3c3c3c;
}
.megamenu_style_2 .site-nav-banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  font-size: 0;
  letter-spacing: 0;
  padding-top: 7px;
}
.megamenu_style_2 .site-nav-banner .site-nav-banner--item {
  width: 210px;
  padding: 0;
}
.megamenu_style_2 .site-nav-banner .col-left .banner + .banner {
  margin-top: 6px;
}
.megamenu_style_2 .site-nav-banner .col-right {
  margin-left: 6px;
}
.megamenu_style_2 .site-nav {
  margin-bottom: 23px;
}
.megamenu_style_2.custom-product .site-nav-banner {
  padding-top: 0;
}
.megamenu_style_2.custom-product .col-right {
  padding-top: 7px;
}
.megamenu_style_3 .site-nav-list .image {
  display: block;
  margin-top: 13px;
  margin-bottom: 10px;
}
.header .header-bottom .megamenu_style_4 .row {
  margin: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.halo-toolbar-bottom-mobile .halo-toolbar-wrapper .halo-toolbar-item .link.mobileSearch-toggle svg,
.halo-toolbar-bottom-mobile .halo-toolbar-wrapper .halo-toolbar-item [data-open-auth-sidebar] svg,
.header .header-bottom .megamenu_style_4 .halo-row-item {
  padding: 0;
}
.header .header-bottom .megamenu_style_4 .halo-row-item .site-banner .img-box {
  border-radius: 0;
}
.header .header-bottom .megamenu_style_4 .site-nav-list {
  text-align: center;
  padding: 0 15px;
}
.header .header-bottom .megamenu_style_5 .megamenu-product-custom {
  position: relative;
  bottom: -17px;
  margin-top: 11px;
  padding: 28px 0 52px;
}
.header .header-bottom .megamenu_style_5 .megamenu-product-custom .halo-row-item .product-card .product-card-top {
  width: 200px;
  height: auto;
}
.header .header-bottom .megamenu_style_5 .megamenu-product-custom .halo-row-item .product-card .card-vendor-title {
  line-height: 15px;
  padding: 7px 0 10px;
}
.header .header-bottom .megamenu_style_5 .megamenu-product-custom .halo-row-item .product-card .card-vendor-title a {
  line-height: 15px;
}
.header .header-bottom .megamenu_style_5 .megamenu-product-custom .halo-row-item .product-card .product-card-bottom {
  width: calc(100% - 200px);
  padding-left: 22px;
}
.header-default .header-language_currency .halo-currency .btn-group .text {
  margin-left: 1px;
}
.header-default .header-language_currency .halo-language .text-language {
  margin-right: 9px;
}
.header-mobile {
  background: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: block;
}
.header-mobile .header-mobile--item,
.header-mobile .header-mobile--wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.header-mobile .header-mobile--item.header-mobile--left,
.header-mobile .header-mobile--wrapper.header-mobile--left {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.header-mobile .header-mobile--item.header-mobile--right,
.header-mobile .header-mobile--wrapper.header-mobile--right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.header-mobile .header-mobile--icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: auto;
  min-height: 50px;
}
.header-mobile .header-mobile--icon.item__mobile--hamburger {
  margin-right: 3px;
}
.header-mobile .header-mobile--icon .mobileMenu-toggle {
  width: 28px;
  height: 28px;
  font-size: 0;
  overflow: hidden;
  position: relative;
  top: -1px;
  z-index: 1;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: block;
  color: #232323;
}
.header-mobile .header-mobile--icon .mobileMenu-toggle .mobileMenu-toggle__Icon {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: background-color 10ms ease-in 0.1s;
  transition: background-color 10ms ease-in 0.1s;
  content: '';
  display: block;
  height: 2px;
  width: 100%;
  position: absolute;
  background-color: #232323;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: top 0.1s ease-in 0.15s, bottom 0.1s ease-in 0.15s, -webkit-transform 0.1s ease-in;
  transition: transform 0.1s ease-in, top 0.1s ease-in 0.15s, bottom 0.1s ease-in 0.15s, -webkit-transform 0.1s ease-in;
}
.header-mobile .header-mobile--icon .mobileMenu-toggle .mobileMenu-toggle__Icon::after,
.header-mobile .header-mobile--icon .mobileMenu-toggle .mobileMenu-toggle__Icon::before {
  content: '';
  display: block;
  height: 2px;
  width: 100%;
  position: absolute;
  background-color: #232323;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: top 0.1s ease-in 0.15s, bottom 0.1s ease-in 0.15s, -webkit-transform 0.1s ease-in;
  transition: transform 0.1s ease-in, top 0.1s ease-in 0.15s, bottom 0.1s ease-in 0.15s, -webkit-transform 0.1s ease-in;
}
.header-mobile .header-mobile--icon .mobileMenu-toggle .mobileMenu-toggle__Icon::before {
  top: -8px;
}
.header-mobile .header-mobile--icon .mobileMenu-toggle .mobileMenu-toggle__Icon::after {
  bottom: -8px;
}
.header-mobile .header-mobile--icon + .header-mobile--icon {
  margin-left: 20px;
  padding-right: 10px;
}
.header-mobile .header-mobile--icon svg {
  width: 24px;
  height: 24px;
  fill: #232323;
}
.header-mobile .header-mobile--icon.item-mobile-logo .header__heading-link {
  /* max-width: 65px  */
  width: 80%;
}
.header-mobile .header-mobile--icon .header__icon--cart svg {
  margin: 0;
}
.header-mobile .header-mobile--icon .header__icon--cart .cart-count-bubble {
  position: absolute;
  right: -12px;
  top: -8px;
  margin: 0;
}
.header-mobile .header-mobile--icon .header__link--account svg {
  width: 30px;
  height: 30px;
}
.product-card .product-card-top .product-card-media img + img {
  opacity: 0;
}
.product-card .product-card-top .product-card-media:hover img + img {
  opacity: 1;
  transition: opacity 0.5s, transform 2s cubic-bezier(0, 0, 0.44, 1.18), -webkit-transform 2s cubic-bezier(0, 0, 0.44, 1.18);
}
.product-card .product-card-bottom {
  padding: 13px 0 10px;
}
.product-card .product-card-information .card-vendor {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  font-weight: 400;
  /* color: #969696; */
  line-height: 22px;
  width: 100%;
  margin-bottom: 0;
}
.product-card .product-card-information .card-vendor a {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}
.product-card .product-card-information .card-title {
  font-weight: 400;
  line-height: 22px;
  color: #232323;
  display: block;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  margin-bottom: 3px;
}
.product-card .card-swatch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 13px 0 0;
}
.card-price,
.product-card .card-product__group {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-direction: normal;
}
.product-card .card-swatch .swatch {
  display: block;
  width: 100%;
  position: relative;
}
.product-card .card-swatch .item {
  display: inline-block;
  vertical-align: top;
  margin: 0 1.5px;
  position: relative;
}
.product-card .card-swatch .item:first-child {
  margin-left: 0;
}
.product-card .card-swatch .swatch-label {
  display: block;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  font-size: 0;
  letter-spacing: 0;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  border: 1px solid #cbcbcb;
  background: 0 0;
  cursor: pointer;
  padding: 2px;
}
.product-card .card-swatch .swatch-label .pattern {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.product-card .card-swatch .swatch-label.is-active,
.product-card .card-swatch .swatch-label:hover {
  border-color: #232323;
}
.product-card .card-product__group {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  position: absolute;
  z-index: 10;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 10px;
  display: flex;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
}
.product-card .card-product__group.group-right {
  left: auto;
  right: 0;
}
.product-card .card-product__group.group-left {
  left: 0;
  right: auto;
}
.product-card .card-product__group .card-icon {
  display: block;
  text-align: center;
  position: relative;
  width: 36px;
  height: 36px;
  padding: 9px;
  line-height: 1;
  border-radius: 50%;
  font-size: 0;
  letter-spacing: 0;
  cursor: pointer;
  color: #232323;
  background: #fff;
}
.product-card .card-product__group .card-icon .text {
  padding: 0 30px 0 15px;
  color: #232323;
  text-transform: capitalize;
  display: none;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  width: 0;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  right: calc(100% - 30px);
  height: 36px;
  background: #fff;
  line-height: 36px;
  position: absolute;
  top: 50%;
  border-radius: 36px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 350ms;
  transition: 350ms;
  pointer-events: none;
}
.footer,
.product-card .card-product__group .card-icon.wishlist-added {
  background: #232323;
}
.product-card .card-product__group .card-icon.wishlist-added .text {
  background: #232323;
  color: #fff;
}
.product-card .card-product__group .card-icon.wishlist-added svg {
  fill: #ffffff;
}
.product-card .card-product__group .card-product__group-item svg {
  width: 18px;
  height: 18px;
  line-height: 18px;
  vertical-align: top;
  position: relative;
  z-index: 5;
  background-color: transparent;
}
.product-card .card-product__group .card-product__group-item + .card-product__group-item {
  margin-top: 10px;
}
.product-card .card-action {
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 0;
  background: 0 0;
  z-index: 1;
  margin: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  -webkit-transform: translate(0);
  transform: translate(0);
}
.product-card .card-action .button {
  background: #fff;
  color: #232323;
  border-color: #232323;
  width: 100%;
  min-width: auto;
}
.card-price {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: flex;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.card-price .price-item .money {
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  color: #232323;
}
.card-price.price__sale .price-item--regular {
  margin: 0 10px 0 0;
}
.card-price.price__sale .price-item--regular span {
  text-decoration: line-through;
  font-weight: 400;
  color: #969696;
}
.card-price.price__sale .price__last span {
  color: #000;
}
.card__badge {
  position: absolute;
  top: 0;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.card__badge .badge {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 20px;
  height: auto;
  padding: 0 8px;
  text-align: center;
  border-radius: 1px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.card__badge .badge.custom-badge {
  color: #fff;
  background-color: #ffbb49;
}
.card__badge .badge.sale-badge {
  color: #fff;
  background-color: #e95144;
}
.card__badge .badge.sold-out-badge {
  color: #fff;
  background-color: #c1c1c1;
}
.card__badge .badge.new-badge {
  color: #0a6cdc;
  background-color: #d2e7ff;
}
.card__badge .badge + .badge {
  margin-top: 5px;
}
.card__badge.badge-left {
  left: 0;
}
.card__badge.badge-right {
  right: 0;
  text-align: right;
}
.product-item-custom .product-card-top {
  display: inline-block;
  vertical-align: top;
  width: 80px;
  height: 107px;
}
.product-item-custom .product-card-bottom {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 90px);
  padding: 0 25px 0 16px;
  position: relative;
  top: -6px;
}
.product-item-custom .product-card-bottom .product-card-information .card-price {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
}
.product-item-custom .product-card-bottom .product-card-information .card-swatch {
  text-align: left !important;
}
.product-item-custom .product-card-bottom .product-card-information .card-swatch .swatch {
  margin-bottom: 13px;
}
.product-card-menu {
  max-width: 175px;
  margin: 0 auto;
}
.product-card-menu .site-nav-title {
  margin-bottom: 12px;
  text-align: center;
}
.product-card-menu .product-card-top .product-card-media {
  max-width: 120px;
  margin: 0 auto;
}
.product-card-menu .product-card-bottom .product-card-information .text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.product-card-menu .shop-now {
  width: 100%;
  margin-top: 4px;
}
.product-card-menu .shop-now .text {
  font-size: 12px;
  letter-spacing: 0.05em;
  font-weight: 700;
  line-height: 22px;
}
@media (max-width: 1024px) {
  .slick-arrow.slick-next svg {
    position: relative;
    top: -3px;
    left: 2px;
  }
  .halo-block-video .video_icon {
    right: 15px;
    bottom: 15px;
  }
  .halo-block-video .video-banner.fixed_video {
    bottom: 60px;
    max-width: 300px;
    max-height: 200px;
    z-index: 99999;
    right: 0 !important;
  }
  .product-card .card-product__group {
    right: 10px;
    display: none;
  }
}
.footer {
  clear: both;
  /* padding-bottom: 70px; */
}
.footer .footer-top {
  padding: 37px 0 22px;
}
.footer .footer-block__heading {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  color: #fff;
}
.footer .desc,
.footer .footer-link {
  display: inline-block;
  padding: 2px 0;
  line-height: 22px;
  color: #cfcfcf;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.02em;
}
.footer .footer-block__newsletter-form {
  max-width: 420px;
  padding: 0;
  width: 100%;
  margin-top: 14px;
  display: block;
}
.newsletter-form .newsletter-form__field-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
}
.newsletter-form .newsletter-form__field-wrapper .field {
  margin-right: 10px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.newsletter-form .newsletter-form__field-wrapper .field__input {
  color: #cfcfcf;
  border: 1px solid #d3d3d3;
  width: 100%;
  padding-top: 11px;
  padding-bottom: 10px;
  line-height: 22px;
}
.newsletter-form .newsletter-form__field-wrapper .newsletter-form__button {
  color: #fff;
  border: 1px solid #fff;
  background-color: #232323;
  width: 124px;
  min-width: 124px;
  margin: 0;
}
.newsletter-form .newsletter-form__field-wrapper .newsletter-form__button:hover {
  color: #fff;
  border: 1px solid #fff;
  background-color: #232323;
}
.list-social {
  display: block;
  font-size: 22px;
}
.list-social .list-social__item {
  display: inline-block;
  vertical-align: top;
  margin: 0 15px 6px 0;
}
.list-social .list-social__link {
  display: block;
  position: relative;
  width: 100%;
  line-height: 1;
  font-size: 0;
  letter-spacing: 0;
  border-radius: 50%;
  color: #282828;
  background: #fff;
  border: 1px solid #fff;
  padding: 9px;
}
.list-social .list-social__link:hover {
  -webkit-box-shadow: 0 0 0 2px #fff;
  box-shadow: 0 0 0 2px #fff;
}
.list-social .list-social__link:hover::before {
  -webkit-box-shadow: 0 0 0 2px #fff !important;
  box-shadow: 0 0 0 2px #fff !important;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.footer-bottom .copyright__content {
  color: #cfcfcf;
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.02em;
}
.footer-bottom .footer__payment .list-payment {
  font-size: 0;
  letter-spacing: 0;
  margin: 0;
  padding-top: 5px;
}
.footer-bottom .footer__payment .list-payment .list-payment__item {
  display: inline-block;
  vertical-align: top;
  margin: 5px 0 5px 15px;
}
.footer-bottom .footer__payment .list-payment .list-payment__item svg {
  width: auto;
  height: 32px;
  fill: currentColor;
  stroke: transparent;
}
@media (max-width: 767px) {
  .footer-top .footer-block__heading {
    border-bottom: 1px solid #c7c7c7;
    pointer-events: auto;
    padding-bottom: 10px !important;
    position: relative;
    margin-bottom: 10px !important;
    cursor: pointer;
  }
  .footer-top .footer-block__heading::after,
  .footer-top .footer-block__heading::before {
    content: '';
    position: absolute;
    top: calc(50% - 6.5px);
    z-index: 2;
    -webkit-transform: translateY(-50%) rotate(0);
    transform: translateY(-50%) rotate(0);
    background-color: #fff;
    -webkit-transition: visibility 350ms, -webkit-transform 350ms;
    transition: transform 350ms, visibility 350ms, -webkit-transform 350ms;
  }
  .footer-top .footer-block__heading::before {
    right: 5px;
    width: 2px;
    height: 12px;
  }
  .footer-top .footer-block__heading::after {
    right: 0;
    width: 12px;
    height: 2px;
  }
  .footer-top .footer-block__heading.is-clicked::before {
    -webkit-transform: translateY(-50%) rotate(90deg);
    transform: translateY(-50%) rotate(90deg);
  }
  .footer-top .list-unstyled {
    -webkit-transition: max-height 0.5s ease-out;
    transition: max-height 0.5s ease-out;
    max-height: 0;
    overflow: hidden;
    pointer-events: none;
    position: relative;
    margin-bottom: 0;
  }
  .footer-block-newsletter .footer-block__heading {
    padding: 0;
    border-bottom: none;
  }
  .footer-block-newsletter .footer-block__heading::after,
  .footer-block-newsletter .footer-block__heading::before {
    display: none;
  }
  .footer .footer-block__newsletter-form {
    max-width: 100%;
  }
  .footer-bottom .halo-row-item {
    width: 100%;
  }
  .footer-bottom .footer__payment .list-payment {
    text-align: left;
  }
  .footer-bottom .footer__payment .list-payment .list-payment__item {
    margin-left: 0;
    margin-right: 10px;
  }
}
@media (max-width: 551px) {
  .halo-row--swipe .halo-row-item {
    width: 300px;
  }
  .footer-block__newsletter-form {
    max-width: 100% !important;
  }
  .newsletter-form .newsletter-form__field-wrapper {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .newsletter-form .newsletter-form__field-wrapper .field {
    -webkit-box-flex: initial;
    -ms-flex: initial;
    flex: initial;
    width: 100%;
    margin: 0 0 15px !important;
  }
  .newsletter-form .newsletter-form__field-wrapper .newsletter-form__button {
    max-width: 100% !important;
    width: 100% !important;
  }
  .newsletter-form .newsletter-form__field-wrapper .field__input {
    text-align: center;
  }
}
.halo-toolbar-bottom-mobile {
  background: #fff;
  padding: 10px 10px 5px;
  position: fixed;
  display: block;
  bottom: 0;
  z-index: 9;
  -webkit-transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  width: 100%;
  -webkit-box-shadow: 0 0 9px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.12);
}
.halo-toolbar-bottom-mobile .halo-toolbar-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.halo-toolbar-bottom-mobile .halo-toolbar-wrapper .halo-toolbar-item .link {
  text-align: center;
  position: relative;
}
.halo-toolbar-bottom-mobile .halo-toolbar-wrapper .halo-toolbar-item .link svg {
  width: 30px;
  height: 30px;
  fill: #232323;
  stroke: transparent;
  padding: 2px;
}
.halo-toolbar-bottom-mobile .halo-toolbar-wrapper .halo-toolbar-item .label {
  display: block;
  font-size: 12px;
  letter-spacing: 0.02em;
  font-weight: 500;
  line-height: 22px;
  padding-top: 2px;
}
.halo-toolbar-bottom-mobile .halo-toolbar-wrapper .halo-toolbar-item .icon--cart .cart-count-bubble {
  position: absolute;
  right: -8px;
  top: -4px;
  margin: 0;
  background-color: #ffe5e8;
  min-width: 23px;
  min-height: 23px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
}
@media (min-width: 1350px) {
  .list-menu.text-left {
    margin-left: 50px !important;
  }
  .header-2 .header-bottom .menu-lv-1__action {
    padding: 21px 20px !important;
  }
}
@media (min-width: 1025px) {
  .animate-scale,
  .view-abs .view {
    position: relative;
    display: block;
  }
  .animate-scale {
    overflow: hidden;
  }
  .animate-scale img {
    -webkit-transition: 0.7s;
    transition: 0.7s;
  }
  .animate-scale:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  .animate-scale:hover::before {
    overflow: visible;
    opacity: 0.3;
  }
  .animate-border,
  .animate-overlay {
    position: relative;
  }
  .animate-overlay .image:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffffb3;
    opacity: 0;
    -webkit-transition: opacity 0.7s;
    transition: opacity 0.7s;
    z-index: 2;
  }
  .animate-overlay:hover .image:before {
    opacity: 1;
  }
  .view-abs {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    opacity: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .view-abs .view {
    width: 40px;
    height: 40px;
    border: 1px solid #232323;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .view-abs .view::after,
  .view-abs .view::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #000;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .view-abs .view::before {
    width: 2px;
    height: 20px;
  }
  .view-abs .view::after {
    width: 20px;
    height: 2px;
  }
  .animate-border::after,
  .animate-border::before {
    content: '';
    position: absolute;
    background: rgba(255, 255, 255, 0.1);
    width: 0;
    height: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    visibility: hidden;
    z-index: 5;
  }
  .animate-border::before {
    top: 0;
    left: 0;
  }
  .animate-border::after {
    bottom: 0;
    right: 0;
  }
  .animate-border:hover::after,
  .animate-border:hover::before {
    width: 100%;
    height: 100%;
    visibility: visible;
  }
  .img-box:hover .view-abs {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .row.column-5 .halo-row-item {
    width: 20%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .product-carousel .product-item,
  .row .halo-item,
  .row .halo-row-item {
    padding: 0 15px;
  }
  .row {
    /* margin-left: -15px; */
    /* margin-right: -15px; */
  }
  .halo-block-header .title .text {
    width: 420px;
  }
  .halo-product-block {
    margin-top: 55px;
  }
  .halo-product-block .product-item:nth-child(n + 5) {
    margin-top: 25px;
  }
  .page {
    padding-bottom: 30px;
  }
  .header-bottom,
  .header-top {
    display: block;
  }
  .header-top .header-top--left {
    width: 90px;
    padding-bottom: 6px;
  }
  .header-top .header-top--right {
    width: calc(100% - 90px);
  }
  .list-menu.text-left {
    margin-left: 20px;
  }
  .menu-lv-item.menu-lv-2.dropdown:hover .site-nav-list-dropdown > .header__submenu,
  .menu-lv-item:hover > .header__submenu {
    z-index: 99;
    top: 100%;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  .menu-lv-item.has-megamenu:hover > .menu-dropdown {
    margin-top: 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  .menu-lv-item.has-megamenu:hover > .menu-dropdown .megamenu-custom-product-width-banner .product-card .card-action {
    visibility: visible;
  }
  .menu-lv-item.menu-lv-2:hover .list-menu--disclosure-2 {
    right: calc(-100% + 10px);
  }
  .halo-toolbar-bottom-mobile,
  .header-mobile {
    display: none;
  }
  .product-card .card-action,
  .product-card .card-product__group {
    opacity: 0;
    visibility: hidden;
  }
  .product-card .card-product__group .card-product__group-item .card-icon:hover .text {
    left: auto;
    right: calc(100% - 30px);
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    display: inline-block;
    width: auto;
  }
  .product-card .card-product__group.group-left .card-product__group-item .card-icon:hover .text {
    left: calc(100% - 30px);
    right: auto;
    padding: 0 15px 0 30px;
  }
  .product-card .card-action {
    position: absolute;
    z-index: 10;
    bottom: -15px;
  }
  .product-card .card-action .button:hover {
    background: #232323;
    color: #fff;
  }
  .product-card:hover .card-product__group {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    right: 10px;
  }
  .product-card:hover .card-product__group.group-left {
    left: 10px;
  }
  .product-card:hover .card-action {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    bottom: 0;
  }
  .footer {
    padding-bottom: 0;
  }
  .list-social__link:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    top: -7px;
    left: -7px;
    padding: 7px;
    pointer-events: none;
    -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
    transition: transform 0.2s, opacity 0.2s, -webkit-transform 0.2s;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
    -webkit-box-shadow: 0 0 0 2px #fff;
    box-shadow: 0 0 0 2px #fff;
  }
}
/*Skin2.css*/
/* @import url(https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;
0,200;
0,300;
0,400;
0,500;
0,600;
0,700;
0,800;
0,900;
1,100;
1,200;
1,300;
1,400;
1,500;
1,600;
1,700;
1,800;
1,900&amp;
display=swap);
@import url(https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;
200;
300;
400&family=Oswald:wght@400;
500;
600;
700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;
0,500;
0,600;
0,700;
0,800;
0,900;
1,400;
1,500;
1,600;
1,800;
1,900&display=swap); */
.skin-2,
.skin-2 .desc,
.skin-2 .link,
.skin-2 label {
  color: #000;
  letter-spacing: 0;
}
.skin-2,
.skin-2 .button,
.skin-2 .desc,
.skin-2 .link {
  font-size: 16px;
  line-height: 28px;
}
.skin-2 .desc,
.skin-2 .link {
  font-weight: 400;
}
.skin-2 label {
  font-size: 16px;
}
.skin-2 input[type='email'],
.skin-2 input[type='text'] {
  padding: 13px 12px 12px;
  border-radius: 0;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  font-weight: 400;
  color: #646464;
}
.skin-2 .icon {
  fill: #000000;
}
.skin-2 .link-underline:hover .text {
  text-underline-offset: 0.2rem;
}
.skin-2 .button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  letter-spacing: 0.05em;
  font-weight: 700;
  border-radius: 0;
  height: 55px;
}
.skin-2 .button.button-1 {
  background: #fff;
  border-color: #fff;
  color: #4f8b7b;
}
.skin-2 .button.button-1:hover {
  background: #4f8b7b;
  border-color: #fff;
  color: #fff;
}
.skin-2 .button.button-2,
.skin-2 .button.button-3:hover {
  background: #fff;
  border-color: #000;
  color: #000;
}
.skin-2 .button.button-2:hover,
.skin-2 .button.button-3 {
  background: #000;
  border-color: #000;
  color: #fff;
}
.skin-2 .halo-block-header {
  position: relative;
  margin-bottom: 18px;
}
.skin-2 .halo-block-header .title {
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0;
  color: #202020;
}
.skin-2 .halo-block-header .title::before {
  display: none;
}
.skin-2 .halo-block-header .title span {
  width: 100%;
  background: 0 0;
}
.skin-2 .card__badge {
  top: 0;
  left: 0;
}
.skin-2 .card__badge .badge,
.skin-2 .halo-recently-viewed-popup .recently-viewed-tab.recently-viewed-list .products-grid .no-products .text,
.skin-2 .halo-sidebar_menu .nav-currency-language .disclosure__list,
.skin-2 .halo-toolbar-bottom-mobile .halo-toolbar-wrapper .halo-toolbar-item .label,
.skin-2 .product-card .card-product__group .card-icon .text {
  font-size: 14px;
}
.skin-2 .card__badge .badge.new-badge {
  color: #000;
  background-color: #fff;
  border: 1px solid #ebebeb;
}
.skin-2 .card__badge .badge.Bundle-badge {
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
}
.skin-2 .card-price .price-item--regular {
  font-size: 16px;
  line-height: 25px;
  font-weight: 600;
}
.skin-2 .product-card .product-card-information .card-vender {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 25px;
  color: #969696;
  margin-top: 2px;
}
.skin-2 .product-card .product-card-information .text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  color: #000;
  line-height: 28px;
}
.skin-2 .product-card .product-card-information .card-price {
  margin-top: -3px;
  font-size: 16px;
  font-weight: 600;
}
.skin-2 .product-card .product-card-information .card-price .price-item.price-item--regular .money {
  color: #d5d5d5;
  font-weight: 400;
}
.skin-2 .product-card .product-card-information .card-price .price-item.price__last .money {
  color: #e74a45;
}
.skin-2 .product-card .product-card-information .card-price .price-item .money {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  color: #000;
  letter-spacing: 0;
}
.skin-2 .product-card .product-card-information .card-swatch {
  margin-top: 16px;
}
.skin-2 .product-card .product-card-bottom {
  padding: 11px 0 10px;
}
.skin-2 .product-card .card-action {
  /* background-color: #fff; */
}
.skin-2 .product-card .card-action .button {
  height: 45px;
}
.skin-2.quickSearchResult-show {
  overflow: hidden;
}
.skin-2.quickSearchResult-show .background-header-search {
  z-index: 1;
  opacity: 1;
  visibility: visible;
}
.skin-2 .halo-ask-an-expert-popup .halo-popup-header .halo-popup-title,
.skin-2 .halo-compare-color-popup .halo-popup-header .halo-popup-title,
.skin-2 .halo-sidebar_menu .halo-sidebar-header .title,
.skin-2 .halo-sidebar_search .halo-sidebar-header .title {
  font-size: 20px;
  text-transform: capitalize;
}
.skin-2 .halo-ask-an-expert-popup .field__input,
.skin-2 .halo-ask-an-expert-popup .form-input,
.skin-2 .halo-ask-an-expert-popup .wrapper-content .ask-an-expert .intro,
.skin-2 .halo-ask-an-expert-popup .wrapper-content .ask-an-expert .text,
.skin-2 .halo-ask-an-expert-popup label,
.skin-2 .halo-auth-sidebar .halo-sidebar-wrapper .auth-actions .auth-link,
.skin-2 .halo-cart-sidebar .haloCalculatorShipping .message,
.skin-2 .halo-cart-sidebar .previewCartCheckbox input[type='checkbox'] + label,
.skin-2 .halo-cart-sidebar .previewCartCheckbox input[type='checkbox'] ~ a,
.skin-2 .halo-cart-sidebar .previewCartItem-name,
.skin-2 .halo-cart-sidebar .previewCartItem-options span,
.skin-2 .halo-compare-color-popup .wrapper-content .halo-compareColors .halo-compareColors-text,
.skin-2 .halo-edit-cart-popup .add-more,
.skin-2 .halo-edit-cart-popup .product-edit-itemCenter .product-edit-title-variant,
.skin-2 .halo-edit-cart-popup .productView-variants .form__label,
.skin-2 .halo-edit-cart-popup .productView-variants .product-form__label .text,
.skin-2 .halo-quick-view-popup .previewCartCheckbox input[type='checkbox'] + label,
.skin-2 .halo-quick-view-popup .previewCartCheckbox input[type='checkbox'] ~ a,
.skin-2 .halo-quick-view-popup .productView-information .productView-more .text,
.skin-2 .halo-quick-view-popup .productView-rating .spr-badge-caption,
.skin-2 .halo-quick-view-popup .productView-soldProduct .text,
.skin-2 .halo-quick-view-popup .productView-variants .form__label,
.skin-2 .halo-quick-view-popup .productView-variants .product-form__label .text,
.skin-2 .halo-quickshop-popup .halo-productView-right .productView-variants .form__label,
.skin-2 .halo-quickshop-popup .halo-productView-right .productView-variants .product-form__label .text,
.skin-2 .halo-quickshop-popup .halo-productView-right .productView-variants .product-form__label.available .text,
.skin-2 .halo-quickshop-popup .halo-productView-right .productView-variants [data-header-option],
.skin-2 .halo-sidebar_menu .site-nav-mobile.nav .menu-lv-item .menu-lv-1__action,
.skin-2 .halo-sidebar_search .search-bar .input-group-field,
.skin-2 .productView-countDown .num {
  font-size: 16px;
}
.skin-2 .halo-ask-an-expert-popup .wrapper-content .ask-an-expert .heading,
.skin-2 .halo-sidebar_search .quickSearchResultsWrap .search-block-title .text {
  font-size: 16px;
  text-transform: capitalize;
}
.skin-2 .halo-ask-an-expert-popup .button {
  height: 50px;
}
.skin-2 .slick-arrow {
  width: 40px;
  height: 40px;
  top: 50%;
}
.skin-2 .slick-arrow:hover svg {
  fill: #000000;
  stroke: #000000;
}
.skin-2 .slick-arrow svg {
  top: -5px;
  right: 2px;
}
.skin-2 .slick-arrow.slick-prev {
  /* left: -15px; */
}
.skin-2 .slick-arrow.slick-next {
  /* right: -15px; */
}
.skin-2 .halo-accept-cookie-popup .desc,
.skin-2 .halo-accept-cookie-popup .group-action-links {
  font-size: 14px;
  line-height: 24px;
  color: #fff;
}
.skin-2 .halo-quick-view-popup .productView-information .previewCartAction .previewCartGroup .previewCartGroup-top .productView-wishlist a svg,
.skin-2 .header-language_currency .halo-language svg {
  position: relative;
  top: -1px;
}
.skin-2 .halo-auth-sidebar .halo-sidebar-header .title,
.skin-2 .halo-cart-sidebar .halo-sidebar-header .title {
  font-size: 20px;
}
.skin-2 .halo-auth-sidebar .halo-sidebar-wrapper .form-field .form-input {
  font-size: 16px;
  padding: 16px 12px 15px;
}
.skin-2 .halo-cart-sidebar .slick-slider {
  position: static;
}
.skin-2 .halo-cart-sidebar .slick-dots {
  position: absolute;
  top: 38px;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.skin-2 .halo-cart-sidebar .product-card .product-card-bottom {
  padding: 11px 0 10px 40px;
  margin-top: -10px;
}
.skin-2 .halo-cart-sidebar .previewCartItem-price {
  font-size: 18px;
  font-weight: 500;
}
.skin-2 .halo-cart-sidebar .previewCartCollection .previewCartTitle,
.skin-2 .halo-cart-sidebar .previewCartInfo .item,
.skin-2 .halo-edit-cart-popup .card-price .price-item .money,
.skin-2 .halo-edit-cart-popup .halo-popup-header .halo-popup-title,
.skin-2 .halo-quick-view-popup .productView-information .productView-subtotal .money,
.skin-2 .halo-quick-view-popup .productView-information .productView-subtotal .text {
  font-size: 18px;
}
.skin-2 .halo-cart-sidebar .previewCartCarousel .halo-row-item {
  padding: 0;
}
.skin-2 .halo-cart-sidebar .previewCartCarousel .card-media {
  width: 100%;
}
.skin-2 .halo-cart-sidebar .previewCartCarousel .card-media img {
  width: 108px;
  max-width: 108px;
}
.skin-2 .halo-cart-sidebar .previewCartItem-image {
  width: 108px;
}
.skin-2 .halo-edit-cart-popup .product-edit-itemCenter .product-edit-title {
  font-size: 16px;
  font-weight: 500;
}
.skin-2.ask-an-expert-show .background-overlay,
.skin-2.compare-color-show .background-overlay,
.skin-2.size-chart-show .background-overlay {
  z-index: 9999;
}
.skin-2.quick-view-show .background-overlay-popup {
  z-index: 99;
}
.skin-2 .background-header-search,
.skin-2 .background-overlay,
.skin-2 .background-overlay-popup {
  background-color: rgba(0, 0, 0, 0.4);
}
.skin-2 .background-header-search {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 25vh;
  cursor: pointer;
  display: block;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.skin-2 .halo-toolbar-bottom-mobile .halo-toolbar-wrapper .halo-toolbar-item .icon--cart .cart-count-bubble {
  background-color: #000;
  color: #fff;
  line-height: 22px;
}
.skin-2 .halo-quickshop-popup .previewCartItem-qty .quantity {
  padding: 16px 14px 15px 20px;
}
.skin-2 .halo-quickshop-popup .previewCartItem-qty .btn-quantity {
  height: 55px;
  top: 5px;
}
.skin-2 .halo-quickshop-popup .halo-productView-right .product-title {
  padding-bottom: 5px;
}
.skin-2 .halo-quick-view-popup input[type='text'] {
  padding: 16px 12px 15px;
}
.skin-2 .halo-quick-view-popup .productView-information .share-button__fallback .button-copy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.skin-2 .halo-quick-view-popup .productView-information .productView-share .share-button svg {
  position: relative;
  top: 2px;
}
.skin-2 .halo-quick-view-popup .productView-perks::-webkit-scrollbar {
  display: none;
}
.skin-2 .halo-quick-view-popup .productView-information .previewCartAction .previewCartGroup .previewCartGroup-top .productView-wishlist a {
  width: 55px;
  height: 55px;
}
.skin-2 .halo-notification-popup .halo-popup-content .product-content,
.skin-2 .halo-sidebar_menu .nav-currency-language .disclosure__list .disclosure__item .disclosure__link .text,
.skin-2 .halo-sidebar_menu .nav-currency-language .wrapper-currencies .currency-item .text,
.skin-2 .live_help_group .text,
.skin-2 .live_help_group .title {
  font-size: 16px;
}
.skin-2 .halo-sidebar_menu .nav-currency-language .disclosure__list .disclosure__item .disclosure__link svg,
.skin-2 .halo-sidebar_menu .nav-currency-language .wrapper-currencies .currency-item svg {
  position: relative;
  top: -5px;
}
.skin-2 .live_help_group .desc {
  font-size: 16px;
  margin-bottom: 16px;
}
.skin-2 .halo-block-product-tab .slick-arrow,
.skin-2 .halo-product .slick-arrow {
  top: 35% !important;
}
.skin-2 .halo-block-product-tab .slick-arrow svg,
.skin-2 .halo-product .slick-arrow svg {
  top: -5px;
}
.skin-2 .halo-block-product-tab .slick-arrow:hover svg,
.skin-2 .halo-product .slick-arrow:hover svg {
  fill: #ffffff;
  stroke: #ffffff;
}
@media (max-width: 1024px) {
  .skin-2 .slick-arrow {
    top: 35%;
  }
  .skin-2 .slick-arrow.slick-prev {
    left: -2px;
  }
  .skin-2 .slick-arrow.slick-next {
    right: -2px;
  }
}
@media (max-width: 992px) {
  .skin-2 .slick-arrow {
    top: 50%;
  }
  .skin-2 .halo-banner.style-2 .slick-arrow.slick-prev {
    left: 10px;
  }
  .skin-2 .halo-banner.style-2 .slick-arrow.slick-next {
    right: 10px;
  }
}
@media (max-width: 767px) {
  .skin-2 .product-card .text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
@media (min-width: 768px) {
  .skin-2 .row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .skin-2 .container.container-1640 .row {
    margin-left: -10px;
    margin-right: -10px;
  }
}
@media (min-width: 1025px) {
  .skin-2 .custom-magamenu-1 .row {
    margin: 0 -15px !important;
  }
  .skin-2 .row {
    margin-left: 0;
    margin-right: 0;
  }
  .skin-2.quickSearchResult-show {
    padding-right: 10px;
  }
  .skin-2 .product-card .card-action {
    padding: 15px;
  }
}
@media (min-width: 1670px) {
  .skin-2 .container.container-1640 {
    max-width: 1670px !important;
  }
  .skin-2 .container.container-1640 .row {
    margin: 0 -15px;
  }
}
/*Demo 2 Css*/
.halo-policies-block {
  margin-top: 15px;
  margin-bottom: 10px;
}
.halo-custom-text .content .title .text.desc-2::before,
.halo-policies-block .halo-row-policies {
  background-color: #f9ede1;
}
.halo-policies-block .halo-row--swipe-policies {
  overflow-x: auto;
  overflow-y: hidden;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0;
}
.halo-policies-block .wrapper-policies-content {
  padding: 23px 0 16px;
}
.halo-policies-block.halo-policies-block-2 {
  margin-top: 10px;
  margin-bottom: 0;
}
.halo-policies-block.halo-policies-block-2 .row {
  background-color: #f2f2f2;
}
.halo-policies-block.halo-policies-block-2 .row .wrapper-policies-content {
  padding: 19px 0 13px;
}
.halo-policies-block.halo-policies-block-2 .row .wrapper-policies-content.before-border::before {
  content: '';
  width: 1px;
  height: 25px;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #000;
}
.halo-policies-block.halo-policies-block-2 .row .policies-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.halo-custom-text .content:hover .desc,
.halo-policies-block.halo-policies-block-2 .row .policies-content:hover .title {
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}
.halo-policies-block.halo-policies-block-2 .row .policies-content svg {
  width: 26px;
  height: 23px;
  fill: #232323;
  stroke: #232323;
  margin-right: 12px;
  position: relative;
  top: -3px;
}
.halo-policies-block.halo-policies-block-2 .row .policies-content .title {
  font-size: 18px;
  color: #000;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28px;
}
.halo-block-instagram,
.halo-block-slideshow {
  line-height: 1;
  font-size: 0;
}
.halo-block-slideshow .bannerCarousel-slide {
  line-height: 1;
}
.halo-block-slideshow .bannerCarousel-slide .sub-title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #fff;
  letter-spacing: -0.02em;
}
.halo-block-slideshow .bannerCarousel-slide .title {
  font-size: 60px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0;
  color: #fff;
  padding-top: 11px;
  margin-bottom: 40px;
}
.halo-block-slideshow .bannerCarousel-slide .desc {
  color: #fff;
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  padding-bottom: 46px;
}
.halo-block-slideshow .bannerCarousel-slide .wrapper-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.halo-block-slideshow .bannerCarousel-slide .button-1 {
  width: 100%;
  max-width: 210px;
}
.halo-block-slideshow:not(.slick-slider) {
  overflow: hidden;
}
.halo-block-slideshow:not(.slick-slider) .bannerCarousel-slide {
  display: none;
}
.halo-block-slideshow:not(.slick-slider) .bannerCarousel-slide:first-child {
  display: block;
}
.halo-block-slideshow .slick-dots {
  background-color: rgba(0, 0, 0, 0.2);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px 1px;
  border-radius: 6px;
  position: absolute;
  bottom: 25px;
  margin: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.halo-block-slideshow .slick-dots li {
  margin: 0 5px;
  width: 12px;
  height: 12px;
}
.halo-block-slideshow .slick-dots li:not(.slick-active) button {
  background-color: transparent;
}
.halo-block-slideshow .slick-dots li button {
  border: 1px solid #fff;
  background-color: #fff;
}
.halo-block-slideshow .slick-arrow {
  border: none;
  background-color: transparent;
  padding: 0 !important;
}
.halo-block-slideshow .slick-arrow.slick-prev {
  left: 41px;
}
.halo-block-slideshow .slick-arrow.slick-next {
  right: 41px;
}
.halo-banner,
.halo-banner .halo-block-header {
  margin-top: 30px;
}
.halo-banner .halo-block-header .title {
  margin-bottom: 22px;
}
.halo-custom-text {
  margin-top: 60px;
}
.halo-custom-text .content .title {
  font-size: 40px;
  font-weight: 700;
  color: #000;
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: 8px;
}
.halo-custom-text .content .title .text {
  position: relative;
}
.halo-custom-text .content .title .text::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 5px;
  height: 24px;
  left: 0;
  right: 0;
  width: calc(100% + 16px);
  margin-left: -8px;
  background-color: #f9e0ec;
  z-index: -1;
}
.halo-custom-text .content .title .text.desc-3::before {
  background-color: #fbded6;
}
.halo-custom-text .content .desc {
  line-height: 30px;
  letter-spacing: 0;
  color: #000;
}
.halo-custom-text .button {
  height: 54px;
  margin: 0 auto;
  position: relative;
  top: 1px;
}
.halo-tab-category {
  margin: 65px 0 53px;
}
.halo-block-instagram .halo-block-content .row,
.halo-block-product-tab .row,
.halo-product .row,
.halo-tab-category .row {
  margin: 0 -5px;
}
.halo-tab-category .row.column-5 .halo-row-item {
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
}
.halo-tab-category .content {
  background-color: #f7f7f7;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.halo-tab-category .content:hover {
  -webkit-box-shadow: 5px 9px 20px #00000026;
  box-shadow: 5px 9px 20px #00000026;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.halo-tab-category .content .img-box {
  width: 100%;
}
.halo-tab-category .content .link {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  color: #202020;
  padding: 15px;
  text-align: center;
  line-height: 22px;
}
.halo-spotlights {
  margin-top: 24px;
}
.halo-spotlights .halo-block-header .title {
  margin-top: 0;
}
.halo-spotlights .row {
  margin: 0 -15px;
}
.halo-spotlights .halo-block-header {
  margin-bottom: 23px;
}
.halo-block-instagram .halo-block-header {
  margin-bottom: 28px;
}
.halo-block-instagram .halo-block-header .title {
  margin-top: 44px;
}
.halo-block-instagram .halo-block-content .row.column-5 .halo-row-item,
.halo-block-product-tab .row.column-5 .halo-row-item,
.halo-product .row.column-5 .halo-row-item {
  width: 20%;
  padding-left: 5px;
  padding-right: 5px;
}
.halo-block-instagram .halo-block-navigation {
  width: 100%;
  padding-top: 45px;
  padding-bottom: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  place-items: center;
}
.halo-block-instagram .halo-block-navigation .nav {
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  margin: 0 auto;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.halo-block-instagram .halo-block-navigation .nav .nav-item {
  position: relative;
  padding: 14px 23px 15px;
  background: #f9ede1;
  margin: 0 5px 10px;
  border-radius: 30px;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0;
  color: #232323;
}
.halo-product .halo-block-header {
  margin-top: 30px;
  margin-bottom: 29px;
}
.halo-block-product-tab .halo-row-item,
.halo-product .halo-row-item {
  padding: 0 5px;
}
.halo-block-product-tab .tab-content > .tab-pane {
  display: block;
  height: 0;
  overflow: hidden;
}
.halo-block-product-tab .tab-content > .active {
  height: auto;
  overflow: visible;
}
.halo-block-product-tab .nav-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 35px;
  margin-top: 60px;
  border: none;
}
.halo-block-product-tab .nav-tabs .nav-link {
  width: 100%;
  max-width: 226px;
  height: 55px;
  border-radius: 40px;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0;
  padding: 12px 0;
  color: #232323;
  border: 1px solid #e6e6e6;
}
.halo-block-product-tab .nav-tabs .nav-link.active,
.halo-block-product-tab .nav-tabs .nav-link:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
@media (max-width: 1600px) and (min-width: 993px) {
  .halo-custom-text .content .title {
    font-size: 30px;
  }
  .halo-custom-text .content .title .text::before {
    bottom: 0;
    height: 20px;
  }
  .slick-arrow.slick-prev {
    left: 5px !important;
  }
  .slick-arrow.slick-next {
    right: 5px !important;
  }
}
@media (max-width: 1200px) {
  .halo-tab-category .row.column-5 .halo-row-item {
    width: 33.33%;
  }
}
@media (max-width: 1024px) {
  .halo-block-instagram .halo-block-content .row,
  .halo-product .row,
  .halo-tab-category .row {
    margin: 0 -10px;
  }
  .halo-block-slideshow .img-box .image {
    height: 100%;
    /* min-height: 550px; */
  }
  .halo-banner .container,
  .halo-block-product-tab .container,
  .halo-block-slideshow .container {
    padding: 0 10px;
  }
  .halo-block-slideshow .slide-item .desc,
  .halo-block-slideshow .slide-item .wrapper-button .button {
    max-width: 330px;
  }
  .halo-block-slideshow .slide-item .wrapper-button {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .halo-spotlights .row {
    margin: 0 -15 px;
  }
  .halo-block-product-tab .nav-tabs {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    display: inline-block;
    padding-bottom: 3px;
    width: 100%;
  }
  .halo-block-product-tab .nav-tabs .nav-link {
    display: inline-block;
  }
  .halo-block-instagram .halo-block-navigation {
    margin: 0 -5px;
  }
  .halo-custom-text .col-left,
  .halo-custom-text .col-right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .halo-custom-text .slick-dots {
    margin: 15px 0 20px;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .halo-policies-block.style-2 .row .policies-content .title {
    font-size: 14px;
    color: #000;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 28px;
  }
}
@media (max-width: 992px) and (min-width: 768px) {
  .halo-block-slideshow .row {
    margin: 0 -5px;
  }
  .halo-block-slideshow .slide-item .title {
    font-size: 45px;
    padding-top: 0;
    margin-bottom: 27px;
  }
  .halo-block-slideshow .slide-item .desc {
    max-width: 360px;
  }
  .halo-block-slideshow .slide-item .wrapper-button {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .halo-block-slideshow .slide-item .wrapper-button .button {
    max-width: 170px;
  }
  .halo-block-slideshow.style-1 .slick-dots {
    top: 0;
  }
}
@media (max-width: 991px) {
  .halo-policies-block .row {
    margin: 0 -5px;
  }
  .halo-policies-block.style-2 .halo-row--swipe-policies {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 15px;
    margin-left: -5px;
    margin-right: -5px;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .halo-tab-category .row {
    margin: 0 -10px;
  }
  .halo-tab-category .row.column-5 .halo-row-item {
    width: 50%;
  }
  .halo-tab-category .content {
    width: 100%;
    max-width: 100%;
  }
  .halo-spotlights .row {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .halo-block-product-tab .nav-tabs .nav-link {
    max-width: 160px;
    padding: 6px 0;
    margin-right: 5px;
  }
  .halo-block-slideshow.halo-block-slideshow-2 .slick-dots {
    margin-top: 15px;
  }
  .halo-block-product-tab .slick-dots {
    margin-top: -10px;
  }
  .halo-block-instagram .slick-dots {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .halo-policies-block .halo-row--swipe-policies {
    padding: 0;
    margin-left: -5px;
    margin-right: -5px;
  }
  .halo-policies-block .wrapper-policies-content {
    text-align: left !important;
    padding: 15px;
    margin: 0 auto;
  }
  .halo-policies-block .wrapper-policies-content .policies-content .title {
    font-size: 20px;
    text-align: center;
    margin-bottom: 0 !important;
  }
  .halo-policies-block .wrapper-policies-content .policies-content .desc {
    font-size: 13px;
  }
  .halo-policies-block.style-2 .halo-row--swipe-policies {
    margin-left: -5px;
    margin-right: -5px;
  }
  .halo-policies-block.halo-policies-block-2 .row .policies-content .title {
    font-size: 16px;
  }
  .halo-block-slideshow .slide-item .title {
    font-size: 50px;
    padding-top: 4px;
    margin-bottom: 28px;
  }
  .halo-block-slideshow .slide-item .desc {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-bottom: 0;
  }
  .halo-block-slideshow .slide-item .wrapper-button {
    margin-top: 20px;
  }
  .halo-custom-text {
    margin-top: 30px;
  }
  .halo-block-instagram,
  .halo-tab-category {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .halo-tab-category .content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 200px;
  }
  .halo-tab-category .content .icon {
    width: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .halo-block-instagram .halo-block-content .row,
  .halo-product .row,
  .halo-spotlights .row,
  .halo-tab-category .row {
    margin: 0 -10px;
  }
  .halo-block-instagram .halo-block-header .title {
    margin-top: 18px;
  }
  .halo-block-instagram .halo-block-navigation {
    padding-top: 15px;
    padding-bottom: 20px;
  }
  .halo-block-instagram .slick-dots {
    margin-top: 25px;
  }
  .halo-block-product-tab .nav-tabs {
    margin-bottom: 22px;
    margin-top: 45px;
  }
  .quickSearchProduct .search-block-title {
    padding-left: 0 !important;
  }
  .halo-sidebar_menu .nav-currency-language .wrapper-currencies {
    font-size: 12px;
  }
}
@media (max-width: 340px) {
  .halo-custom-text .content .title {
    font-size: 35px;
  }
  .halo-policies-block.style-2 .row .policies-content .title {
    font-size: 15px;
  }
}
@media (min-width: 768px) {
  .halo-tab-category .content:hover {
    -webkit-box-shadow: 5px 9px 20px #00000026;
    box-shadow: 5px 9px 20px #00000026;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  .halo-tab-category .content .img-box {
    width: 120px;
  }
  .halo-tab-category .content .link {
    width: calc(100% - 120px);
  }
  .halo-banner .halo-block-header,
  .halo-product .halo-block-header {
    margin-top: 56px;
  }
}
@media (min-width: 1025px) {
  .halo-block-slideshow .slick-dots {
    bottom: 48px;
  }
}
/* Header 2 */
.header-2 .header-bottom .header-search .quickSearchResultsWrap .header-search__trending .list-item .item .link:hover,
.header-2 .header-bottom .header-search .quickSearchResultsWrap .header-search__trending .list-item .item:hover {
  background-color: #f5f5f5;
}
.header-2 .header-mobile .header-mobile--top .link.logo img,
.header-2 .header-top .header-top-wrapper .content-left .link.logo img {
  max-width: 40px;
}
.header-2 {
  background: #fff;
}
.header-2 .header-top {
  background-color: #000;
  padding: 0;
}
.header-2 .header-top .container {
  padding-right: 0;
  padding-left: 0;
}
.header-2 .header-top .header-language_currency {
  position: absolute;
  right: 40px;
  top: 35px;
  width: 100%;
  max-width: 212px;
  background-color: #fff;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 0 5px 7px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.15);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0;
  margin: 0;
  display: block;
  cursor: pointer;
}
.header-2 .header-top .header-language_currency .down-icon svg {
  position: relative;
  top: -6px;
}
.header-2 .header-top .header-language_currency .text {
  font-size: 14px;
  letter-spacing: 0;
  font-weight: 500;
}
.header-2 .header-top .header-language_currency.open {
  display: block;
  top: 45px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 1;
  visibility: visible;
}
.header-2 .header-top .header-language_currency .halo-language svg {
  width: 16px;
  height: 16px;
}
.header-2 .header-top .header-language_currency .halo-language .localization-form .disclosure__link {
  padding: 0 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 2px;
}
.header-2 .header-top .header-language_currency .halo-language .localization-form .disclosure__link .icon {
  margin-right: 2px;
}
.header-2 .header-top .header-language_currency .halo-language .dropdown-language {
  padding: 0 11px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.header-2 .header-top .header-language_currency .halo-currency .halo-dropdown.show,
.header-2 .header-top .header-language_currency .halo-language .dropdown-language.show {
  top: 100%;
  left: 0;
}
.header-2 .header-top .header-language_currency .halo-language .disclosure .disclosure__list .disclosure__item.text,
.header-2 .header-top .header-language_currency .halo-language .dropdown-language .currency-menu .currency-item.text {
  font-size: 14px;
  letter-spacing: 0.05em;
  font-weight: 500;
  padding-left: 0;
  margin-bottom: 6px;
}
.header-2 .header-top .header-language_currency .halo-language .disclosure .disclosure__list .disclosure__item .disclosure__link .text,
.header-2 .header-top .header-language_currency .halo-language .dropdown-language .currency-menu .currency-item .text {
  line-height: 21px;
}
.header-2 .header-top .header-language_currency .halo-language .disclosure {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
.header-2 .header-top .header-language_currency .halo-language .disclosure::before {
  content: '';
  width: 1px;
  height: 15px;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #e6e6e6;
}
.header-2 .header-top .header-language_currency .halo-language .disclosure .text {
  margin-right: 6px;
}
.header-2 .header-top .header-language_currency .halo-currency .btn-group .icon {
  top: 0;
}
.header-2 .header-top .header-language_currency .halo-currency .down-icon svg {
  position: relative;
  top: -5px;
}
.header-2 .header-top .header-language_currency .halo-currency .currency-menu .icon {
  width: 18px;
  height: 18px;
  margin-right: 9px;
}
.header-2 .header-top .header-language_currency .halo-currency .currency-item {
  padding: 3px 15px;
}
.header-2 .header-top .header-language_currency .halo-currency .text {
  margin: 0 3px 0 0;
  position: relative;
  top: -1px;
}
.header-2 .header-top .header-language_currency .halo-currency .halo-dropdown {
  -webkit-box-shadow: 0 5px 7px #00000026;
  box-shadow: 0 5px 7px #00000026;
  width: calc(100% + 6px);
  margin-left: -5px;
}
.header-2 .header-bottom .megamenu-3 .halo-row-item,
.header-2 .header-top .header-language_currency .halo-currency .wrapper-currency {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-2 .header-top .header-language_currency .halo-currency,
.header-2 .header-top .header-language_currency .halo-language {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 103px;
}
.header-2 .header-top .header-top-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-2 .header-bottom .header__inline-menu,
.header-2 .header-top .header-top-wrapper .content-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-2 .header-top .header-top-wrapper .content-left .header__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
  font-size: 0;
}
.header-2 .header-top .header-top-wrapper .content-left .header__list li.separation {
  display: block;
  width: 1px;
  height: 30px;
  line-height: 30px;
  padding: 0.5px;
  background-color: #4d4d4d;
}
.header-2 .header-top .header-top-wrapper .content-left .header__list li.is-active,
.header-2 .header-top .header-top-wrapper .content-left .header__list li:hover {
  background: #fff;
}
.header-2 .header-bottom .menu-lv-1:hover > a:hover,
.header-2 .header-top .header-top-wrapper .content-left .header__list li:hover .text {
  color: #000;
}
.header-2 .header-top .header-top-wrapper .content-left .link {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  letter-spacing: 0;
}
.header-2 .header-top .header-top-wrapper .content-left .link.logo {
  padding: 23px 24px 22px;
}
.header-2 .header-top .header-top-wrapper .content-left a {
  padding: 16px 24px;
}
.header-2 .header-top .header-top-wrapper .content-left a.logo-text {
  font-size: 20px;
  background-color: #fff;
  color: #000;
  font-weight: 700;
  letter-spacing: 0;
}
.header-2 .header-top .header-top-wrapper .content-left .text {
  color: #fff;
  position: relative;
}
.header-2 .header-top .header-top-wrapper .content-left .hidde-before .text::before {
  width: 0;
  height: 0;
}
.header-2 .header-top .header-top-wrapper .content-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  padding-right: 30px;
}
.header-2 .header-top .header-top-wrapper .content-right .cart svg,
.header-2 .header-top .header-top-wrapper .content-right .customer-links svg,
.header-2 .header-top .header-top-wrapper .content-right .live-help svg,
.header-2 .header-top .header-top-wrapper .content-right .nav-wishlist svg {
  width: 20px;
  height: 20px;
  margin-right: 20px;
  cursor: pointer;
  fill: #ffffff;
}
.header-2 .header-top .header-top-wrapper .content-right .cus-ser-text {
  font-size: 14px;
  font-weight: 400;
  margin-right: 68px;
}
.header-2 .header-top .header-top-wrapper .content-right .cus-ser-text a {
  color: #fff;
}
.header-2 .header-top .header-top-wrapper .content-right .cus-ser-text .underline {
  text-decoration: underline;
  text-underline-offset: 0.2rem;
}
.header-2 .header-bottom .header-search .quickSearchResultsWrap .quickSearchResult,
.header-2 .header-bottom .megamenu_style_2 .img-box,
.header-2 .header-top .header-top-wrapper .content-right .cart,
.header-2 .header-top .header-top-wrapper .content-right .nav-wishlist {
  position: relative;
}
.header-2 .header-top .header-top-wrapper .content-right .cart .wishlist-quantity,
.header-2 .header-top .header-top-wrapper .content-right .nav-wishlist .wishlist-quantity {
  position: absolute;
  top: -7px;
  right: 7px;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 50%;
  z-index: 99;
  text-align: center;
  color: #000;
}
.header-2 .header-top .header-top-wrapper .content-right .cart .wishlist-quantity {
  right: 9px;
}
.header-2 .header-top .wishlist-quantity .text {
  position: relative;
  top: -3px;
  font-size: 14px;
}
.header-2 .header-top .global {
  cursor: pointer;
}
.header-2 .header-top .live-help .button-3 {
  text-transform: capitalize;
}
.header-2 .header-top .live-help .button-3 svg {
  margin-right: 14px !important;
}
.header-2 .header-top .live-help .button-3:hover svg {
  fill: #000000;
  stroke: #000000;
}
.header-2 .header-bottom {
  background-color: #fff;
  position: relative;
  -webkit-box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.05);
}
.header-2 .header-bottom .header-bottom--wrapper {
  padding: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}
.header-2 .header-bottom .menu-dropdown {
  border-top: 1px solid #e0e0e1;
  padding: 30px 0 2px;
}
.header-2 .header-bottom .quickSearchProduct .search-block-title {
  padding-left: 135px;
}
.header-2 .header-bottom .product-card .product-card-bottom {
  padding: 11px 0 0;
}
.header-2 .header-bottom .product-card .card-swatch {
  margin: 4px 0;
}
.header-2 .header-bottom .product-card .card-swatch .swatch-label {
  width: 24px !important;
  height: 24px !important;
}
.header-2 .header-bottom .menu-lv-1__action {
  font-size: 14px !important;
  color: #202020;
  padding: 21px 15px;
  font-weight: 600;
  letter-spacing: 0;
}
.header-2 .header-bottom .custom-magamenu-1 .hola-row-item-left {
  padding-left: 160px;
  padding-top: 20px;
}
.header-2 .header-bottom .wrapper-mega-banner-style-2::before {
  content: ' ';
  width: 300%;
  height: 100%;
  position: absolute;
  left: -100%;
  bottom: 0;
  z-index: -1;
  background-color: transparent;
  border-top: 1px solid #e6e6e6;
  margin-bottom: 1px;
}
.header-2 .header-bottom .megamenu_style_3 .wrapper-mega-banner .col-brands {
  padding-top: 9px;
}
.header-2 .header-bottom .megamenu_style_3 .wrapper-mega-banner .col-brands .title {
  font-size: 16px;
  font-weight: 400;
  color: #232323;
  margin-bottom: 12px;
  letter-spacing: 0.05em;
}
.header-2 .header-bottom .megamenu_style_3 .wrapper-mega-banner .col-brands .azbrandstable li {
  line-height: 0;
}
.header-2 .header-bottom .megamenu_style_3 .wrapper-mega-banner .col-brands .link-brands {
  margin-top: 3px;
  font-size: 14px;
  color: #202020;
}
.header-2 .header-bottom .container-header-style-3 .product-card,
.header-2 .header-bottom .megamenu_style_3 .wrapper-mega-banner .halo-col-product,
.header-2 .header-bottom .search-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.header-2 .header-bottom .logo-home {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 52px;
}
.header-2 .header-bottom .logo-home .img-box {
  width: 68px;
}
.header-2 .header-bottom .logo-home .img-box img {
  width: 68px;
  height: auto;
}
.header-2 .header-bottom .header-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 240px;
  position: static;
}
.header-2 .header-bottom .header-search .product-card .card-price {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.header-2 .header-bottom .header-search .quickSearchResultsWrap .search-block-title .text {
  font-weight: 600;
  letter-spacing: 0.02em;
  color: #202020;
  font-size: 18px;
}
.header-2 .header-bottom .header-search .quickSearchResultsWrap .wrapper-quickSearchResult .row {
  margin: 0 -15px;
}
.header-2 .header-bottom .header-search .search-bar {
  border: none;
}
.header-2 .header-bottom .header-search .search-bar .input-group-field {
  color: #828282;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  padding: 9px 0 9px 14px;
  width: 190px;
  background-color: #fff;
  border: 1px solid #ececec;
}
.header-2 .header-bottom .header-search .search-bar .icon-search {
  width: 40px;
  height: 40px;
  background-color: #202020;
  border: 1px solid #202020;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
}
.header-2 .header-bottom .header-search .search-bar .icon-search svg {
  width: 20px;
  height: 20px;
}
.header-2 .header-bottom .header-search .quickSearchResultsWrap {
  display: block;
  position: absolute;
  width: 100%;
  top: 0;
  padding: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  right: auto;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.header-2 .header-bottom .header-search .quickSearchResultsWrap .header-search__trending {
  padding: 0 140px;
  position: relative;
}
.header-2 .header-bottom .header-search .quickSearchResultsWrap .header-search__trending .title {
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #202020;
  font-weight: 600;
  margin: 28px 0 16px;
}
.header-2 .header-bottom .header-search .quickSearchResultsWrap .header-search__trending .title .halo-search-close {
  position: absolute;
  right: 0;
}
.header-2 .header-bottom .header-search .quickSearchResultsWrap .header-search__trending .title .halo-search-close svg {
  width: 17px;
  height: 17px;
}
.header-2 .header-bottom .header-search .quickSearchResultsWrap .header-search__trending .list-item .item {
  border: 1px solid grey;
  background-color: #fff;
  margin-right: 16px;
}
.header-2 .header-bottom .header-search .quickSearchResultsWrap .header-search__trending .list-item .item .link {
  background-color: #fff;
  padding: 8px 10px 8px 7px;
}
.header-2 .header-bottom .header-search .quickSearchResultsWrap .header-search__trending .list-item .item .link .text {
  display: block;
  margin: 0 0 0 4px;
  color: #202020;
  font-weight: 400;
}
.header-2 .header-bottom .header-search .quickSearchResultsWrap .header-search__trending .list-item .item .link svg {
  fill: #000000;
  stroke: #000000;
  margin-left: 14px;
}
.header-2 .header-bottom .header-search .quickSearchResultsWrap .quickSearchResult .halo-sidebar-close {
  position: absolute;
  top: -25px;
  right: 15px;
}
.header-2 .header-bottom .header-search .quickSearchResultsWrap .quickSearchResult .halo-sidebar-close svg {
  position: relative;
  top: 5px;
  width: 20px;
  height: 20px;
  fill: #000000;
  margin-left: 5px;
}
.header-2 .header-bottom .header-search .quickSearchResultsWrap .quickSearchProduct .halo-product-block {
  margin-top: 11px;
}
.header-2 .header-bottom .header-search .quickSearchResultsWrap .search-block-title {
  border-bottom: none;
  padding-bottom: 0;
  margin: 42px 0 0;
  padding-left: 140px;
}
.header-2 .header-bottom .header-search .quickSearchResultsWrap.show {
  top: 65px;
  z-index: 9;
  visibility: visible;
  pointer-events: auto;
}
.header-2 .header-bottom .header-search .quickSearchResultsWrap .search-keywords {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #f8f8f8;
  padding: 31px 0;
}
.header-2 .header-bottom .header-search .quickSearchResultsWrap .search-keywords #keywords {
  border: 1px solid #f8f8f8;
  background-color: #f8f8f8;
  font-size: 30px;
  font-weight: 400;
  color: #bcbcbc;
  width: 705px;
}
.header-2 .header-bottom .header-search .quickSearchResultsWrap .search-keywords .icon-search-keywords {
  border: 1px solid #f8f8f8;
  background-color: #f8f8f8;
}
.header-2 .header-bottom .header-search .quickSearchResultsWrap .search-keywords .icon-search-keywords svg {
  fill: #202020;
  width: 30px;
  height: 30px;
}
.header-2 .header-bottom .header-search .product-item:nth-child(n + 5) {
  margin-top: 0;
}
.header-2 .header-bottom .product-card .card-price {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
}
.header-2 .header-bottom .container-header-style-3 {
  margin-top: 35px;
}
.header-2 .header-bottom .container-header-style-3 .row {
  padding-right: 100px;
}
.header-2 .header-bottom .container-header-style-3 .product-card img {
  width: 128px;
  height: auto;
  margin-right: 6px;
}
.header-2 .header-bottom .row-header-product {
  padding-top: 20px;
}
.header-2 .header-bottom .row-header-product .image-adapt img {
  width: 170px;
  height: 255px;
}
.header-2 .header-bottom .megamenu-style-menu4 .megamenu_style_2 .site-nav,
.header-2 .header-bottom .megamenu_style_2 .site-nav {
  margin-bottom: 0;
}
.header-2 .header-bottom .megamenu_style_2 .row-style_2 {
  margin: 0 10px;
  padding-right: 140px;
}
.header-2 .header-bottom .megamenu_style_2 .row-style_2 .banner-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.header-2 .header-bottom .megamenu_style_3 .custom-shop {
  margin-top: 24px;
  padding-top: 1px;
}
.header-2 .header-bottom .megamenu_style_3 .wrapper-mega-banner {
  bottom: -5px;
  display: block;
}
.header-2 .header-bottom .megamenu_style_3 .wrapper-mega-banner .halo-col-product .img-box {
  width: 100%;
  max-width: 192px;
}
.header-2 .header-bottom .megamenu_style_3 .wrapper-mega-banner .halo-col-product .text-center {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-2 .header-bottom .megamenu_style_3 .wrapper-mega-banner .halo-col-product .text-center .sub-title {
  font-size: 16px;
  font-weight: 400;
  color: #202020;
  margin-bottom: 10px;
}
.header-2 .header-bottom .megamenu_style_3 .wrapper-mega-banner .halo-col-product .text-center .line {
  width: 60px;
  height: 1px;
  background-color: #202020;
  margin: 0 auto;
}
.header-2 .header-bottom .megamenu_style_3 .wrapper-mega-banner .halo-col-product .text-center .title {
  font-size: 40px;
  line-height: 60px;
  letter-spacing: 0.1em;
  color: #202020;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 5px;
}
.header-2 .header-bottom .megamenu_style_3 .wrapper-mega-banner .halo-col-product .text-center .link {
  font-size: 14px;
  font-weight: 400;
  color: #202020;
}
.header-2 .header-bottom .megamenu_style_3 .wrapper-mega-banner::before {
  background-color: transparent;
  border-top: 1px solid #e6e6e6;
  margin-bottom: 1px;
}
.header-2 .header-bottom .megamenu_style_3 .wrapper-mega-banner .row {
  margin: 0 -5px;
}
.header-2 .header-bottom .megamenu_style_3 .wrapper-mega-banner .col-brands {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 65px;
}
.header-2 .header-mobile .header-mobile--top {
  background: #000;
}
.header-2 .header-mobile .header-mobile--top .header__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
  font-size: 0;
  margin-right: 0;
}
.header-2 .header-mobile .header-mobile--top .header__list li.separation {
  display: block;
  width: 1px;
  height: 30px;
  line-height: 30px;
  padding: 0.5px;
  background-color: #4d4d4d;
}
.header-2 .header-mobile .header-mobile--top .header__list li.is-active {
  background: #fff;
}
.header-2 .header-mobile .header-mobile--top .link {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  letter-spacing: 0;
}
.header-2 .header-mobile .header-mobile--top .link.logo {
  padding: 15px;
}
.header-2 .header-mobile .header-mobile--top a {
  padding: 8px 24px 9px;
}
.header-2 .header-mobile .header-mobile--top a.logo-text {
  font-size: 20px;
  background-color: #fff;
  color: #000;
  font-weight: 700;
  letter-spacing: 0;
}
.header-2 .header-mobile .header-mobile--top .text {
  color: #fff;
  position: relative;
}
.header-2 .header-mobile .header-mobile--wrapper {
  padding: 5px 0;
}
.header-2 .header-mobile .header__iconItem .header__icon--cart .cart-count-bubble {
  background-color: #000;
}
@media (max-width: 1200px) {
  .header-2 .header-bottom .header-search__form {
    display: none;
  }
}
@media (max-width: 1024px) {
  .header-2 .header-bottom,
  .header-2 .header-top .content-right {
    display: none;
  }
  .header-2 .header-mobile--center {
    margin-left: 15px;
  }
}
@media (max-width: 992px) {
  .header-2 .content-left .text.logo {
    position: relative;
    top: 1px;
  }
}
@media (max-width: 767px) {
  .header-2 .header-top .content-left {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    display: block;
  }
  .header-2 .header-top .content-left .text {
    display: inline-block;
  }
  .header-2 .header-top .content-left .text.logo {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 65px;
  }
  .header-2 .header-mobile {
    width: 100%;
  }
  .header-2 .header-mobile .header-mobile--left {
    width: 60%;
  }
  .header-2 .header-mobile .header-mobile--right {
    width: 40%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: start;
  }
}
@media (min-width: 1600px) {
  .header-2 .header-bottom .header-bottom--wrapper {
    padding: 0 30px;
  }
}
/*Animation*/
@-webkit-keyframes box-shadow {
  0%,
  100% {
    -webkit-box-shadow: 0 0;
    box-shadow: 0 0;
  }
  50% {
    -webkit-box-shadow: 0 0 0 4px;
    box-shadow: 0 0 0 4px;
  }
}
@keyframes box-shadow {
  0%,
  100% {
    -webkit-box-shadow: 0 0;
    box-shadow: 0 0;
  }
  50% {
    -webkit-box-shadow: 0 0 0 4px;
    box-shadow: 0 0 0 4px;
  }
}
@-webkit-keyframes zoom-animation {
  from,
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.5, 1.5, 1);
    transform: scale3d(1.5, 1.5, 1);
  }
}
@keyframes zoom-animation {
  from,
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.5, 1.5, 1);
    transform: scale3d(1.5, 1.5, 1);
  }
}
/*Component Category*/
.toolbar,
.toolbar .toolbar-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
}
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.collection-template,
.collection-template-masonry {
  padding-bottom: 50px;
}
.collection-wrapper {
  margin-bottom: 50px;
}
.collection-wrapper .breadcrumb {
  padding: 20px 0 27px;
}
.collection {
  /* margin-bottom: 80px; */
}
.collection .products-grid .product-card .card-grid-hide,
.products-list .product-card .card-action,
.toolbar .toolbar-dropdown.limited-view,
.toolbar .toolbar-label {
  display: none;
}
.collection-header,
.page-header {
  padding-bottom: 17px;
}
.collection-header .collection-title,
.page-header .collection-title {
  font-size: 20px;
  letter-spacing: 0.05em;
  color: #232323;
  margin: 33px 0 0;
}
.collection-header .desc,
.page-header .desc {
  font-size: 18px;
  color: #3c3c3c;
  margin-top: 15px;
  max-width: initial;
  margin: auto;
}
.toolbar .toolbar-col,
.toolbar .toolbar-item {
  font-size: 0;
  letter-spacing: 0;
}
.toolbar {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  margin: 0;
  padding: 30px 0;
  border-top: 1px solid #dedede;
}
.toolbar .toolbar-wrapper {
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.toolbar .toolbar-wrapper:not(.toolbar-mobile) {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.products-list .product-card .product-card-bottom .product-card-information .card-price,
.toolbar .toolbar-wrapper.toolbar-mobile {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.toolbar .toolbar-wrapper:not(.toolbar-mobile) + .toolbar-mobile {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.toolbar .toolbar-wrapper .toolbar-colLeft,
.toolbar .toolbar-wrapper .toolbar-colRight {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.toolbar .toolbar-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  vertical-align: middle;
  position: relative;
  z-index: 9;
}
.toolbar .toolbar-dropdown,
.toolbar .toolbar-icon,
.toolbar .toolbar-item .icon-mode {
  display: inline-block;
  vertical-align: middle;
}
.toolbar .toolbar-item .icon-mode {
  position: relative;
  cursor: pointer;
  width: 26px;
  height: 26px;
  padding: 3px;
  border: 1px solid #dfdfdf;
}
.toolbar .toolbar-item .icon-mode:before {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  background-color: #dfdfdf;
  width: 4px;
  height: 18px;
}
.toolbar .toolbar-item .icon-mode.icon-mode-list:before {
  width: 18px;
  height: 4px;
  -webkit-box-shadow: 0 7px 0 #dfdfdf, 0 14px 0 #dfdfdf;
  box-shadow: 0 7px 0 #dfdfdf, 0 14px 0 #dfdfdf;
}
.toolbar .toolbar-item .icon-mode + .icon-mode {
  margin-left: 5px;
}
.toolbar .toolbar-item .icon-mode.active.grid-2,
.toolbar .toolbar-item .icon-mode.grid-2 {
  width: 19px;
}
.toolbar .toolbar-item .icon-mode.grid-2:before {
  -webkit-box-shadow: 7px 0 0 #dfdfdf;
  box-shadow: 7px 0 0 #dfdfdf;
}
.toolbar .toolbar-item .icon-mode.grid-3:before {
  -webkit-box-shadow: 7px 0 0 #dfdfdf, 14px 0 0 #dfdfdf;
  box-shadow: 7px 0 0 #dfdfdf, 14px 0 0 #dfdfdf;
}
.toolbar .toolbar-item .icon-mode.active.grid-4,
.toolbar .toolbar-item .icon-mode.grid-4 {
  width: 33px;
}
.toolbar .toolbar-item .icon-mode.grid-4:before {
  -webkit-box-shadow: 7px 0 0 #dfdfdf, 14px 0 0 #dfdfdf, 21px 0 0 #dfdfdf;
  box-shadow: 7px 0 0 #dfdfdf, 14px 0 0 #dfdfdf, 21px 0 0 #dfdfdf;
}
.toolbar .toolbar-item .icon-mode.active.grid-5,
.toolbar .toolbar-item .icon-mode.grid-5 {
  width: 40px;
}
.toolbar .toolbar-item .icon-mode.grid-5:before {
  -webkit-box-shadow: 7px 0 0 #dfdfdf, 14px 0 0 #dfdfdf, 21px 0 0 #dfdfdf, 28px 0 0 #dfdfdf;
  box-shadow: 7px 0 0 #dfdfdf, 14px 0 0 #dfdfdf, 21px 0 0 #dfdfdf, 28px 0 0 #dfdfdf;
}
.toolbar .toolbar-item .icon-mode.active {
  border-color: #323232;
}
.toolbar .toolbar-item .icon-mode.active:before {
  background: #323232;
}
.toolbar .toolbar-item .icon-mode.active.icon-mode-list:before {
  -webkit-box-shadow: 0 7px 0 #323232, 0 14px 0 #323232;
  box-shadow: 0 7px 0 #323232, 0 14px 0 #323232;
}
.toolbar .toolbar-item .icon-mode.active.grid-2:before {
  -webkit-box-shadow: 7px 0 0 #323232;
  box-shadow: 7px 0 0 #323232;
}
.toolbar .toolbar-item .icon-mode.active.grid-3:before {
  -webkit-box-shadow: 7px 0 0 #323232, 14px 0 0 #323232;
  box-shadow: 7px 0 0 #323232, 14px 0 0 #323232;
}
.toolbar .toolbar-item .icon-mode.active.grid-4:before {
  -webkit-box-shadow: 7px 0 0 #323232, 14px 0 0 #323232, 21px 0 0 #323232;
  box-shadow: 7px 0 0 #323232, 14px 0 0 #323232, 21px 0 0 #323232;
}
.toolbar .toolbar-item .icon-mode.active.grid-5:before {
  -webkit-box-shadow: 7px 0 0 #323232, 14px 0 0 #323232, 21px 0 0 #323232, 28px 0 0 #323232;
  box-shadow: 7px 0 0 #323232, 14px 0 0 #323232, 21px 0 0 #323232, 28px 0 0 #323232;
}
.toolbar .toolbar-item.sidebar-toogle {
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
  background: #fff;
}
.toolbar .toolbar-icon {
  width: 26px;
  height: 26px;
  padding: 3px;
  border: 1px solid #323232;
}
.toolbar .toolbar-icon .icon {
  width: 100%;
  height: 100%;
  fill: #323232;
}
.toolbar .toolbar-dropdown {
  position: relative;
}
.toolbar .toolbar-dropdown .label-tab {
  background-color: #fff;
  border: 1px solid #dadada;
  padding: 7px 38px 7px 20px;
  min-height: 45px;
  width: 100%;
  min-width: 89px;
  max-width: 149px;
  cursor: pointer;
  position: relative;
  line-height: 29px;
}
.toolbar .toolbar-dropdown .label-tab::before {
  content: '';
  background: 0 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}
.toolbar .toolbar-dropdown .label-text {
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #3c3c3c;
}
.toolbar .toolbar-dropdown .icon-dropdown {
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.toolbar .toolbar-dropdown .icon-dropdown svg {
  fill: #3c3c3c;
  color: #3c3c3c;
  width: 10px;
  height: 10px;
}
.toolbar .toolbar-dropdown .dropdown-menu {
  position: absolute;
  top: calc(100% - 5px);
  left: auto;
  right: 0;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  font-size: 0;
  letter-spacing: 0;
  background-color: #fff;
  z-index: 9;
  width: 100%;
  min-width: 90px;
  padding: 13px 13px 13px 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  display: initial;
  border-radius: 0;
  border: none;
}
.toolbar .toolbar-dropdown .dropdown-menu li {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 100%;
}
.toolbar .toolbar-dropdown .dropdown-menu li .text {
  display: block;
  padding: 7px 0 0;
  font-weight: 400;
  color: #232323;
  font-size: 12px;
  cursor: pointer;
}
.toolbar .toolbar-dropdown .dropdown-menu li.is-active .text {
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}
.toolbar .toolbar-dropdown .dropdown-menu.is-open {
  top: calc(100% + 5px);
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  -webkit-transform: translate(0);
  transform: translate(0);
}
.toolbar .toolbar-sort .dropdown-menu {
  min-width: 180px;
}
.productListing .product-item {
  padding-bottom: 20px;
}
.pagination-page-total {
  color: #707070;
  letter-spacing: 0.02em;
  margin: 25px 0 20px;
}
.pagination-page-total .pagination-total-progress {
  max-width: 190px;
  height: 3px;
  background-color: #e6e6e6;
  text-align: left;
  margin: 7px auto 0;
  display: block;
}
.pagination-page-total .pagination-total-progress .pagination-total-item {
  width: 0;
  height: 100%;
  background-color: #4b6cc0;
  display: block;
  -webkit-transition: 1s;
  transition: 1s;
}
.infinite-scrolling .button {
  width: 100%;
  max-width: 290px;
}
.halo-custom-banner,
.halo-product-block {
  clear: both;
}
.halo-product-block .category-product-header {
  border-top: 1px solid #e6e6e6;
  padding: 30px 0 12px;
}
.halo-product-block .category-product-header .title {
  font-size: 18px;
  letter-spacing: 0.02em;
  font-weight: 600;
  color: #232323;
}
.products-list .product-card .product-card-bottom .product-card-information {
  text-align: left !important;
}
.products-list .product-card .product-card-bottom .product-card-information .card-vendor {
  font-weight: 500;
}
.products-list .product-card .product-card-bottom .product-card-information .card-title {
  font-weight: 500;
  font-size: 14px;
  margin-top: 3px;
}
.products-list .product-card .product-card-bottom .card-swatch {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: left !important;
}
.products-list .product-card .product-card-bottom .card-summary {
  margin: 10px 0 12px;
  color: #3c3c3c;
}
.products-list .product-card .card-swatch .item {
  margin: 0 5px;
}
.products-list .product-card .card-action.card-action-list {
  display: block;
  position: relative;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  bottom: 0;
  max-width: 210px;
}
.products-list .grid-item {
  margin-bottom: 30px;
}
.products-list .grid-item + .grid-item .product-card {
  padding-top: 30px;
  border-top: 1px solid #dedede;
}
.collection-template-masonry .collection,
.collection-template-right-sidebar .collection {
  margin-bottom: 60px;
}
.collection-template-right-sidebar .page-header {
  border-bottom: 1px solid #dedede;
}
.collection-template-right-sidebar .toolbar {
  border: none;
}
.collection-template-masonry .halo-product-block,
.collection-template-right-sidebar .halo-product-block {
  margin-top: 35px;
}
.collection-template-masonry .product-collection .grid-item-masonry {
  margin-bottom: 20px;
}
.collection-template-masonry .product-collection .grid-item-masonry .product-card .productView-countDown {
  position: absolute;
  z-index: 9;
  bottom: 26px;
  margin: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
}
.collection-template-masonry .product-collection .grid-item-masonry .product-card .productView-countDown .num {
  color: #fff;
  border-color: transparent;
  background-color: rgba(50, 50, 50, 0.9);
  font-size: 16px;
  font-weight: 400;
  padding: 5px 6px;
  min-width: 50px;
  margin-bottom: 4px;
}
.collection-template-masonry .product-collection .grid-item-masonry .product-card .productView-countDown .num span {
  color: #fff;
  letter-spacing: 0.02em;
  font-size: 10px;
}
.collection-template-masonry .pagination-page-total {
  margin: 4px 0 20px;
}
.halo-custom-banner .halo-row-item .sub-banner-title {
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #232323;
  font-weight: 400;
  margin: 0 0 19px;
}
.halo-custom-banner .halo-row-item .banner-title {
  font-size: 30px;
  letter-spacing: 0.05em;
  margin-bottom: 14px;
}
.halo-custom-banner .halo-row-item .line {
  width: 40px;
  height: 2px;
  display: block;
  background: #232323;
  border: 1px solid #232323;
  text-align: center;
  margin: 0 auto 19px;
}
.halo-custom-banner .halo-row-item .desc {
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #3c3c3c;
  line-height: 22px;
  margin: 0 auto 33px;
  max-width: 450px;
}
.halo-custom-banner .halo-row-item .button {
  width: 100%;
  max-width: 237px;
}
.halo-custom-banner.style-2 .wrapper-content {
  border: 1px solid #646464;
  width: 100%;
  height: 100%;
  position: relative;
}
.halo-custom-banner.style-2 .halo-row-item .banner-title {
  font-size: 30px;
  margin-bottom: 18px;
}
.halo-custom-banner.style-2 .halo-row-item .desc {
  max-width: 350px;
  margin: 0 auto 28px;
}
.halo-custom-banner.style-2 .halo-row-item .button {
  max-width: 187px;
}
@media (max-width: 1024px) {
  .toolbar {
    border: none;
  }
  .toolbar .toolbar-wrapper .toolbar-colLeft {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .toolbar .toolbar-item .icon-mode.grid-5 {
    display: none;
  }
  .toolbar.toolbar-fix {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px 15px;
    background: #fff;
    z-index: 9;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    border-bottom: 1px solid #dedede;
  }
  .collection-template-masonry .product-collection .grid-item-masonry .product-card .productView-countDown {
    bottom: 60px;
  }
}
@media (max-width: 768px) {
  .toolbar .toolbar-item .icon-mode.grid-4,
  .toolbar .toolbar-item .icon-mode.grid-5 {
    display: none;
  }
}
@media (max-width: 767px) {
  .toolbar .toolbar-item .icon-mode.grid-3,
  .toolbar .toolbar-item .icon-mode.grid-4,
  .toolbar .toolbar-item .icon-mode.grid-5 {
    display: none;
  }
  .halo-custom-banner .halo-row-item {
    margin-bottom: 15px;
  }
}
@media (max-width: 551px) {
  .collection-template-masonry .product-collection .grid-item-masonry .product-card .productView-countDown .num {
    color: #232323;
    border-color: transparent;
    background-color: transparent;
    font-size: 16px;
    padding: 0;
    min-width: 34px;
    font-weight: 600;
  }
  .collection-template-masonry .product-collection .grid-item-masonry .product-card .productView-countDown .num span {
    color: #232323;
    font-weight: 500;
  }
}
@media (min-width: 551px) {
  .toolbar .toolbar-dropdown.limited-view {
    display: inline-block;
  }
  .toolbar .toolbar-sort {
    margin-left: 28px;
  }
  .products-list .product-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    font-size: 0;
    letter-spacing: 0;
  }
  .products-list .product-card .product-card-top {
    width: 250px;
  }
  .products-list .product-card .product-card-bottom {
    width: calc(100% - 250px);
    padding-left: 30px;
    position: relative;
    top: -15px;
  }
  .products-list .product-card .card-swatch .item {
    margin: 0 5px;
  }
}
@media (min-width: 768px) {
  .products-list .product-card .product-card-top {
    width: calc(33.3333333% - 8px);
  }
  .products-list .product-card .product-card-bottom {
    width: calc(66.6666667% + 8px);
  }
}
@media (min-width: 992px) {
  .products-list .product-card .product-card-top {
    width: calc(25% - 8px);
  }
  .products-list .product-card .product-card-bottom {
    width: calc(75% + 8px);
  }
}
@media (min-width: 1025px) {
  .toolbar .toolbar-label {
    display: inline-block;
    vertical-align: middle;
    margin: 0 15px 0 0;
    text-transform: uppercase;
    font-weight: 500;
    color: #3c3c3c;
    font-size: 12px;
    letter-spacing: 0.02em;
  }
  .toolbar .toolbar-item {
    display: inline-block;
  }
  .toolbar .toolbar-sidebar {
    display: none;
  }
  .collection-template-right-sidebar .page-sidebar {
    margin-top: 42px;
  }
  .collection-template-right-sidebar .page-header .desc {
    max-width: 970px;
  }
  .halo-custom-banner .halo-row-item .banner-title {
    font-size: 40px;
  }
}
/*Header Page*/
.announcement-bar {
  background: #f8f8f8;
}
.announcement-bar .message {
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #ef6454;
  padding: 7px 15px;
}
.announcement-bar .message a {
  color: #ef6454;
}
.header-page {
  background: #ffffff;
}
.header-page .container {
  position: initial;
}
.header-page .menu-dropdown {
  border-top: 1px solid #efefef;
  padding: 28px 0 17px;
}
.header-page .header-wrapper {
  display: none;
}
.header-page .header__logo {
  position: relative;
  top: -1px;
}
.header-page .header__logo img {
  max-width: 72px;
}
.header-page .header-center .menu-lv-item .menu-lv-1__action {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: #232323;
  text-transform: capitalize;
  padding: 33px 18px 27px;
}
.header-page .header-center .menu-lv-item .menu-lv-1__action .label {
  right: -4px;
  top: 18px;
}
.header-page .header-right {
  padding-bottom: 6px;
}
.header-page .header-right .header__iconItem .header__icon {
  width: 22px;
  height: 22px;
  margin: 0px 13px;
}
.header-page .header-right .header__iconItem .header__icon svg {
  width: 100%;
  height: 100%;
  fill: #232323;
}
.header-page .header-right .header__iconItem .header__icon.header__icon--cart {
  width: 25px;
  height: 25px;
}
.header-page .header-right .header__iconItem .header__icon.hamburger-icon {
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 0;
  margin-left: 7px;
}
.header-page .header-right .header__iconItem .header__icon.hamburger-icon .icon-line {
  background-color: #232323;
  width: 24px;
  height: 2px;
  margin: 3px 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.header-page .header-right .header__iconItem .header__icon.hamburger-icon .icon-line::before,
.header-page .header-right .header__iconItem .header__icon.hamburger-icon .icon-line::after {
  background-color: #232323;
  width: 24px;
  height: 2px;
  margin: 3px 0;
  position: absolute;
  left: 0;
  right: 0;
  content: '';
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.header-page .header-right .header__iconItem .header__icon.hamburger-icon .icon-line::before {
  top: 5px;
}
.header-page .header-right .header__iconItem .header__icon.hamburger-icon .icon-line::after {
  top: 12px;
}
.header-page .header-right .header__iconItem .header__icon.hamburger-icon:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
.header-page .header-right .header__iconItem .header__icon .cart-count-bubble {
  position: absolute;
  right: -9px;
  top: -10px;
  background: #232323;
}
.header-page .header-right .header__iconItem .header__icon .cart-count-bubble .text-count {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0;
  top: 1px;
}
.halo-search-popup .halo-popup-wrapper .halo-popup-content .input-group-field {
  font-size: 12px;
}
.halo-search-popup .halo-popup-wrapper .quickSearchResultsWrap .search-block-title .text {
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #232323;
  font-style: normal;
  font-weight: 700;
}
.halo-search-popup .halo-popup-wrapper .quickSearchResultsWrap .list-item .link {
  padding: 9px 20px 8px 12px;
  color: #232323;
  font-size: 12px;
  background: #f2f2f2;
  border-radius: 4px;
}
.halo-sidebar_language_currency .halo-sidebar-wrapper .nav-currency-language .title {
  font-style: normal;
  font-weight: 700;
  color: #232323;
}
.halo-sidebar_language_currency .halo-sidebar-wrapper .nav-currency-language .wrapper-currencies .currency-item .text {
  color: #232323;
}
@media (min-width: 1025px) {
  .header-page .header-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
  }
}
@media (min-width: 1200px) {
  .header-page .header-left {
    width: 140px;
  }
}
.footer-2 {
  background-color: #fff;
  color: #000;
}
.footer-2 .halo-row-item {
  padding: 0;
}
.footer-2 .newsletter-form .newsletter-form__field-wrapper .newsletter-form__button {
  height: 45px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0;
  width: 126px;
  min-width: 126px;
}
.footer-2 .footer-top {
  background-color: #f7f7f7;
  padding: 57px 0 41px;
}
.footer-2 .footer-top .link {
  line-height: 34px;
  color: #333;
  font-size: 16px;
}
.footer-2 .footer-top .contact-info .contact-store a {
  color: #000 !important;
}
.footer-2 .footer-top .footer-block-newsletter {
  margin-left: 105px;
}
.footer-2 .footer-top .contact-address {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  color: #000;
  line-height: 24px;
}
.footer-2 .footer-top .contact-store {
  margin-top: -2px;
}
.footer-2 .footer-bottom {
  padding: 20px 0;
}
.footer-2 .footer-bottom .col-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-right: 40px;
}
.footer-2 .footer-bottom .halo-row-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.footer-2 .footer-bottom .copyright__content {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #000;
  letter-spacing: 0;
}

.footer-2 .list-unstyled {
  margin-bottom: 5px;
}
.footer-2 .desc {
  margin-top: 2px;
}
.footer-2 .footer-block__newsletter-form {
  margin-top: 6px;
  max-width: 100%;
}
.footer-2 .footer-block__newsletter-form .field__input {
  padding: 13px 18px;
  max-width: 320px;
  background: #fff;
  color: #646464;
  border: 1px solid #555;
  height: 50px;
}
.footer-2 .newsletter-form .newsletter-form__field-wrapper {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
}
.footer-2 .footer-block__list-social {
  margin-top: 8px;
}
.footer-2 .newsletter-form .newsletter-form__field-wrapper .field {
  margin-right: 5px;
  width: 320px;
  max-width: 320px;
}
.footer-2 .list-unstyled li {
  margin-bottom: 0;
}
.footer-2 .list-unstyled.list-social .list-social__link {
  background: 0 0;
  border: none;
  padding: 0;
}
.footer-2 .list-unstyled.list-social .list-social__link::before {
  display: none;
}
.footer-2 .list-unstyled.list-social .list-social__link:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.footer-2 .list-unstyled.list-social .list-social__link svg {
  width: 24px;
  height: 24px;
  margin-right: 13px;
}
.footer-2 #ContactFooter {
  width: 100%;
}
@media (max-width: 1024px) {
  .footer-2 .row {
    margin: 0 -2px;
  }
  .footer-2 .footer-top .footer-block-newsletter {
    margin-left: 0;
  }
}
@media (max-width: 992px) {
  .footer-2 .footer-bottom .col-left {
    padding-right: 0;
    padding-top: 10px;
  }
}
@media (max-width: 767px) {
  .footer-2 .footer-top .footer-block__heading::after,
  .footer-2 .footer-top .footer-block__heading::before {
    background-color: #000;
  }
  .footer-2 .footer-top .footer-block__heading.block-heading_custom {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .footer-2 .footer-top .footer-col-left .row {
    margin: 0;
  }
  .footer-2 .footer-top .footer-block-newsletter .desc {
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .footer-2 .footer-top .contact-address {
    line-height: 32px;
  }
  .footer-2 .footer-top .contact-store {
    margin-bottom: 15px;
  }
  .footer-2 .footer-block__list-social {
    margin-top: -16px;
  }
}
@media (max-width: 551px) {
  .footer-2 .newsletter-form .newsletter-form__field-wrapper .field {
    width: 100%;
    max-width: 100%;
  }
  .footer-2 .newsletter-form .newsletter-form__field-wrapper .field .field__input {
    max-width: 100%;
  }
}
/* Footer Page*/
.footer-page {
  padding: 0;
}
.footer-page .footer-top {
  background: #fafafa;
  padding: 67px 0 40px;
}
.footer-page .footer-top .footer-logo {
  margin-bottom: 24px;
}
.footer-page .footer-top .footer-logo img {
  max-width: 62px;
}
.footer-page .footer-top .footer-block__heading {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #232323;
  text-transform: uppercase;
  padding-bottom: 18px;
}
.footer-page .footer-top .desc,
.footer-page .footer-top .footer-link {
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #707070;
  padding: 3px 0;
}
.footer-page .footer-top .footer-block-newsletter .desc {
  padding: 3px 0 0;
}
.footer-page .footer-top .footer-block-newsletter .footer-block__newsletter-form {
  margin-top: 13px;
}
.footer-page .footer-top .newsletter-form__field-wrapper .button {
  width: 100%;
  max-width: 130px;
}
.footer-page .footer-top .newsletter-form__field-wrapper .field__input {
  letter-spacing: 0.02em;
  color: #646464;
  font-size: 12px;
  background: #fafafa;
  border-color: #969696;
  padding-top: 11px;
  padding-bottom: 10px;
}
.footer-page .footer-top .newsletter-form__field-wrapper .newsletter-form__button {
  background-color: #232323;
  border-color: #232323;
  color: #fff;
}
.footer-page .footer-top .newsletter-form__field-wrapper .newsletter-form__button:hover {
  background-color: #fff;
  border-color: #232323;
  color: #232323;
}
.footer-page .footer-top .footer-securityImage {
  margin-top: 38px;
}
.footer-page .footer-top .footer-securityImage ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin: 0 -12px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.footer-page .footer-top .footer-securityImage ul li {
  padding: 0 12px 10px;
}
.footer-page .footer-top .contact-info {
  color: #707070;
  line-height: 22px;
}
.footer-page .footer-top .contact-info a,
.footer-page .footer-top .contact-info p,
.footer-page .footer-top .contact-info span {
  color: #707070;
}
.footer-page .footer-top .list-social .list-social__link {
  background: 0 0;
  padding: 0;
  margin-right: 5px;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.footer-page .footer-top .list-social .list-social__link::before {
  display: none;
}
.footer-page .footer-top .list-social .list-social__link svg {
  fill: #232323;
  width: 20px;
  height: 20px;
}
.footer-page .footer-bottom {
  background: #fff;
  padding-top: 25px;
  padding-bottom: 39px;
  border: none;
}
.footer-page .footer-bottom .copyright__content {
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #707070;
}
.footer-page .footer-bottom .footer__payment .list-payment {
  padding-top: 0;
  position: relative;
  top: 0;
}
.footer-page .footer-bottom .footer__payment .list-payment__item svg {
  height: 30px;
}
@media (max-width: 991px) {
  .footer-page .footer-bottom {
    padding-top: 20px;
  }
  .footer-page .footer-bottom .halo-row-item {
    width: 100%;
  }
  .footer-page .footer-bottom .halo-row-item.halo-row-item {
    margin-top: 20px;
  }
  .footer-page .footer-bottom .footer__payment .list-payment {
    text-align: left !important;
  }
  .footer-page .footer-bottom .footer__payment .list-payment li {
    margin: 5px 10px 5px 0;
  }
}
@media (max-width: 767px) {
  .footer-page .footer-newsletter .footer-block__heading {
    border: none;
  }
  .footer-page .footer-newsletter .footer-block__heading::after,
  .footer-page .footer-newsletter .footer-block__heading::before {
    display: none;
  }
  .footer-page .footer-top {
    padding: 50px 0 40px;
  }
  .footer-page .footer-top .footer-block__heading::after,
  .footer-page .footer-top .footer-block__heading::before {
    background-color: #232323;
  }
  .footer-page .footer-block__list-social {
    margin-top: 20px;
  }
}
/*Component Category Full Width Css*/
.template-full-width {
  padding-bottom: 50px;
}
.template-full-width .page-header {
  position: relative;
  background-size: cover;
  background-repeat: repeat-x;
  background-position: center top;
  height: 270px;
  background-attachment: fixed;
}
.template-full-width .page-header .content-box {
  top: 0;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  padding: 0 15px;
  left: 50%;
  max-width: 550px;
}
.template-full-width .page-header .content-box .breadcrumb {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  padding: 20px 0 0;
  margin: 0;
}
.template-full-width .page-header .content-box .breadcrumb .separate svg {
  color: #fff;
  fill: #ffffff;
}
.collection-custom-banner .content-box .banner-title a,
.template-full-width .page-header .content-box .breadcrumb a {
  color: #fff;
}
.template-full-width .page-header .content-box .page-heading .page-title {
  display: block;
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #fff;
  position: relative;
  padding: 52px 0 17px;
  margin: 0;
}
.template-full-width .page-header .content-box .page-heading .desc {
  margin-top: 5px;
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
}
.template-full-width .collection-navigation {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 8px;
  padding-right: 10px;
  background-color: #fafafa;
  border: 1px solid #fafafa;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  place-items: center;
}
.template-full-width .collection-navigation .nav {
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  margin: 0 auto;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.template-full-width .collection-navigation .nav .nav-item {
  position: relative;
  padding: 0 25px;
}
.template-full-width .collection-navigation .nav .nav-item a {
  color: #232323;
  font-weight: 500;
  -webkit-transition: color 0.35s ease-in-out;
  transition: color 0.35s ease-in-out;
  letter-spacing: 0.02em;
}
.template-full-width .toolbar .results-count span {
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #707070;
}
.template-full-width .toolbar .results-count,
.template-full-width .toolbar .toolbar-colRight {
  width: 40%;
}
.template-full-width .toolbar .toolbar-colRight {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.template-full-width .toolbar .toolbar-toolbar-colLeft {
  width: 20%;
}
.template-full-width .collection-description {
  color: #3c3c3c;
  max-width: 900px;
  margin: 40px auto;
}
.page-sidebar.page-sidebar--horizontal .filter-title {
  display: none;
}
.collection-custom-banner {
  margin: 20px 0 0;
}
.collection-custom-banner .content-box {
  position: absolute;
  bottom: 70px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  z-index: 9;
}
.collection-custom-banner .content-box .sub-banner-title {
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #fff;
  font-weight: 400;
  margin: 0;
}
.collection-custom-banner .content-box .banner-title {
  font-size: 40px;
  letter-spacing: 0.05em;
  margin: 0;
  color: #fff;
  padding: 13px 0 24px;
}
.collection-custom-banner .content-box .banner-title .text {
  text-decoration-thickness: 1px !important;
}
.collection-custom-banner .content-box .banner-button {
  border-color: #fff;
  width: 100%;
  max-width: 167px;
  text-align: center;
  color: #232323;
  background: #fff;
}
.collection-custom-banner .content-box .banner-button:hover {
  background: 0 0;
  color: #fff;
}
.halo-icon-with-text {
  padding: 0 0 55px;
}
.halo-icon-with-text .halo-row-item {
  text-align: center;
}
.halo-icon-with-text .halo-row-item .icon {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}
.halo-icon-with-text .halo-row-item .icon svg {
  fill: #232323;
  width: 100%;
  height: 100%;
}
.halo-icon-with-text .halo-row-item .title {
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 30px;
  color: #232323;
  font-weight: 500;
  padding: 15px 0 3px;
}
.halo-icon-with-text .halo-row-item .desc {
  color: #232323;
}
@media (max-width: 992px) {
  .collection-custom-banner {
    margin: 20px 0 25px;
  }
  .halo-icon-with-text {
    padding: 4px 0 35px;
  }
  .halo-icon-with-text .halo-row-item {
    border: 1px solid #727272;
    padding: 30px 15px;
  }
}
@media (max-width: 767px) {
  .template-full-width .halo-custom-banner.style-2 .content-box {
    position: relative;
    padding: 35px 0 40px;
    top: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@media (min-width: 1025px) {
  .page-sidebar.page-sidebar--horizontal {
    width: 100%;
    max-width: 100%;
    position: relative;
    top: unset !important;
    margin-top: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .page-sidebar.page-sidebar--horizontal + .page-content {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  .page-sidebar.page-sidebar--horizontal .sidebar-refined-widgets {
    padding: 6px 15px 25px 0;
  }
  .page-sidebar.page-sidebar--horizontal .halo-sidebar-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: 100%;
    overflow: inherit;
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .page-sidebar.page-sidebar--horizontal .halo-sidebar-wrapper .filter-title {
    display: block;
  }
  .page-sidebar.page-sidebar--horizontal .halo-sidebar-wrapper .filter-title span {
    font-size: 12px;
    letter-spacing: 0.02em;
    font-weight: 500;
    color: #3c3c3c;
    text-transform: uppercase;
    padding: 0 17px 0 5px;
  }
  .page-sidebar.page-sidebar--horizontal .halo-sidebar-wrapper .filter-title svg {
    width: 22px;
    height: 100%;
    fill: #3c3c3c;
  }
  .page-sidebar.page-sidebar--horizontal .sidebarBlock {
    max-width: 170px;
    width: 100%;
    margin: 0 0 10px;
    padding: 0 5px;
    position: relative;
  }
  .page-sidebar.page-sidebar--horizontal .sidebarBlock .sidebarBlock-heading {
    border: 1px solid #e6e6e6;
    font-size: 12px;
    letter-spacing: 0.02em;
    color: #3c3c3c;
    font-weight: 400;
    text-transform: capitalize;
    padding: 11px 15px 10px;
    margin: 0;
  }
  .page-sidebar.page-sidebar--horizontal .sidebarBlock .sidebarBlock-heading .icon {
    right: 15px;
  }
  .page-sidebar.page-sidebar--horizontal .sidebarBlock .sidebarBlock-content {
    position: absolute;
    top: calc(100% + 5px);
    left: 6px;
    background: #fff;
    z-index: 999;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    width: calc(100% + 30px);
    padding: 0 15px;
  }
  .page-sidebar.page-sidebar--horizontal .sidebarBlock.filter-prices .sidebarBlock-content {
    padding-bottom: 15px;
  }
  .template-full-width .toolbar {
    padding: 34px 0 36px;
  }
  .template-full-width .collection-description {
    margin: 72px auto 75px;
  }
  .template-full-width .halo-custom-banner.style-2 {
    padding-top: 12px;
  }
}
@media (min-width: 1400px) {
  .page-sidebar.page-sidebar--horizontal .sidebar-refined-widgets {
    padding: 6px 15px 25px 110px;
  }
  .page-sidebar.page-sidebar--horizontal .sidebarBlock {
    max-width: 210px;
  }
}
/*Component Collection List*/
.collections-list,
.page-collections-masonry {
  padding-bottom: 50px;
}
.halo-collection-content .collection-banner {
  position: relative;
  background-size: cover;
  background-repeat: repeat-x;
  background-position: center top;
  height: 170px;
  background-attachment: fixed;
}
.halo-collection-content .collection-banner .page-title {
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.5em;
  font-size: 30px;
}
.halo-collection-content .page-header {
  padding-bottom: 17px;
}
.halo-collection-content .page-header .page-description {
  max-width: 930px;
  margin: 24px auto 23px;
  padding: 0 15px;
}
.list-categories .collection-list-item {
  margin-bottom: 60px;
}
.list-categories .collection-list-item .img-box,
.list-categories-2 .collection-list-item,
.list-categories-3 .collection-list-item {
  margin-bottom: 30px;
}
.list-categories .collection-list-item .img-box:hover .view-abs {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) rotateX(0);
  transform: translate(-50%, -50%) rotateX(0);
}
.list-categories .collection-list-item .collection-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #232323;
  letter-spacing: 0.05em;
}
.list-categories .collection-list-item .count {
  display: block;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 17px;
  letter-spacing: 0.02em;
  color: #999;
}
.list-categories .collection-list-item .desc {
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 33px;
  letter-spacing: 0.02em;
  color: #3c3c3c;
  line-height: 22px;
}
.list-categories .collection-list-item .button {
  width: 100%;
  max-width: 190px;
}
.list-categories .collection-list-item .view-abs {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transition: transform 0.3s linear 0.1s;
  transition: transform 0.3s linear 0.1s;
  transition: transform 0.3s linear 0.1s, -webkit-transform 0.3s linear 0.1s;
  z-index: 2;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%) rotateX(90deg);
  transform: translate(-50%, -50%) rotateX(90deg);
}
.list-categories .collection-infinite-scroll .button {
  width: 100%;
  max-width: 300px;
}
.collection-description {
  color: #3c3c3c;
  margin: 40px auto;
  max-width: 930px;
  padding: 0 15px;
}
.collection-product-header {
  border-top: 1px solid #e6e6e6;
  padding: 30px 15px 12px;
}
.collection-product-header .title {
  font-size: 18px;
  letter-spacing: 0.02em;
  font-weight: 600;
  color: #232323;
}
.list-categories-2 .img-box,
.list-categories-3 .img-box {
  margin-bottom: 0 !important;
  position: relative;
}
.list-categories-2 .img-box .content-box-banner .banner-title {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 13px;
  letter-spacing: 0.05em;
}
.list-categories-2 .img-box .content-box-banner .des-countdown {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 35px;
  letter-spacing: 0.02em;
}
.list-categories-2 .img-box .content-box-banner .bannerView-countDown .num {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #f0f0f0;
  font-size: 18px;
  border-radius: 50%;
  padding: 13px 10px 10px;
  margin: 0 8px;
  color: #fff;
  font-weight: 600;
  background-color: transparent;
  text-align: center;
  width: 64px;
  height: 64px;
}
.list-categories-2 .img-box .content-box-banner .bannerView-countDown .num span {
  color: #fff;
  font-weight: 400;
  display: block;
  font-size: 12px;
  letter-spacing: 0.02em;
  position: relative;
  top: -3px;
}
.list-categories-2 .collections-wrapper {
  background: #fafafa;
  width: 100%;
  height: 100%;
  position: relative;
}
.list-categories-2 .collections-wrapper .collection-title {
  font-size: 24px;
}
.list-categories-2 .collections-wrapper .desc {
  margin: 0 auto 20px;
  max-width: 450px;
  padding: 0 15px;
}
.list-categories-2 .collection-infinite-scroll,
.list-categories-3 .collection-infinite-scroll {
  padding-top: 30px;
}
.list-categories-3 .img-box .count {
  position: absolute;
  top: 13px;
  left: 0;
  z-index: 2;
  padding: 11px 29px 9px;
  opacity: 1;
  visibility: visible;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #232323;
}
.list-categories-3 .img-box .collection-title {
  padding: 13px 50px 12px 30px;
  margin: 0;
  background: #fff;
  font-size: 14px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
}
.list-categories-3 .img-box .collection-title svg {
  position: absolute;
  height: 11px;
  width: 11px;
  fill: #232323;
  top: 50%;
  right: 27px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.list-categories-3 .img-box .collection-title:hover {
  background: #232323;
}
.list-categories-3 .img-box .collection-title:hover a {
  color: #fff;
}
.list-categories-3 .img-box .collection-title:hover svg {
  fill: #ffffff;
}
.list-categories-fullwith .collection-list-item .row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}
.list-categories-fullwith .collections-wrapper {
  background: #fff;
  border: 1px solid #646464;
}
.list-categories-fullwith .collections-wrapper .collection-title {
  font-size: 24px !important;
  margin-bottom: 12px !important;
}
.list-categories-fullwith .collections-wrapper .count {
  color: #3c3c3c;
}
.list-categories-fullwith .collections-wrapper .desc {
  margin: 0 auto 33px !important;
}
@media (max-width: 767px) {
  .list-categories-2 .collections-wrapper {
    height: auto;
    margin-top: 20px;
  }
  .list-categories-2 .collections-wrapper .content-box {
    position: relative;
    padding: 35px 0 40px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@media (max-width: 400px) {
  .list-categories-2 .img-box .content-box-banner .des-countdown {
    margin-bottom: 20px;
  }
  .list-categories-2 .img-box .content-box-banner .bannerView-countDown .num {
    margin: 0 4px;
  }
}
@media (max-width: 320px) {
  .list-categories-2 .img-box .content-box-banner .bannerView-countDown .num {
    margin: 0;
  }
}
@media (min-width: 768px) {
  .list-categories-2 .collection-list-item:nth-child(2n) .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .list-categories-fullwith .collection-list-item:nth-child(4n + 3) .row,
  .list-categories-fullwith .collection-list-item:nth-child(4n + 4) .row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
}
@media (min-width: 1025px) {
  .halo-collection-content .collection-banner {
    background-size: contain;
    height: 270px;
  }
  .list-categories-2 .collections-wrapper .collection-title {
    font-size: 30px;
    margin-bottom: 6px;
  }
  .list-categories-2 .collections-wrapper .desc {
    margin: 0 auto 37px;
  }
  .list-categories-3 .img-box {
    margin-bottom: 0 !important;
    position: relative;
  }
  .list-categories-3 .img-box .count {
    top: 0;
    opacity: 0;
    visibility: hidden;
  }
  .list-categories-3 .img-box:hover .count {
    opacity: 1;
    visibility: visible;
    top: 13px;
  }
  .collection-description {
    margin: 74px auto;
  }
  .page-collections-masonry .collection-description {
    margin: 52px auto 72px;
  }
}
/*Wishlist */
.page-wishlist .heading {
  margin-bottom: 12px;
}
.page-wishlist .wishlist-rowHeading {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 11.9658% 41.735% 15.102% 16.094% auto;
  grid-template-columns: 11.9658% 41.735% 15.102% 16.094% auto;
  background-color: #fafafa;
}
.page-wishlist .wishlist-rowHeading .wishlist-rowItem {
  font-size: 12px;
  font-weight: 600;
  color: #232323;
  letter-spacing: 0.05em;
  line-height: 22px;
  padding: 11.5px 0;
  margin: 0;
  border: none;
}
.page-wishlist .wishlist-rowHeading .wishlist-image {
  margin-left: 20px;
  max-width: 120px;
  width: 100%;
}
.page-wishlist .wishlist-rowHeading .wishlist-product-name {
  max-width: 385px;
  width: 100%;
}
.page-wishlist .wishlist-rowHeading .wishlist-price {
  max-width: 70px;
  width: 100%;
  text-align: center;
}
.page-wishlist .wishlist-rowHeading .wishlist-add {
  width: 100%;
  max-width: 441px;
  text-align: center;
}
.page-wishlist .wishlist-rowHeading .wishlist-remove {
  width: 100%;
  max-width: 114px;
  margin: 0 auto;
  text-align: end;
}
.page-wishlist .wishlist-items .wishlist-price,
.page-wishlist .wishlist-items .wishlist-row .wishlist-add {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.page-wishlist .wishlist-items .wishlist-add {
  text-align: -webkit-center;
}
.page-wishlist .wishlist-items .wishlist-row {
  border: 1px solid #e7e7e7;
  margin-top: 10px;
  min-height: 164px;
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 11.9658% 42.735% 14.102% 18.094% auto;
  grid-template-columns: 11.9658% 42.735% 14.102% 18.094% auto;
}
.page-wishlist .wishlist-items .wishlist-row .item .item-image {
  display: block;
  overflow: hidden;
  margin: 15px;
  width: 80px;
}
.page-wishlist .wishlist-items .wishlist-row .item-vendor {
  font-size: 12px;
  line-height: 22px;
  color: #999;
  font-weight: 400;
}
.page-wishlist .wishlist-items .wishlist-row .wishlist-product-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  margin-top: 10px;
}
.page-wishlist .wishlist-items .wishlist-row .item-price .money {
  font-size: 14px;
  font-weight: 500;
  color: #232323;
}
.page-wishlist .wishlist-items .wishlist-row .wishlist-add .button {
  width: 100%;
  max-width: 210px;
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.page-wishlist .wishlist-items .wishlist-row .wishlist-add .desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 14px;
  height: 45px;
}
.page-wishlist .wishlist-items .wishlist-row .wishlist-remove {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 45px;
}
.page-wishlist .wishlist-items .wishlist-row .wishlist-remove .item-remove svg {
  width: 20px;
  height: 20px;
}
.page-wishlist .wishlist-footer {
  margin-top: 30px;
  margin-bottom: 5px;
}
.page-wishlist .wishlist-footer .wishlist-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  float: left;
}
.page-wishlist .wishlist-footer .wishlist-share .mail-icon-container {
  width: 46px;
  height: 46px;
  background-color: #fafafa;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.page-wishlist .wishlist-footer .wishlist-share .mail-icon-container .icon-mail {
  width: 25px;
  height: 20px;
}
.page-wishlist .wishlist-footer .wishlist-share .desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 14px;
}
.page-wishlist .wishlist-footer .pagination__list {
  width: 50%;
  float: right;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
@media (max-width: 1024px) {
  .page-wishlist .wishlist-rowHeading {
    display: none !important;
  }
  .page-wishlist .wishlists .wishlist-items .wishlist-row {
    -ms-grid-columns: 14.9658% 46.735% 25.102% auto auto;
    grid-template-columns: 14.9658% 46.735% 25.102% auto auto;
  }
  .page-wishlist .wishlists .wishlist-items .wishlist-row .wishlist-rowItem:first-child {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    grid-row: 1/3;
  }
  .page-wishlist .wishlists .wishlist-items .wishlist-row .wishlist-rowItem:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-column-span: 4;
    grid-column: 2/6;
  }
  .page-wishlist .wishlists .wishlist-items .wishlist-row .wishlist-price {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .page-wishlist .wishlists .wishlist-items .wishlist-row .wishlist-add,
  .page-wishlist .wishlists .wishlist-items .wishlist-row .wishlist-remove {
    position: relative;
    top: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
@media (max-width: 992px) and (min-width: 768px) {
  .page-wishlist .wishlist-items .wishlist-row {
    -ms-grid-columns: 16% 35% 14.102% 18.094% auto;
    grid-template-columns: 16% 35% 14.102% 18.094% auto;
  }
}
@media (max-width: 767px) {
  .page-wishlist .wishlist-items .wishlist-row {
    -ms-grid-columns: 30% 60% auto;
    grid-template-columns: 30% 60% auto;
  }
  .page-wishlist .wishlist-items .wishlist-row .wishlist-image {
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    grid-row: 1/4;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
  }
  .page-wishlist .wishlist-items .wishlist-row .wishlist-price {
    -ms-grid-column: 2;
    -ms-grid-column-span: 4;
    grid-column: 2/6;
  }
  .page-wishlist .wishlist-items .wishlist-row .wishlist-add {
    margin: 5px 0 10px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
  }
  .page-wishlist .wishlist-items .wishlist-row .wishlist-add .button {
    max-width: 170px;
  }
  .page-wishlist .wishlist-items .wishlist-row .wishlist-remove {
    padding-left: 0;
    position: relative;
    left: -15px;
    top: -2px;
  }
  .page-wishlist .wishlist-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 10px;
  }
  .page-wishlist .wishlist-footer .wishlist-share {
    width: 100%;
    margin-bottom: 10px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .page-wishlist .wishlist-footer .pagination__list {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media (max-width: 550px) {
  .page-wishlist .wishlists .wishlist-items .wishlist-row {
    -ms-grid-columns: 33% auto;
    grid-template-columns: 33% auto;
  }
  .page-wishlist .wishlists .wishlist-items .wishlist-row .item .item-image {
    margin: 10px;
  }
  .page-wishlist .wishlists .wishlist-items .wishlist-row .wishlist-rowItem:first-child {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
  }
  .page-wishlist .wishlists .wishlist-items .wishlist-row .wishlist-rowItem:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-column-span: 4;
    grid-column: 2/6;
  }
}
@media (max-width: 320px) {
  .page-wishlist .wishlist-items .wishlist-row {
    -ms-grid-columns: 33% 60% auto;
    grid-template-columns: 33% 60% auto;
  }
  .page-wishlist .wishlist-items .wishlist-row .item .item-image {
    margin: 10px;
  }
  .page-wishlist .wishlist-items .wishlist-row .wishlist-product-name .item-title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
/*Register*/
.register-page .sign-in {
  margin-top: 58px;
}
.register-page .heading {
  margin-bottom: 35px;
}
.register-page .heading .title {
  font-size: 24px;
  font-weight: 700;
  color: #232323;
  letter-spacing: 0.05em;
}
.register-page .login-heading .title,
.register-page .wrapper-content .register-heading .title {
  font-size: 18px;
  color: #232323;
  letter-spacing: 0.02em;
  font-weight: 600;
  margin-bottom: 25px;
}
.register-page .login-heading .desc {
  margin-bottom: 17px;
}
.register-page .contact-form .form-input {
  /* max-width: 450px; */
}
.register-page .button-register {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 25px;
}
.register-page .button-register .button-2 {
  width: 135px;
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.register-page .button-register .link-underline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.register-page .button-register .link-underline .text {
  margin-left: 25px;
}
.register-page .wrapper-content {
  padding: 58px;
  background-color: #fafafa;
}
.register-page .wrapper-content .button-1 {
  width: 255px;
  height: 44px;
  margin-top: 25px;
}
@media (max-width: 1024px) {
  .register-page .wrapper-content {
    padding: 40px 30px;
    background-color: #fafafa;
  }
  .register-page .sign-in {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .register-page .sign-in {
    margin-top: 0;
  }
  .register-page .wrapper-content {
    padding: 28px 20px 0;
    margin-top: 30px;
  }
  .register-page .wrapper-content .button-1 {
    width: 100%;
    height: 44px;
    margin-top: 25px;
  }

  .hm-regality h3 {
    margin: 10px 0 0 0 !important;
    font-size: 30px !important;
  }
  .hm-regality h4 {
    margin: 20px 0 !important;
    font-size: 18px !important;
  }
  .hm-appt h3 {
    margin: 20px 0 0 0 !important;
    text-align: center;
    font-size: 25px !important;
  }
  .hm-appt h4 {
    margin: 10px 0 20px 0 !important;
    font-size: 18px !important;
    text-align: center;
  }
  .hm-store-img {
    margin-top: 100px !important;
  }
  .about-policies .text-policies {
    font-size: 14px !important;
    letter-spacing: 1;
    font-weight: 400 !important;
  }
  .about-policies .img-box img {
    width: 50%;
  }
  .footer .footer-block__heading {
    font-size: 16px !important;
  }
}
.sidelinks-ui {
  padding: 10px 0 15px 0;
  border-bottom: 1px solid #000;
  font-size: 16px;
}
.sidelinks-ui a:hover {
  color: #084c99;
}
.text-justify {
  text-align: justify;
}
/* By SURESH */
.excarousel-img {
  width: 100%;
}
.excarousel-img > img {
  width: 100%;
}
.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
@media (min-width: 1350px) {
  .list-menu.text-left {
    margin-left: 50px !important;
  }
  .header-2 .header-bottom .menu-lv-1__action {
    padding: 21px 20px !important;
  }
}
.offer-text {
  color: #fff !important;
}
.d-block {
  display: block !important;
}
.loader-wrapper {
  min-height: 300px;
  position: relative;
}
.vh-100 {
  height: 100vh !important;
}
.vh-70 {
  height: 70vh !important;
}
.loader-wrapper .centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
}
.loadersmall {
  border: 5px solid #f3f3f3;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border-top: 5px solid #555;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.curr_symbol {
  color: #000 !important;
}

.owl-nav button.owl-prev {
  font-size: 50px;
  position: absolute;
  top: 42%;
  left: -23px;
}

.owl-nav button.owl-prev > span {
  font: inherit;
  content: 'f053';
  padding: 0px 15px !important;
  color: rgba(0, 0, 0, 0.7);
  font-size: 30px;
  font-weight: 500;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 50%;
}
.owl-nav button.owl-prev > span:hover {
  background-color: #6c757d;
  color: white;
}

.owl-nav button.owl-next {
  font-size: 50px;
  position: absolute;
  top: 42%;
  right: -20px;
}

.owl-nav button.owl-next > span {
  font: inherit;
  content: 'f053';
  padding: 0px 15px !important;
  color: rgba(0, 0, 0, 0.7);
  font-size: 30px;
  font-weight: 500;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 50%;
}

.owl-nav button.owl-next > span:hover {
  background-color: #6c757d;
  color: white;
}

.owl-carousel .owl-dots {
  margin-top: -40px !important;
  position: absolute;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 10px;
  border-radius: 5px;
}

.owl-carousel button.owl-dot {
  background-color: white;
  border-radius: 50%;
  margin: 7px !important;
}

.owl-carousel button.owl-dot > span {
  border: 1px solid white;
  padding: 0px 7px;
  border-radius: 50%;
}

.owl-carousel button.active > span {
  background-color: white;
}

.card-value {
  border: 1px solid #d1d1d1;
  padding: 5px;
  text-align: center;
  font-weight: 700;
  border-radius: 5px;
  font-size: 20px;
}
.card-value:hover {
  background: rgba(66, 153, 225, 1);
  color: #fff;
}
.card-p {
  padding: 0 5px;
}

.button,
img {
  max-width: 100%;
}

.active_id {
  background-color: rgba(66, 153, 225, 1);
  color: white;
}

.banner-video-container {
  width: 100%;
}

.hm-regality h3 {
  margin: 200px 0 0 0;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 50;
}
.hm-regality h4 {
  margin: 30px 0 30px 0 !important;
  font-size: 22px;
}
.hm-appt h3 {
  margin: 150px 0 0 0;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 50;
}
.hm-appt h4 {
  margin: 30px 0 30px 0 !important;
  font-size: 20px;
}
.frame-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  width: 300%;
  left: -100%;
}
.frame-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.regality-video {
  height: 650px;
  width: 100%;
  object-fit: cover;
}

.vm-man .lazy-load-image-background.blur.lazy-load-image-loaded > img {
  min-height: auto !important;
}

.img-box-home {
  position: relative;
}

.img-box-home .ibh-heading {
  top: 45%;
  color: #fff;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 999;
}

.img-box-home .ibh-heading h3 {
  font-size: 20px;
  letter-spacing: 1px;
}

.ibh-heading h3 {
  font-weight: 600;
}

.img-box-home .ibh-shop {
  bottom: 0px;
  color: #fff;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 999;
}

.header-top .container {
  padding-right: 0;
  padding-left: 0;
}
.container-1920 {
  max-width: 1920px;
  padding-left: 0;
  padding-right: 0;
}

.container {
  display: block;
  position: relative;
}

.header-top .header-top-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 450px) {
  .canvas-img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.img-box-home {
  position: relative;
}

.image-adapt img,
.image-adaptv2 img {
  left: 0;
  object-fit: cover;
  object-position: center;
  top: 0;
  width: 100%;
  z-index: 1;
}

.pmt-buttons input[type='radio'] + .form-label--radio {
  display: flex;
  align-items: center;
}

.pmt-buttons input[type='radio'] + .form-label--radio:before,
.pmt-buttons input[type='radio'] + .form-label--radio:after {
  top: auto !important;
}

.product-form__input .sizeguide-link {
  /* margin-left: auto; */
  font-size: 0.8125rem;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.875rem;
  position: relative;
}
@media (max-width: 1499.98px) {
  .product-form__input .sizeguide-link {
    margin-left: 0;
  }
}
.product-form__input .sizeguide-link:before {
  content: '';
  border-bottom: 2px solid;
  display: block;
  width: 0;
  transition: all 0.3s ease;
  position: absolute;
  left: 0;
  bottom: 0;
}
.product-form__input .sizeguide-link:hover:before {
  width: 100%;
}

.size-guide__wrapper {
  display: flex;
  gap: 2.6875rem;
}
@media (max-width: 767.98px) {
  .size-guide__wrapper {
    flex-direction: column;
  }
}

.size-guide__image {
  flex: 0 0 42%;
  max-width: 42%;
}
@media (max-width: 767.98px) {
  .size-guide__image {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.size-guide__detail {
  flex: 1;
}
.size-guide__detail > h5,
.size-guide__detail > .h5 {
  margin-bottom: 1.875rem;
}
.size-guide__detail table {
  font-size: 0.875rem;
  /* margin-bottom: 1.875rem; */
  width: 100%;
}
.size-guide__detail table thead th {
  color: #767676;
  padding-bottom: 0.8125rem;
}
.size-guide__detail table tbody td {
  line-height: 2.1875rem;
}

.cart--totals-title {
  margin-top: 0px;
  margin-bottom: 0px !important;
  background: #dee2e6;
  padding: 5px 10px 3px 20px;
}

.content-cart-right > div:first-child {
  border: 1px solid #dee2e6;
  border-left: 2px solid #3c3c3c;
}

.button.wpbutton {
  background-color: #f7f7f7;
  border: 1px solid #232323;
}

.button.wpbutton:hover {
  background-color: #232323;
  color: #fff;
}

.skin-4.active .sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  color: #222;
  -webkit-box-shadow: 0 8px 10px rgba(34, 34, 34, 0.05);
  box-shadow: 0 8px 10px rgba(34, 34, 34, 0.05);
  z-index: 9;
  /* padding: calc(15px + (24 - 15) * ((100vw - 320px) / (1920 - 320))) 0; */
}

.theme-option {
  position: fixed;
  bottom: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
  right: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
  z-index: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.theme-option .back-to-top {
  background-color: #000;
  padding: 0;
  border-radius: 5px;
  z-index: 1;
  margin-top: 12px;
}

.theme-option .back-to-top a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
  height: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
}

.top-position {
  display: block !important;
}

.customer-login-cta {
  font-size: 0.875rem;
  letter-spacing: -0.03125rem;
  padding-top: 0.5rem;
  text-transform: uppercase;
}

.customer-information-block .btn-link {
  padding: 0;
  vertical-align: baseline;
}

.checkout-guest-button {
  font-size: 14px;
  letter-spacing: 1px;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff !important;
  background-color: #232323;
  border: 1px solid #232323;
  border-radius: 0.25rem !important;
}

@media (max-width: 767px) {
  .header-top {
    font-size: 12px;
    padding: 0px !important;
  }
}

.form-wrap[data-v-8c5cdcd4] {
  background: #fafafa;
  padding: 50px;
}

.react-tel-input .flag-dropdown.open {
  z-index: 10 !important;
}

.react-tel-input .country-list .search {
  padding: 10px !important;
}

.website-image {
  display: block;
}

.mobile-image {
  display: none;
}

@media (max-width: 550px) {
  .website-image {
    display: none;
  }

  .mobile-image {
    display: block;
  }
}

.slick-dots li a img {
  height: auto !important;
}

.halo-row-item {
  padding: 0 8px;
}
