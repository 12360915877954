.template-product .footer {
  padding-bottom: 70px;
}
.productView-variants .form__label {
  display: block;
  margin: 0 0 4px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 22px;
  text-transform: capitalize;
  text-align: left;
  color: #232323;
}
.productView-variants .form__label [data-header-option] {
  color: #3c3c3c;
  font-weight: 400;
  margin-left: 2px;
}
.productView-variants .product-form__label {
  border-radius: 20px;
  position: relative;
  font-size: 0;
  width: auto;
  min-width: 40px;
  height: 40px;
  line-height: 37px;
  border: 1px solid #cbcbcb;
  background: 0 0;
  cursor: pointer;
  padding: 0 10px;
  opacity: 1;
  margin: 5px 10px 14px 0;
}
.productView-variants .product-form__label:hover,
.productView-variants .product-form__radio:checked + .product-form__label {
  border-color: #232323;
}
.productView-variants .product-form__label .text {
  display: block;
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
  color: #232323;
  text-align: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.productView-variants .product-form__label.soldout {
  opacity: 0.5;
}
.productView-variants .product-form__label.soldout::before {
  content: "";
  top: 50%;
  left: 0;
  height: 1px;
  opacity: 1;
  z-index: 1;
  border: none;
  position: absolute;
  visibility: visible;
  width: 100%;
  background-color: #e95144;
  -webkit-transform: translateY(-50%) rotate(-45deg);
  transform: translateY(-50%) rotate(-45deg);
}
.productView-variants .product-form__swatch .product-form__label {
  border-radius: 50%;
  line-height: 40px;
  padding: 3px;
  margin-bottom: 3px;
}
.productView-variants .product-form__swatch .product-form__label .pattern {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  font-size: 0;
  letter-spacing: 0;
  position: relative;
  background-size: cover;
}
.productView-rating,
.review-rating {
  display: inline-block;
  vertical-align: bottom;
  margin-left: -2px;
}
.productView-information .card-price .money,
.productView-rating .spr-badge-starrating,
.review-rating .spr-badge-starrating {
  font-size: 18px;
}
.productView-rating .spr-icon::before,
.review-rating .spr-icon::before {
  font-family: inherit;
  font-size: 18px;
  font-style: normal;
  width: auto;
  height: auto;
  font-weight: 400;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
}
.productView-rating .spr-icon.spr-icon-star::before,
.review-rating .spr-icon.spr-icon-star::before {
  content: "\2605";
  color: #232323;
}
.productView-rating .spr-icon.spr-icon-star-empty::before,
.review-rating .spr-icon.spr-icon-star-empty::before {
  content: "\2605";
  color: #c8c8c8;
}
.productView-rating .spr-badge-caption,
.review-rating .spr-badge-caption {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  margin: 0 0 0 10px;
  text-transform: capitalize;
  letter-spacing: 0.02em;
}
.productView-short-desc {
  font-size: 12px;
  line-height: 24px;
  color: #3c3c3c;
  padding: 14px 0 0;
}
.productView-soldProduct {
  margin-left: 23px;
  display: inline-block;
  vertical-align: bottom;
}
.productView-soldProduct .text {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #e95144;
}
.productView-soldProduct .icon {
  width: 18px;
  height: 18px;
  position: relative;
  top: -2px;
  fill: #e95144;
  margin-right: 7px;
}
.productView-countDown {
  margin-bottom: 19px;
  display: inline-block;
  vertical-align: top;
  font-size: 0;
  letter-spacing: 0;
  text-align: left;
}
.productView-countDown .num {
  display: inline-block;
  vertical-align: middle;
  border: 2px solid #f0f0f0;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 13px 10px 10px;
  color: #232323;
  font-weight: 600;
  background-color: transparent;
  text-align: center;
  min-width: 55px;
  line-height: 1.6;
}
.productView-countDown .num span {
  color: #3c3c3c;
  font-weight: 400;
  font-size: 12px;
  display: block;
  text-transform: capitalize;
}
.productView-countDown .num + .num {
  margin-left: 5px;
}
.productView-countDown.style-2 .title-countDown {
  font-size: 15px;
  color: #000;
  border-bottom: 1px solid;
  margin-right: 11px;
  position: relative;
  top: 5px;
}
.previewCartItem-qty .btn-quantity.plus::after,
.previewCartItem-qty .btn-quantity::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 1px;
  left: 10px;
  top: 22px;
  background: #5a5a5a;
}
.productView-countDown.style-2 .num {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  display: inline-block;
  min-width: inherit;
  border: none;
  padding: 0;
}
.productView-countDown.style-2 .num span {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
}
.productView-countDown.style-3 {
  margin-bottom: 11px;
}
.productView-countDown.style-3 .num,
.productView-countDown.style-3 span {
  border: none;
  padding: 0;
  color: #e10600;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  min-width: auto;
  text-transform: lowercase;
}
.productView-countDown.style-3 span {
  padding-left: 5px;
}
.product-quantity .form__label {
  display: block;
  font-weight: 500;
  color: #3c3c3c;
  letter-spacing: 0.02em;
}
.previewCartItem-qty {
  display: inline-block;
  vertical-align: middle;
  width: 90%;
  margin-top: 0px;
  position: relative;
}
.previewCartItem-qty .quantity {
  padding: 11px 14px 10px 20px;
  text-align: center;
}
.previewCartItem-qty .btn-quantity {
  width: 32px;
  height: 45px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}
.previewCartItem-qty .btn-quantity.plus {
  right: 0;
  left: auto;
}
.previewCartItem-qty .btn-quantity.plus::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.productView-information {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.productView-information .halo-productView-left {
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}
.productView-information .halo-productView-left .productView-images-wrapper,
.productView-tab .tab-content .halo-custom-banner .halo-row-item .item {
  position: relative;
}
.productView-information .halo-productView-left .card__badge {
  top: 0;
}
.productView-information .halo-productView-left .card__badge.badge-left {
  left: 0;
}
.productView-information .halo-productView-left .card__badge.badge-right {
  right: 0;
}
.productView-information .halo-productView-left .productView-thumbnail-wrapper {
  margin: 8px 0 0;
  position: relative;
}
.productView-information
  .halo-productView-left
  .productView-thumbnail-wrapper
  .productView-for {
  position: relative;
  font-size: 0;
  letter-spacing: 0;
  padding: 0 35px;
}
.productView-information
  .halo-productView-left
  .productView-thumbnail-wrapper
  .productView-thumbnail {
  padding: 0 7.5px;
}
.productView-information
  .halo-productView-left
  .productView-thumbnail-wrapper
  .productView-thumbnail
  .productView-thumbnail-link {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.productView-information
  .halo-productView-left
  .productView-thumbnail-wrapper
  .slick-current
  .productView-thumbnail-link
  img {
  opacity: 0.3;
}
.productView-information
  .halo-productView-left
  .productView-thumbnail-wrapper
  .slick-arrow {
  width: 30px;
  height: 30px;
  top: 50%;
  padding: 3px !important;
  border: none;
}
.productView-information
  .halo-productView-left
  .productView-thumbnail-wrapper
  .slick-arrow
  svg {
  width: 100%;
  height: 100%;
}
.frequently-bought-together-block
  .bundle-product-left
  .bundlePdItem
  .bundle-product-options
  .halo-popup-header
  .halo-popup-close,
.productView-information
  .halo-productView-left
  .productView-thumbnail-wrapper
  .slick-arrow:hover {
  background: 0 0;
}
.productView-information
  .halo-productView-left
  .productView-thumbnail-wrapper
  .slick-arrow:hover
  svg {
  fill: #cbcbcb;
}
.frequently-bought-together-block .bundle-product-left,
.productView-information .halo-productView-right,
.productView-information
  .previewCartAction
  .previewCartGroup
  .previewCartGroup-top
  .productView-action,
.productView-tab .nav-tabs .nav-item .nav-link.active::after,
.productView-tab .nav-tabs .nav-item .nav-link:hover::after {
  width: 100%;
}
.productView-information .halo-productView-right .productView-title {
  display: block;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0;
  color: #232323;
  margin: 0 0 2px;
  line-height: 30px;
  position: relative;
  top: -7px;
}
.productView-information .productView-info {
  margin: 13px 0 16px;
}
.productView-information .productView-info .productView-info-item {
  padding-bottom: 2px;
}
.productView-information .productView-info .productView-info-item span {
  display: inline-block;
  vertical-align: top;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
}
.productView-information .card-price {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 23px;
}
.productView-information .quantity_selector {
  margin: 7px 0 8px;
}
.productView-information .quantity_selector .quantity__label {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0 0 7px;
  font-weight: 500;
}
.productView-information .quantity_selector .previewCartItem-qty {
  margin-top: 0;
  width: 110px;
}
.productView-information .productView-subtotal {
  margin: 8px 0 10px;
}
.productView-information .productView-subtotal .text {
  color: #232323;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
}
.productView-information .productView-subtotal .money {
  color: #232323;
  font-size: 13px;
  font-weight: 500;
  line-height: 23px;
}
.productView-information .productView-perks {
  margin: 18px 0 23px;
  display: block;
  white-space: nowrap;
  overflow-x: auto;
}
.productView-information .productView-perks .link,
.productView-stickyCart .sticky-product .sticky-right .sticky-actions form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.productView-information .productView-perks .link .icon {
  fill: #232323;
  stroke: transparent;
  margin: 0 10px 0 0;
  position: relative;
  top: -1px;
}
.productView-information .productView-perks .link .text {
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
  display: inline-block;
  vertical-align: middle;
}
.productView-information .productView-perks .productView-sizeChart {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
}
.productView-information .productView-perks .productView-sizeChart .icon {
  width: 25px;
  height: 16px;
}
.productView-information .productView-perks .productView-askAnExpert,
.productView-information .productView-perks .productView-compareColor {
  margin-left: 20px;
  display: inline-block;
  vertical-align: middle;
}
.productView-information .productView-perks .productView-compareColor .icon {
  width: 18px;
  height: 18px;
}
.productView-information .productView-perks .productView-askAnExpert .icon {
  width: 21px;
  height: 21px;
}
.productView-information .productView-share {
  display: inline-block;
  position: relative;
}
.productView-information .productView-share .share-button {
  display: inline-block;
  vertical-align: top;
  width: auto;
  border: none;
  min-width: auto;
  font-size: 0;
  padding: 14px 0 14px 15px;
  color: #232323;
  background-color: #fff;
}
.productView-information .productView-share .share-button .icon {
  width: 20px;
  height: 20px;
  vertical-align: top;
}
.productView-information .productView-share .share-button span {
  text-decoration: underline;
  text-underline-offset: 0.2rem;
  padding-left: 9px;
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #3c3c3c;
  text-transform: capitalize;
  font-weight: 400;
}
.productView-information .productView-share .share-button:hover span {
  color: #e95144;
}
.productView-information
  .previewCartAction
  .previewCartGroup
  .previewCartGroup-top
  .productView-wishlist
  a.wishlist-added
  svg,
.productView-information
  .previewCartAction
  .previewCartGroup
  .previewCartGroup-top
  .productView-wishlist
  a:hover
  svg,
.productView-information .productView-share .share-button:hover svg {
  fill: #e95144;
}
.productView-information
  .previewCartAction
  .previewCartGroup
  .previewCartGroup-top {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 0;
}
.productView-information
  .previewCartAction
  .previewCartGroup
  .previewCartGroup-top
  .productView-action
  .button,
.productView-information .previewCartAction .previewCartGroup-bottom .button {
  padding: 14px 15px;
}
.productView-information
  .previewCartAction
  .previewCartGroup
  .previewCartGroup-top
  .productView-wishlist {
  margin: 0 0 0 15px;
}
.productView-information
  .previewCartAction
  .previewCartGroup
  .previewCartGroup-top
  .productView-wishlist
  a {
  display: block;
  width: 50px;
  height: 50px;
  border: 1px solid #c7c7c7;
  background-color: #fff;
  text-align: center;
  overflow: hidden;
  padding: 12px;
  border-radius: 50%;
}
.productView-information
  .previewCartAction
  .previewCartGroup
  .previewCartGroup-top
  .productView-wishlist
  a
  svg {
  width: 100%;
  height: 100%;
  fill: #232323;
}
.productView-information
  .previewCartAction
  .previewCartGroup
  .previewCartGroup-top
  .productView-wishlist
  a.wishlist-added,
.productView-information
  .previewCartAction
  .previewCartGroup
  .previewCartGroup-top
  .productView-wishlist
  a:hover {
  border-color: #e95144;
}
.productView-information .previewCartAction .previewCartCheckbox {
  margin: 14px 0 12px;
}
.productView-information .share-button__fallback {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  left: auto;
  right: 0;
  padding: 2rem;
  top: 100%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  width: 300px;
  background: #fff;
  -webkit-box-shadow: 0 3px 7px #0003;
  box-shadow: 0 3px 7px #0003;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.productView-information .share-button__fallback.is-open {
  top: calc(100% + 15px);
  opacity: 1;
  z-index: 999;
  visibility: visible;
}
.productView-information .share-button__fallback .share-button__close {
  position: absolute;
  top: 14px;
  right: 22px;
  font-size: 0;
}
.productView-information .share-button__fallback .form-label {
  letter-spacing: 0.02em;
}
.productView-information .share-button__fallback .share-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.productView-information .share-button__fallback .form-field {
  width: calc(100% - 56px);
}
.productView-information .share-button__fallback .button {
  width: 46px;
  margin: 0 0 0 10px;
  min-width: auto;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 0;
}
.productView-information .share-button__fallback .button svg {
  fill: #ffffff;
  height: auto;
}
.productView-information .productView-more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 15px;
}
.productView-information .productView-more svg {
  width: 19px;
  height: 19px;
  margin: -2px 10px 0 0;
  fill: #232323;
}
.productView-information .productView-more .text {
  color: #3c3c3c;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 22px;
}
.productView-pickupAvailable {
  margin: 25px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.productView-pickupAvailable .icon {
  position: relative;
  top: -1px;
}
.productView-pickupAvailable .icon svg {
  margin-right: 15px;
  width: 17px;
  height: 17px;
  fill: #108043;
}
.productView-pickupAvailable span {
  display: block;
  font-size: 12px;
  letter-spacing: 0.02em;
  padding-bottom: 3px;
}
.productView-pickupAvailable a {
  margin-top: 10px;
  display: block;
}
.productView-securityImage {
  margin: 28px 0 20px;
}
.productView-securityImage ul {
  font-size: 0;
  letter-spacing: 0;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  margin: 0;
}
.productView-securityImage ul li {
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  margin-bottom: 5px;
}
.frequently-bought-together-block
  .bundle-product-wrapper
  .slick-slide:first-child
  .bundlePdItem-wrapper::before,
.frequently-bought-together-block .halo-block-header .title:before,
.productView-nextProducts {
  display: none;
}
.frequently-bought-together-block {
  padding-bottom: 70px;
  padding-top: 36px;
  margin-top: 27px;
  background: #f8f8f8;
}
.frequently-bought-together-block .halo-block-header {
  margin: 0 0 35px;
}
.frequently-bought-together-block .halo-block-header .title {
  text-align: left;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #232323;
}
.frequently-bought-together-block .bundle-product-wrapper .slick-arrow {
  top: -51px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  border: none;
  background: 0 0;
  padding: 4px !important;
  width: 28px;
  height: 28px;
}
.frequently-bought-together-block
  .bundle-product-wrapper
  .slick-arrow.slick-next {
  right: 5px;
}
.frequently-bought-together-block
  .bundle-product-wrapper
  .slick-arrow.slick-prev {
  left: inherit;
  right: 42px;
}
.frequently-bought-together-block .bundle-product-wrapper .slick-arrow svg {
  top: -8px;
}
.frequently-bought-together-block
  .bundle-product-wrapper
  .slick-arrow:hover
  svg {
  fill: #232323;
}
.frequently-bought-together-block .bundle-product-wrapper .slick-dots {
  padding: 25px 0 0;
}
.frequently-bought-together-block
  .bundle-product-wrapper
  .slick-active.slick-current
  .bundlePdItem
  .bundle-product-options {
  right: auto;
  left: 0;
}
.frequently-bought-together-block
  .bundle-product-wrapper
  .slick-active.slick-current
  .bundlePdItem.is-open
  .bundle-product-options {
  right: auto;
  left: 15px;
}
.frequently-bought-together-block .bundle-product-left .bundlePdItem {
  padding-left: 15px;
  padding-right: 15px;
}
.frequently-bought-together-block
  .bundle-product-left
  .bundlePdItem
  .bundlePdItem-wrapper {
  padding: 10px;
  background-color: #fff;
  position: relative;
}
.frequently-bought-together-block
  .bundle-product-left
  .bundlePdItem
  .bundlePdItem-wrapper:before {
  content: "+";
  position: absolute;
  font-size: 24px;
  left: -15px;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #3e3e3e;
  z-index: 1;
}
.frequently-bought-together-block
  .bundle-product-left
  .bundlePdItem
  .bundle-product-content {
  padding: 20px 0 0;
  text-align: left;
}
.frequently-bought-together-block
  .bundle-product-left
  .bundlePdItem
  .bundle-product-content
  .bundle-product-checkbox {
  position: relative;
  width: 100%;
  padding-bottom: 7px;
}
.frequently-bought-together-block
  .bundle-product-left
  .bundlePdItem
  .bundle-product-content
  .product-title {
  overflow: hidden;
  white-space: normal;
  display: inline;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  font-weight: 400;
}
.frequently-bought-together-block
  .bundle-product-left
  .bundlePdItem
  .bundle-product-content
  .card-price {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding-bottom: 16px;
}
.frequently-bought-together-block
  .bundle-product-left
  .bundlePdItem
  .bundle-product-content
  .button {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 12px;
}
.frequently-bought-together-block
  .bundle-product-left
  .bundlePdItem
  .bundle-product-options {
  max-width: 400px;
  width: 285px;
  position: absolute;
  left: auto;
  right: 0;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-box-shadow: 0 3px 7px #0003;
  box-shadow: 0 3px 7px #0003;
  z-index: 9999;
}
.frequently-bought-together-block
  .bundle-product-left
  .bundlePdItem
  .bundle-product-options
  .halo-popup-header {
  background-color: #f5f5f5;
  padding: 0 20px;
  margin: 0;
}
.frequently-bought-together-block
  .bundle-product-left
  .bundlePdItem
  .bundle-product-options
  .halo-popup-header
  .halo-popup-title {
  padding-top: 12px;
  padding-bottom: 10px;
  letter-spacing: 0.02em;
  text-align: left;
  font-size: 14px;
}
.frequently-bought-together-block
  .bundle-product-left
  .bundlePdItem
  .bundle-product-options
  .halo-popup-wrapper {
  padding: 20px;
}
.frequently-bought-together-block
  .bundle-product-left
  .bundlePdItem.is-open
  .bundle-product-options {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  z-index: 101;
  right: 15px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.frequently-bought-together-block .bundle-product-right {
  background: #f8f8f8;
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 15px 0 0 15px;
  text-align: left;
}
.frequently-bought-together-block
  .bundle-product-right
  .bundle-product-totalPrice {
  display: block;
  letter-spacing: 0;
  font-size: 0;
  margin-bottom: 17px;
}
.frequently-bought-together-block
  .bundle-product-right
  .bundle-product-totalPrice
  .text {
  display: block;
  color: #3c3c3c;
  font-size: 12px;
  font-weight: 400;
  margin: 0 0 10px;
  letter-spacing: 0.02em;
}
.frequently-bought-together-block
  .bundle-product-right
  .bundle-product-totalPrice
  .old-price {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  font-weight: 500;
  color: #969696;
  margin: 0 10px 0 0;
  text-decoration: line-through;
}
.frequently-bought-together-block
  .bundle-product-right
  .bundle-product-totalPrice
  .bundle-price {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  font-weight: 600;
  color: #e95144;
}
.frequently-bought-together-block
  .bundle-product-right
  .bundle-product-action
  .button {
  max-width: 210px;
  width: 100%;
  min-width: 210px;
  margin: 0;
}
.frequently-bought-together-block .bundle-product-right .bundle-product-text {
  padding-top: 15px;
  max-width: 210px;
  margin: 0;
  line-height: 22px;
}
.productView-stickyCart {
  position: fixed;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 10px 0;
  border-bottom: 1px solid #ededed;
}
.productView-stickyCart.show-sticky {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  top: auto;
  bottom: 69px;
}
.productView-stickyCart .sticky-product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.productView-stickyCart .sticky-product .sticky-left {
  display: none;
  width: calc(100% - 650px);
}
.productView-stickyCart .sticky-product .sticky-left .sticky-image {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 55px;
  overflow: hidden;
}
.productView-stickyCart .sticky-product .sticky-left .sticky-info {
  padding-left: 20px;
  width: calc(100% - 45px);
}
.productView-stickyCart
  .sticky-product
  .sticky-left
  .sticky-info
  .sticky-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #232323;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin: 0;
}
.productView-stickyCart
  .sticky-product
  .sticky-left
  .sticky-info
  .sticky-price {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 22px;
  color: #232323;
}
.productView-stickyCart .sticky-product .sticky-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}
.productView-stickyCart .sticky-product .sticky-right .sticky-options {
  width: 100%;
  max-width: 250px;
  margin-bottom: 0;
  margin-right: 15px;
}
.productView-stickyCart
  .sticky-product
  .sticky-right
  .sticky-options
  .product-option {
  font-size: 12px;
  line-height: 1.5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin: 0 0 10px;
}
.productView-stickyCart
  .sticky-product
  .sticky-right
  .sticky-options
  .form__select {
  position: relative;
  background: #f5f5f5;
}
.productView-stickyCart
  .sticky-product
  .sticky-right
  .sticky-options
  .form__select
  .select__select {
  padding-top: 10px;
  padding-bottom: 11px;
  padding-right: 30px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  background: 0 0;
}
.productView-stickyCart
  .sticky-product
  .sticky-right
  .sticky-options
  .form__select
  .icon-dropdown {
  width: 45px;
  height: 45px;
  top: 0;
  bottom: inherit;
  right: 0;
  z-index: 1;
}
.productView-stickyCart .sticky-product .sticky-right .sticky-actions {
  padding-right: 0;
}
.productView-stickyCart
  .sticky-product
  .sticky-right
  .sticky-actions
  form
  .quantity_selector {
  margin: 0 15px 0 0;
}
.productView-stickyCart
  .sticky-product
  .sticky-right
  .sticky-actions
  form
  .quantity_selector
  .previewCartItem-qty {
  margin: 0;
  width: 100px;
}
.productView-stickyCart
  .sticky-product
  .sticky-right
  .sticky-actions
  form
  .quantity_selector
  .previewCartItem-qty
  .quantity,
.productView-stickyCart
  .sticky-product
  .sticky-right
  .sticky-actions
  form
  .quantity_selector
  .previewCartItem-qty
  a {
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
}
.productView-stickyCart
  .sticky-product
  .sticky-right
  .sticky-actions
  form
  .product-form__submit {
  width: 210px;
}
.show-sticky-cart.notification-show .halo-notification-popup {
  bottom: 150px !important;
}
.productView-notifyMe {
  padding: 30px 0 2px;
}
.productView-notifyMe .form-field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.productView-notifyMe .form-label {
  color: #3c3c3c;
}
.productView-notifyMe .form-input {
  width: calc(100% - 185px);
}
.productView-notifyMe .button {
  width: 175px !important;
  margin: 0 0 0 10px;
}
.productView-tab .nav-tabs {
  text-align: center;
  margin: 32px 0 20px;
  border-bottom: 1px solid #e6e6e6;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: none;
}
.productView-tab .nav-tabs .nav-item {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  margin: 0;
  position: relative;
  text-align: left;
}
.productView-tab .nav-tabs .nav-item .nav-link {
  font-size: 20px;
  font-weight: 600;
  text-transform: none;
  padding: 15px 0;
  margin: 0 15px;
  overflow: visible;
  position: relative;
  border: none;
  display: block;
  letter-spacing: 0.02em;
  color: #000;
}
.productView-tab .nav-tabs .nav-item .nav-link::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 3px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #000;
}
.productView-tab .tab-content {
  padding: 18px 0 15px;
}
.productView-tab .tab-content .toggle-title {
  font-size: 16px;
  font-weight: 600;
  text-transform: none;
  padding: 18px 0 16px;
  margin: 0;
  color: #000;
  letter-spacing: 0.02em;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: block;
  position: relative;
  border-top: 1px solid #e6e6e6;
  cursor: pointer;
}
.productView-tab .tab-content .toggle-title::after,
.productView-tab .tab-content .toggle-title::before {
  content: "";
  position: absolute;
  top: 50%;
  z-index: 2;
  -webkit-transform: translateY(-50%) rotate(0);
  transform: translateY(-50%) rotate(0);
  background-color: #232323;
  transition: transform 350ms, visibility 350ms, -webkit-transform 350ms;
}
.productView-tab .tab-content .toggle-title::before {
  right: 5px;
  width: 2px;
  height: 12px;
}
.productView-tab .tab-content .toggle-title::after {
  right: 0;
  width: 12px;
  height: 2px;
}
.productView-tab .tab-content .toggle-title.is-clicked::before {
  -webkit-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
}
.productView-tab .tab-content .toggle-content h4 {
  margin-top: 25px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #232323;
}
.productView-tab .tab-content .toggle-content ol,
.productView-tab .tab-content .toggle-content ul {
  margin-left: 25px;
}

#tab-review .spr-form .spr-form-actions,
.productView-tab .tab-content .toggle-content ol li + li,
.productView-tab .tab-content .toggle-content ul li + li {
  margin-top: 5px;
}
.productView-tab .tab-content .toggle-content ol li {
  list-style-type: auto;
  padding-left: 5px;
}
.productView-tab .tab-content .blockQuote {
  margin-top: 18px;
}
.productView-tab .tab-content .blockQuote .title {
  color: #3c3c3c;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 22px;
  letter-spacing: 0.02em;
}
.productView-tab .tab-content .blockQuote .blockQuote-content {
  color: #3c3c3c;
  font-size: 12px;
}
.productView-tab .tab-content .blockQuote .blockQuote-content.has-bg {
  padding: 30px 40px;
  background-color: #fafafa;
}
.productView-tab .tab-content .blockQuote + .blockQuote {
  margin-top: 40px;
}
.productView-tab .tab-content .blockQuote + .blockQuote .title {
  margin-bottom: 18px;
}
.productView-tab .tab-content .halo-custom-banner {
  margin: 25px 0 0;
}
.productView-tab .tab-content .halo-custom-banner > .row > .halo-row-item {
  padding-top: 15px;
  padding-bottom: 15px;
}
.productView-tab
  .tab-content
  .halo-custom-banner
  .halo-row-item
  .wrapper-content {
  border: 1px solid #232323;
  width: 100%;
  height: 100%;
}
.productView-tab .tab-content .halo-custom-banner .halo-row-item .banner-title {
  font-size: 30px;
  letter-spacing: 0.02em;
  margin-bottom: 20px;
}
.productView-tab .tab-content .halo-custom-banner .halo-row-item .desc {
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #3c3c3c;
  line-height: 22px;
  margin: 0 auto 35px;
  max-width: 320px;
}
.productView-tab .tab-content .halo-custom-banner .halo-row-item .button {
  width: 100%;
  max-width: 217px;
}
.productView-tab .tab-content .custom-desc {
  margin-top: 30px;
}
.productView-tab .tab-content .custom-desc dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #cbcbcb;
  margin: 0;
  border-bottom: none;
}
.productView-tab .tab-content .custom-desc dl dd,
.productView-tab .tab-content .custom-desc dl dt {
  padding: 11px 10px 10px;
}
.productView-tab .tab-content .custom-desc dl dt {
  font-weight: 500;
  max-width: 400px;
  min-width: 150px;
  color: #232323;
  letter-spacing: 0.02em;
  margin: 0;
}
.productView-tab .tab-content .custom-desc dl dd {
  border-left: 1px solid #cbcbcb;
  margin: 0;
  letter-spacing: 0.02em;
  color: #3c3c3c;
}
.productView-tab .tab-content .custom-desc dl:last-child {
  border-bottom: 1px solid #cbcbcb;
}
.productView-tab .tab-content .custom-desc.style-2,
.productView-tab.layout-vertical
  .tab-content
  .toggle-content
  .wrapper-content
  .custom-desc {
  margin: 0;
}
.productView-tab .tab-content .custom-desc.style-2 dd,
.productView-tab .tab-content .custom-desc.style-2 dl {
  border: none;
}
.productView-tab .tab-content .custom-desc.style-2 dl:nth-child(odd) {
  background: #f1f1f1;
}
.productView-tab .tab-content .custom-desc.style-2 p {
  margin-top: 25px;
}
.productView-tab .tab-content #tab-shipping-return h4 {
  margin-top: 0;
  letter-spacing: 0.02em;
  color: #232323;
  font-size: 14px;
  margin-bottom: 12px;
}
.productView-tab .tab-content #tab-shipping-return p {
  font-size: 12px;
  letter-spacing: 0.02em;
  line-height: 24px;
  color: #3c3c3c;
  margin-bottom: 23px;
}
.productView-tab.layout-vertical .tab-content .toggle-title {
  display: block;
}
.productView-tab.layout-vertical .tab-content > .tab-pane {
  display: block;
  opacity: 1;
}
.productView-tab.layout-vertical .tab-content .toggle-content {
  -webkit-transition: max-height 0.5s ease-out;
  transition: max-height 0.5s ease-out;
  max-height: 0;
  position: relative;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  border-bottom: 1px solid transparent;
}
.productView-tab.layout-vertical .tab-content .toggle-content .wrapper-content {
  padding: 15px 0 30px;
}
.productView-tab.layout-vertical
  .tab-content
  .toggle-content
  .wrapper-content
  .custom-desc
  dl
  dt {
  max-width: 170px;
}
#tab-review .productView-review .spr-header {
  margin-top: -10px;
}
#tab-review .productView-review .spr-summary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
#tab-review .productView-review .spr-summary .spr-starrating {
  display: block;
  text-align: center;
  margin-right: 0;
  margin-bottom: 0;
}
#tab-review .productView-review .spr-summary .spr-summary-caption {
  margin-bottom: 17px;
  letter-spacing: 0.02em;
}
#tab-review .productView-review .spr-summary .button {
  width: 100%;
  max-width: 218px;
  font-size: 14px;
}
#tab-review .spr-form {
  width: 100%;
  max-width: 570px;
}
#tab-review .spr-form .spr-form-title {
  display: block;
  text-align: left;
  text-transform: uppercase;
  margin: 42px 0 26px;
  font-size: 14px;
  letter-spacing: 0.05em;
}
#tab-review .spr-form .spr-form-contact-email,
#tab-review .spr-form .spr-form-contact-location,
#tab-review .spr-form .spr-form-contact-name,
#tab-review .spr-form .spr-form-review-body,
#tab-review .spr-form .spr-form-review-rating,
#tab-review .spr-form .spr-form-review-title {
  margin: 0 0 20px;
}
#tab-review .spr-form .spr-form-review-rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
#tab-review .spr-form .spr-form-review-rating .spr-form-label {
  width: 60px;
  margin: 0;
}
#tab-review .spr-form .spr-form-review-rating .spr-badge-starrating {
  font-size: 38px;
}
#tab-review
  .spr-form
  .spr-form-review-rating
  .spr-badge-starrating
  .spr-icon::before {
  font-size: 29px;
}
#tab-review .spr-form .spr-form-input-textarea {
  padding: 20px 17px;
}
#tab-review .spr-form .spr-form-actions .button {
  width: 100%;
  max-width: 220px;
  padding-bottom: 14px;
  padding-top: 14px;
}
#tab-review .spr-reviews {
  margin: 35px 0 0;
}
#tab-review .spr-reviews .spr-review {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  padding: 25px 30px 35px;
  border: 1px solid #e6e6e6;
  width: 100%;
  background-color: #fff;
  position: relative;
}
#tab-review .spr-reviews .spr-review .spr-review-header-title {
  color: #232323;
  font-size: 18px;
  text-transform: capitalize;
  margin: 8px 0 9px;
  letter-spacing: 0.02em;
  font-weight: 600;
}
#tab-review .spr-reviews .spr-review .spr-review-header-byline {
  display: block;
  color: #999;
  margin: 0 0 12px;
  opacity: 1;
}
#tab-review .spr-reviews .spr-review .spr-review-header-byline strong {
  font-weight: 400;
  letter-spacing: 0.02em;
}
#tab-review .spr-reviews .spr-review .spr-review-content-body {
  color: #232323;
}
#tab-review .spr-reviews .spr-review .spr-review-footer {
  position: absolute;
  top: 55px;
  right: 35px;
}
#tab-review
  .spr-reviews
  .spr-review
  .spr-review-footer
  .spr-review-reportreview {
  display: inline-block;
  vertical-align: top;
  float: none;
  font-style: normal;
  text-decoration: underline;
  text-underline-offset: 0.3rem;
  font-size: var(--font-body-size);
  color: #e95144;
}
.image-zoom {
  width: 100%;
  height: 100%;
  cursor: crosshair;
}
.image-zoom img {
  max-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
@media (max-width: 1024px) {
  .productView-tab .tab-content .toggle-content {
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
}
@media (max-width: 992px) {
  .productView-soldProduct {
    display: block;
    margin-left: 0;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .halo-custom-banner .item {
    width: 100%;
    margin-bottom: 15px;
  }
  .halo-custom-banner .item .content-box {
    position: relative;
    padding: 35px 15px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    top: 0;
  }
  .halo-custom-banner .item .content-box .button {
    margin-top: 0;
  }
  .productView-tab .tab-content > .tab-pane {
    display: block;
    opacity: 1;
  }
  .productView-tab .tab-content .toggle-content {
    -webkit-transition: max-height 0.5s ease-out;
    transition: max-height 0.5s ease-out;
    max-height: 0;
    overflow: hidden;
    position: relative;
  }
  .productView-tab .tab-content .toggle-content .wrapper-content {
    padding: 15px 0 30px;
  }
}
@media (max-width: 551px) {
  .productView-soldProduct {
    margin-left: 0;
    display: block;
    margin-top: 10px;
  }
  .frequently-bought-together-block .bundle-product-left .bundlePdItem {
    padding: 0;
  }
  .frequently-bought-together-block
    .bundle-product-left
    .bundlePdItem
    .bundlePdItem-wrapper::before,
  .productView-stickyCart
    .sticky-product
    .sticky-right
    .sticky-actions
    form
    .quantity_selector {
    display: none;
  }
  .productView-stickyCart
    .sticky-product
    .sticky-right
    .sticky-actions
    form
    .product-form__submit {
    width: 160px;
  }
  .productView-tab .tab-content #tab-review .spr-reviews .spr-review {
    padding: 25px 20px 35px;
  }
  .productView-tab
    .tab-content
    #tab-review
    .spr-reviews
    .spr-review
    .spr-review-footer {
    position: relative;
    top: 0;
    right: 0;
    margin-top: 15px;
  }
}
@media (min-width: 768px) {
  .productView-information .halo-productView-left {
    width: 50%;
    margin-bottom: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 30px;
  }
  .productView-information .halo-productView-right {
    width: 50%;
    padding-left: 30px;
  }
  .frequently-bought-together-block
    .bundle-product-left
    .bundlePdItem
    .bundlePdItem-wrapper {
    padding: 15px;
  }
  .productView-tab .nav-tabs {
    display: block;
  }
  .productView-tab .tab-content .toggle-title {
    display: none;
  }
  .halo-custom-banner .halo-row-item.style-2 .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
}
@media (min-width: 992px) {
  .productView-securityImage ul li {
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .productView-tab .nav-tabs .nav-item .nav-link {
    margin: 0 26px;
  }
  .productView-stickyCart .sticky-product .sticky-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .productView-stickyCart .sticky-product .sticky-right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    width: 650px;
  }
}
@media (min-width: 1025px) {
  .productView-securityImage ul {
    overflow: visible;
  }
  .productView-nextProducts {
    display: block;
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 4;
  }
  .productView-nextProducts .next-prev-icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .productView-nextProducts .next-prev-icons .next-prev-icon.next-icon {
    margin-left: 24px;
  }
  .productView-nextProducts .next-prev-icons .next-prev-icon .icon {
    width: 15px;
    height: 15px;
  }
  .productView-nextProducts .next-prev-modal {
    position: absolute;
    top: calc(100% - 6px);
    left: auto;
    right: 0;
    z-index: 3;
    padding-top: 10px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
    -webkit-transition: -webkit-transform 350ms;
    transition: transform 350ms;
    transition: transform 350ms, -webkit-transform 350ms;
  }
  .productView-nextProducts .next-prev-modal .next-prev-content {
    display: none;
    width: 250px;
    background: #fff;
    padding: 10px 10px 8px 15px;
    -webkit-box-shadow: 0 3px 7px #0003;
    box-shadow: 0 3px 7px #0003;
  }
  .productView-nextProducts .next-prev-modal .next-prev-content.is-active {
    display: block;
  }
  .productView-nextProducts .next-prev-modal .card-information {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .productView-nextProducts
    .next-prev-modal
    .card-information
    .card-information__wrapper {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 60px);
    padding: 0 25px 0 0;
    position: relative;
    top: -6px;
  }
  .productView-nextProducts .next-prev-modal .card-information .card-vendor {
    margin: 0;
    overflow: hidden;
  }
  .productView-nextProducts .next-prev-modal .card-information .card-vendor a {
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    color: #969696;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.02em;
  }
  .productView-nextProducts .next-prev-modal .card-information .card-title {
    font-size: 11px;
    color: #232323;
    letter-spacing: 0.02em;
    overflow: hidden;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
  .productView-nextProducts .next-prev-modal .card-information .card-price {
    font-size: 13px;
    margin-top: 3px;
    color: #232323;
    letter-spacing: 0.02em;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .productView-nextProducts .next-prev-modal .card-media {
    display: inline-block;
    vertical-align: top;
    width: 60px;
    height: 90px;
    position: relative;
    z-index: 1;
  }
  .productView-nextProducts .next-prev-modal.is-show {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  .frequently-bought-together-block {
    clear: both;
  }
  .frequently-bought-together-block .bundle-product-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .frequently-bought-together-block .bundle-product-left {
    width: calc(100% - 240px);
  }
  .frequently-bought-together-block .bundle-product-right {
    width: 100%;
    max-width: 240px;
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 15px 0 0 30px;
  }
  .frequently-bought-together-block
    .bundle-product-right
    .bundle-product-action
    .button,
  .frequently-bought-together-block .bundle-product-right .bundle-product-text {
    margin: 0 auto;
  }
  .productView-tab .tab-content .custom-desc dl dd,
  .productView-tab .tab-content .custom-desc dl dt {
    padding: 11px 20px 10px;
  }
  .productView-tab .tab-content .custom-desc dl dt {
    width: 100%;
  }
  .productView-stickyCart {
    -webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
    border: none;
  }
  .productView-stickyCart.show-sticky {
    bottom: 0;
  }
}
@media (min-width: 1200px) {
  .productView-information .halo-productView-left {
    width: 470px;
  }
  .productView-information .halo-productView-right {
    width: calc(100% - 470px);
  }
}
