.skin-2,
.skin-2 .desc,
.skin-2 .link,
.skin-2 label {
  color: #000;
  letter-spacing: 0;
}
.skin-2,
.skin-2 .button,
.skin-2 .desc,
.skin-2 .link {
  font-size: 16px;
  line-height: 28px;
}
.skin-2 {
  font-weight: 400;
}
.skin-2 .desc,
.skin-2 .link {
  font-weight: 400;
}
.skin-2 label {
  font-size: 16px;
}
.skin-2 input[type='email'],
.skin-2 input[type='text'] {
  padding: 13px 12px 12px;
  border-radius: 0;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  font-weight: 400;
  color: #646464;
}
.skin-2 .icon {
  fill: #000000;
}
.skin-2 .link-underline:hover .text {
  text-underline-offset: 0.2rem;
}
.skin-2 .button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  letter-spacing: 0.05em;
  font-weight: 700;
  border-radius: 0;
  height: 55px;
}
.skin-2 .button.button-1 {
  background: #fff;
  border-color: #fff;
  color: #4f8b7b;
}
.skin-2 .button.button-1:hover {
  background: #4f8b7b;
  border-color: #fff;
  color: #fff;
}
.skin-2 .button.button-2,
.skin-2 .button.button-3:hover {
  background: #fff;
  border-color: #000;
  color: #000;
}
.skin-2 .button.button-2:hover,
.skin-2 .button.button-3 {
  background: #000;
  border-color: #000;
  color: #fff;
}
.skin-2 .halo-block-header {
  position: relative;
  margin-bottom: 18px;
}
.skin-2 .halo-block-header .title {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  color: #202020;
}
.skin-2 .halo-block-header .title::before {
  display: none;
}
.skin-2 .halo-block-header .title span {
  width: 100%;
  background: 0 0;
}
.skin-2 .card__badge {
  top: 0;
  left: 0;
}
.skin-2 .card__badge .badge,
.skin-2 .halo-recently-viewed-popup .recently-viewed-tab.recently-viewed-list .products-grid .no-products .text,
.skin-2 .halo-sidebar_menu .nav-currency-language .disclosure__list,
.skin-2 .halo-toolbar-bottom-mobile .halo-toolbar-wrapper .halo-toolbar-item .label,
.skin-2 .product-card .card-product__group .card-icon .text {
  font-size: 14px;
}
.skin-2 .card__badge .badge.new-badge {
  color: #000;
  background-color: #fff;
  border: 1px solid #ebebeb;
}
.skin-2 .card__badge .badge.Bundle-badge {
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
}
.skin-2 .card-price .price-item--regular {
  font-size: 16px;
  line-height: 25px;
  font-weight: 600;
}
.skin-2 .product-card .product-card-information .card-vender {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 25px;
  color: #969696;
  margin-top: 2px;
}
.skin-2 .product-card .product-card-information .text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  color: #000;
  line-height: 28px;
}
.skin-2 .product-card .product-card-information .card-price {
  margin-top: -3px;
  font-size: 16px;
  font-weight: 600;
}
.skin-2 .product-card .product-card-information .card-price .price-item.price-item--regular .money {
  color: #d5d5d5;
  font-weight: 400;
}
.skin-2 .product-card .product-card-information .card-price .price-item.price__last .money {
  color: #e74a45;
}
.skin-2 .product-card .product-card-information .card-price .price-item .money {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  color: #000;
  letter-spacing: 0;
}
.skin-2 .product-card .product-card-information .card-swatch {
  margin-top: 16px;
}
.skin-2 .product-card .product-card-bottom {
  padding: 11px 0 10px;
}
.skin-2 .product-card .card-action {
  /* background-color: #fff; */
}
.skin-2 .product-card .card-action .button {
  height: 45px;
}
.skin-2.quickSearchResult-show {
  overflow: hidden;
}
.skin-2.quickSearchResult-show .background-header-search {
  z-index: 1;
  opacity: 1;
  visibility: visible;
}
.skin-2 .halo-ask-an-expert-popup .halo-popup-header .halo-popup-title,
.skin-2 .halo-compare-color-popup .halo-popup-header .halo-popup-title,
.skin-2 .halo-sidebar_menu .halo-sidebar-header .title,
.skin-2 .halo-sidebar_search .halo-sidebar-header .title {
  font-size: 20px;
  text-transform: capitalize;
}
.skin-2 .halo-ask-an-expert-popup .field__input,
.skin-2 .halo-ask-an-expert-popup .form-input,
.skin-2 .halo-ask-an-expert-popup .wrapper-content .ask-an-expert .intro,
.skin-2 .halo-ask-an-expert-popup .wrapper-content .ask-an-expert .text,
.skin-2 .halo-ask-an-expert-popup label,
.skin-2 .halo-auth-sidebar .halo-sidebar-wrapper .auth-actions .auth-link,
.skin-2 .halo-cart-sidebar .haloCalculatorShipping .message,
.skin-2 .halo-cart-sidebar .previewCartCheckbox input[type='checkbox'] + label,
.skin-2 .halo-cart-sidebar .previewCartCheckbox input[type='checkbox'] ~ a,
.skin-2 .halo-cart-sidebar .previewCartItem-name,
.skin-2 .halo-cart-sidebar .previewCartItem-options span,
.skin-2 .halo-compare-color-popup .wrapper-content .halo-compareColors .halo-compareColors-text,
.skin-2 .halo-edit-cart-popup .add-more,
.skin-2 .halo-edit-cart-popup .product-edit-itemCenter .product-edit-title-variant,
.skin-2 .halo-edit-cart-popup .productView-variants .form__label,
.skin-2 .halo-edit-cart-popup .productView-variants .product-form__label .text,
.skin-2 .halo-quick-view-popup .previewCartCheckbox input[type='checkbox'] + label,
.skin-2 .halo-quick-view-popup .previewCartCheckbox input[type='checkbox'] ~ a,
.skin-2 .halo-quick-view-popup .productView-information .productView-more .text,
.skin-2 .halo-quick-view-popup .productView-rating .spr-badge-caption,
.skin-2 .halo-quick-view-popup .productView-soldProduct .text,
.skin-2 .halo-quick-view-popup .productView-variants .form__label,
.skin-2 .halo-quick-view-popup .productView-variants .product-form__label .text,
.skin-2 .halo-quickshop-popup .halo-productView-right .productView-variants .form__label,
.skin-2 .halo-quickshop-popup .halo-productView-right .productView-variants .product-form__label .text,
.skin-2 .halo-quickshop-popup .halo-productView-right .productView-variants .product-form__label.available .text,
.skin-2 .halo-quickshop-popup .halo-productView-right .productView-variants [data-header-option],
.skin-2 .halo-sidebar_menu .site-nav-mobile.nav .menu-lv-item .menu-lv-1__action,
.skin-2 .halo-sidebar_search .search-bar .input-group-field,
.skin-2 .productView-countDown .num {
  font-size: 16px;
}
.skin-2 .halo-ask-an-expert-popup .wrapper-content .ask-an-expert .heading,
.skin-2 .halo-sidebar_search .quickSearchResultsWrap .search-block-title .text {
  font-size: 16px;
  text-transform: capitalize;
}
.skin-2 .halo-ask-an-expert-popup .button {
  height: 50px;
}
.skin-2 .slick-arrow {
  width: 40px;
  height: 40px;
  top: 50%;
}
.skin-2 .slick-arrow:hover svg {
  fill: #000000;
  stroke: #000000;
}
.skin-2 .slick-arrow svg {
  top: -5px;
  right: 2px;
}
.skin-2 .slick-arrow.slick-prev {
  /* left: -15px; */
}
.skin-2 .slick-arrow.slick-next {
  /* right: -15px; */
}
.skin-2 .halo-accept-cookie-popup .desc,
.skin-2 .halo-accept-cookie-popup .group-action-links {
  font-size: 14px;
  line-height: 24px;
}
.skin-2 .halo-quick-view-popup .productView-information .previewCartAction .previewCartGroup .previewCartGroup-top .productView-wishlist a svg,
.skin-2 .header-language_currency .halo-language svg {
  position: relative;
  top: -1px;
}
.skin-2 .halo-auth-sidebar .halo-sidebar-header .title,
.skin-2 .halo-cart-sidebar .halo-sidebar-header .title {
  font-size: 20px;
}
.skin-2 .halo-auth-sidebar .halo-sidebar-wrapper .form-field .form-input {
  font-size: 16px;
  padding: 16px 12px 15px;
}
.skin-2 .halo-cart-sidebar .slick-slider {
  position: static;
}
.skin-2 .halo-cart-sidebar .slick-dots {
  position: absolute;
  top: 38px;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.skin-2 .halo-cart-sidebar .product-card .product-card-bottom {
  padding: 11px 0 10px 40px;
  margin-top: -10px;
}
.skin-2 .halo-cart-sidebar .previewCartItem-price {
  font-size: 18px;
  font-weight: 500;
}
.skin-2 .halo-cart-sidebar .previewCartCollection .previewCartTitle,
.skin-2 .halo-cart-sidebar .previewCartInfo .item,
.skin-2 .halo-edit-cart-popup .card-price .price-item .money,
.skin-2 .halo-edit-cart-popup .halo-popup-header .halo-popup-title,
.skin-2 .halo-quick-view-popup .productView-information .productView-subtotal .money,
.skin-2 .halo-quick-view-popup .productView-information .productView-subtotal .text {
  font-size: 18px;
}
.skin-2 .halo-cart-sidebar .previewCartCarousel .halo-row-item {
  padding: 0;
}
.skin-2 .halo-cart-sidebar .previewCartCarousel .card-media {
  width: 100%;
}
.skin-2 .halo-cart-sidebar .previewCartCarousel .card-media img {
  width: 108px;
  max-width: 108px;
}
.skin-2 .halo-cart-sidebar .previewCartItem-image {
  width: 108px;
}
.skin-2 .halo-edit-cart-popup .product-edit-itemCenter .product-edit-title {
  font-size: 16px;
  font-weight: 500;
}
.skin-2.ask-an-expert-show .background-overlay,
.skin-2.compare-color-show .background-overlay,
.skin-2.size-chart-show .background-overlay {
  z-index: 9999;
}
.skin-2.quick-view-show .background-overlay-popup {
  z-index: 99;
}
.skin-2 .background-header-search,
.skin-2 .background-overlay,
.skin-2 .background-overlay-popup {
  background-color: rgba(0, 0, 0, 0.4);
}
.skin-2 .background-header-search {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 25vh;
  cursor: pointer;
  display: block;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.skin-2 .halo-toolbar-bottom-mobile .halo-toolbar-wrapper .halo-toolbar-item .icon--cart .cart-count-bubble {
  background-color: #000;
  color: #fff;
  line-height: 22px;
}
.skin-2 .halo-quickshop-popup .previewCartItem-qty .quantity {
  padding: 16px 14px 15px 20px;
}
.skin-2 .halo-quickshop-popup .previewCartItem-qty .btn-quantity {
  height: 55px;
  top: 5px;
}
.skin-2 .halo-quickshop-popup .halo-productView-right .product-title {
  padding-bottom: 5px;
}
.skin-2 .halo-quick-view-popup input[type='text'] {
  padding: 16px 12px 15px;
}
.skin-2 .halo-quick-view-popup .productView-information .share-button__fallback .button-copy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.skin-2 .halo-quick-view-popup .productView-information .productView-share .share-button svg {
  position: relative;
  top: 2px;
}
.skin-2 .halo-quick-view-popup .productView-perks::-webkit-scrollbar {
  display: none;
}
.skin-2 .halo-quick-view-popup .productView-information .previewCartAction .previewCartGroup .previewCartGroup-top .productView-wishlist a {
  width: 55px;
  height: 55px;
}
.skin-2 .halo-notification-popup .halo-popup-content .product-content,
.skin-2 .halo-sidebar_menu .nav-currency-language .disclosure__list .disclosure__item .disclosure__link .text,
.skin-2 .halo-sidebar_menu .nav-currency-language .wrapper-currencies .currency-item .text,
.skin-2 .live_help_group .text,
.skin-2 .live_help_group .title {
  font-size: 16px;
}
.skin-2 .halo-sidebar_menu .nav-currency-language .disclosure__list .disclosure__item .disclosure__link svg,
.skin-2 .halo-sidebar_menu .nav-currency-language .wrapper-currencies .currency-item svg {
  position: relative;
  top: -5px;
}
.skin-2 .live_help_group .desc {
  font-size: 16px;
  margin-bottom: 16px;
}
.skin-2 .halo-block-product-tab .slick-arrow,
.skin-2 .halo-product .slick-arrow {
  top: 35% !important;
}
.skin-2 .halo-block-product-tab .slick-arrow svg,
.skin-2 .halo-product .slick-arrow svg {
  top: -5px;
}
.skin-2 .halo-block-product-tab .slick-arrow:hover svg,
.skin-2 .halo-product .slick-arrow:hover svg {
  fill: #ffffff;
  stroke: #ffffff;
}

@media (max-width: 1024px) {
  .skin-2 .slick-arrow {
    top: 35%;
  }
  .skin-2 .slick-arrow.slick-prev {
    left: -2px;
  }
  .skin-2 .slick-arrow.slick-next {
    right: -2px;
  }
}

@media (max-width: 992px) {
  .skin-2 .slick-arrow {
    top: 50%;
  }
  .skin-2 .halo-banner.style-2 .slick-arrow.slick-prev {
    left: 10px;
  }
  .skin-2 .halo-banner.style-2 .slick-arrow.slick-next {
    right: 10px;
  }
}

@media (max-width: 767px) {
  .skin-2 .product-card .text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media (min-width: 768px) {
  .skin-2 .row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .skin-2 .container.container-1640 .row {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media (min-width: 1025px) {
  .skin-2 .custom-magamenu-1 .row {
    margin: 0 -15px !important;
  }
  .skin-2 .row {
    margin-left: 0;
    margin-right: 0;
  }
  .skin-2.quickSearchResult-show {
    padding-right: 10px;
  }
  .skin-2 .product-card .card-action {
    padding: 15px;
  }
}

@media (min-width: 1670px) {
  .skin-2 .container.container-1640 {
    max-width: 1670px !important;
  }
  .skin-2 .container.container-1640 .row {
    margin: 0 -15px;
  }
}
