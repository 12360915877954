masonry-items {display: block}

.portfolio-page .page-header {
    /* background-image: url("../../../assets/images/banner-portfolio.png"); */
    background-size: cover;background-repeat: repeat-x;background-position: center top;height: 270px; background-attachment: fixed
}

.page-content {
padding-bottom: 70px
}

.shuffle-tabs .tabs {
list-style-type: none;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: start;
-ms-flex-pack: start;
justify-content: flex-start;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-ms-flex-wrap: nowrap;
flex-wrap: nowrap;
white-space: nowrap;
overflow-x: auto;
overflow-y: hidden;
width: 100%;
padding-top: 12px;
padding-bottom: 12px;
padding-right: 10px;
background-color: #fafafa
}

.shuffle-tabs .tabs .tab {
position: relative;
padding: 0 25px
}

.shuffle-tabs .tabs .tab .tab-title {
color: #232323;
font-weight: 500;
-webkit-transition: color .35s ease-in-out;
transition: color .35s ease-in-out
}

.shuffle-tabs .tabs .tab.is-active .tab-title span {
text-decoration: underline;
text-underline-offset: 0.2rem
}

.shuffle-tabs .haloPortfolioContent .desc {
max-width: 470px;
width: 100%;
margin: 0 auto;
padding: 32px 15px 38px
}

.tabs-contents .masonry-row {
margin-left: -5px;
margin-right: -5px;
}

.masonry-item p{position:absolute;bottom:10px;left:145px;font-size:22px;background:rgba(0,0,0,0.7);padding:10px;color:#fff;}
.masonry-item p a{color:#fff;}

.tabs-contents .masonry-item {
padding: 5px;
display: inline-block;
vertical-align: top;
width: 100%
}

.tabs-contents .masonry-item.sizer-element {
padding: 0 !important
}

.tabs-contents .masonry-item .item {
position: relative
}

.tabs-contents .masonry-item .item .icon {
width: 60px;
height: 60px;
background-color: #232323;
stroke: transparent;
border-radius: 50%;
position: absolute;
top: 50%;
left: 50%;
-webkit-transform: translate(-50%, calc(-50% + 10px));
transform: translate(-50%, calc(-50% + 10px));
padding: 18px;
-webkit-transition: .7s;
transition: .7s;
z-index: -1;
opacity: 0;
visibility: hidden
}

.tabs-contents .masonry-item .item .icon svg {
width: 100%;
height: 100%;
fill: #ffffff
}

.tabs-contents .masonry-item .item .content-box {
position: absolute;
left: 0;
bottom: 0;
height: 146px;
width: 100%;
padding: 7px;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: center;
-ms-flex-pack: center;
justify-content: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
z-index: 10;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-ms-flex-direction: column;
flex-direction: column;
gap: 7px
}

.tabs-contents .masonry-item .item .content-box .title {
display: block;
font-size: 18px;
font-weight: 600;
line-height: 22px;
letter-spacing: .02em;
color: #fff;
margin: 0
}

.tabs-contents .masonry-item .item .content-box .desc {
color: #fff
}

.tabs-contents .masonry-item .item .content-box::after {
content: "";
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: transparent;
opacity: .9;
z-index: -1;
-webkit-transition: background-color .5s ease-in-out;
transition: background-color .5s ease-in-out
}

.tabs-contents .masonry-item .item:hover .icon {
z-index: 5;
opacity: 1;
visibility: visible;
-webkit-transform: translate(-50%, -50%);
transform: translate(-50%, -50%)
}

.tabs-contents .masonry-item .item:hover .content-box::after {
background: #232323
}

.tabs-contents .portfolio-showmore-btn {
padding: 45px 15px;
text-align: center
}

.tabs-contents .portfolio-showmore-btn .button {
width: 100%;
max-width: 290px
}

.portfolio-with-sidebar .tabs-contents .portfolio-showmore-btn {
padding: 30px 15px
}

@media (min-width:768px) {
.shuffle-tabs .tabs {
-webkit-box-pack: center;
-ms-flex-pack: center;
justify-content: center
}
.tabs-contents .masonry-row {
margin-left: -10px;
margin-right: -10px
}
.tabs-contents .masonry-row.column-3 .masonry-item {
width: 50%;
padding: 10px
}
.tabs-contents .masonry-row.column-2 .masonry-item {
width: 50%;
padding: 15px
}
.tabs-contents .masonry-row.column-4 .masonry-item {
width: 50%;
padding: 15px
}
}

.portfolio-with-sidebar .shuffle-tabs .tabs {
display: block;
background: 0 0;
margin-top: 5px
}

.portfolio-with-sidebar .shuffle-tabs .tabs .tab {
padding: 11px 0;
width: 100%;
color: #232323;
border-top: 1px solid #787878
}

.portfolio-with-sidebar .shuffle-tabs .tabs .tab:last-child {
border-bottom: 1px solid #787878
}

@media (max-width:767px) {
.shuffle-tabs .haloPortfolioContent .desc {
padding: 15px 15px 25px
}
}

@media (min-width:1025px) {
.portfolio-page {
padding-bottom: 57px
}
.tabs-contents .masonry-row {
margin-left: -15px;
margin-right: -15px
}

.tabs-contents .masonry-row.column-4 .masonry-item {
width: 25%;
padding: 15px
}

.tabs-contents .masonry-row.column-3 .masonry-item {
width: 33.33333333%;
padding: 15px
}
.tabs-contents .masonry-row.column-2 .masonry-item {
width: 50%;
padding: 15px
}
.portfolio .page-sidebar+.page-content {
padding-left: 20px
}
}