.skin-4,
.skin-4 .desc {
    font-weight: 400;
    line-height: 26px;
    font-size: 16px;
    letter-spacing: 0.02em;
}
.skin-4 {
    color: #232323;
}
.skin-4 .h0,
.skin-4 .h1,
.skin-4 .h2,
.skin-4 .h3,
.skin-4 .h4,
.skin-4 .h5,
.skin-4 .nav-link,
.skin-4 h1,
.skin-4 h2,
.skin-4 h3,
.skin-4 h4,
.skin-4 h5 {
    letter-spacing: 0.02em;
}
.skin-4 input[type="color"],
.skin-4 input[type="date"],
.skin-4 input[type="datetime-local"],
.skin-4 input[type="datetime"],
.skin-4 input[type="email"],
.skin-4 input[type="month"],
.skin-4 input[type="number"],
.skin-4 input[type="password"],
.skin-4 input[type="search"],
.skin-4 input[type="tel"],
.skin-4 input[type="text"],
.skin-4 input[type="time"],
.skin-4 input[type="url"],
.skin-4 input[type="week"] {
    padding: 13px 12px;
    font-size: 16px;
}

.skin-4 .auth-actions .auth-link,
.skin-4 label {
    font-size: 16px;
    text-underline-offset: 0.3rem;
}
.skin-4 .desc {
    color: #3c3c3c;
}
.skin-4 .button {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.05em;
    padding: 13px 15px;
    border-width: 2px;
}
.skin-4 .button.button-1,
.skin-4 .button.button-2:hover {
    background: #000;
    border-color: #000;
    color: #fff;
    -webkit-box-shadow: 5px 5px 0 0 #a1decd;
    box-shadow: 5px 5px 0 0 #a1decd;
}
.skin-4 .button.button-1:hover {
    background: #a1decd;
    border-color: #a1decd;
    color: #000;
    -webkit-box-shadow: 5px 5px 0 0 #000;
    box-shadow: 5px 5px 0 0 #000;
}
.skin-4 .button.button-2 {
    background: #fff;
    border-color: #000;
    color: #000;
    -webkit-box-shadow: 5px 5px 0 0 #000;
    box-shadow: 5px 5px 0 0 #000;
}
.skin-4 .button.button-3 {
    background: #a1decd;
    border-color: #a1decd;
    color: #000;
}
.skin-4 .button.button-3:hover {
    background: #000;
    border-color: #a1decd;
    color: #a1decd;
}
.skin-4 .halo-cart-sidebar .previewCartItem-name,
.skin-4 .halo-cart-sidebar .previewCartItem-price,
.skin-4 .halo-notification-popup .halo-popup-content .product-content,
.skin-4 .halo-search-popup .header-search .search-bar .input-group-field,
.skin-4 .haloCalculatorShipping .message,
.skin-4 .header-search .search-bar .input-group-field,
.skin-4 .link,
.skin-4 .previewCartCheckbox input[type="checkbox"] + label,
.skin-4 .previewCartCheckbox input[type="checkbox"] ~ a,
.skin-4 .previewCartInfo .item,
.skin-4 .productView-information .productView-more .text,
.skin-4 .productView-information .productView-subtotal .text,
.skin-4 .productView-soldProduct .text,
.skin-4 .quickSearchResultsWrap .list-item .link,
.skin-4 .review-rating .spr-badge-caption {
    font-size: 16px;
}
.skin-4 .slick-dots {
    position: absolute;
    bottom: -40px;
}
.skin-4 .slick-dots li button {
    border-color: #232323;
    background: #232323;
}
.skin-4 .slick-dots li.slick-active button {
    border-color: #232323;
    background: 0 0;
}
.skin-4 .slick-arrow.slick-next svg,
.skin-4 .slick-arrow.slick-prev svg {
    top: -7px;
}
.skin-4 .halo-block-header {
    margin-bottom: 30px;
}
.skin-4 .halo-block-header .title {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.02em;
    color: #000;
}
.skin-4 .halo-block-header .title::before {
    display: none;
}
.skin-4 .card__badge .badge,
.skin-4 .halo-cart-sidebar .previewCartItem-options span,
.skin-4 .halo-edit-cart-popup .add-more,
.skin-4 .halo-edit-cart-popup .product-edit-itemCenter .product-edit-title-variant,
.skin-4 .halo-toolbar-bottom-mobile .halo-toolbar-item .label,
.skin-4 .product-card .card__badge .badge {
    font-size: 14px;
}
.skin-4 .product-card .card-icon .text {
    font-size: 14px;
    color: #232323;
}
.skin-4 .product-card .card-action {
    position: relative;
    opacity: 1;
    visibility: visible;
    bottom: 0;
}
.skin-4 .product-card .card-action .button {
    text-transform: capitalize;
}
.skin-4 .product-card .card-vendor {
    font-size: 16px;
    letter-spacing: 0.02em;
    font-weight: 700;
    color: #232323;
    margin-bottom: 6px;
}
.skin-4 .product-card .card-title {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.02em;
    color: #232323;
}
.skin-4 .product-card .card-swatch {
    margin: 15px 0 5px;
}
.skin-4 .product-card .product-card-bottom {
    padding: 17px 0 30px;
}
.skin-4 .product-card .card-price .price-item .money {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
}
.skin-4 .halo-toolbar-bottom-mobile .icon--cart .cart-count-bubble {
    background-color: #232323 !important;
    color: #fff;
}
.skin-4 .halo-search-popup .header-search .search-bar .icon-search {
    padding: 0;
}
.skin-4 .quickSearchResultsWrap .search-block-title {
    letter-spacing: 0.05em;
    font-style: normal;
}
.skin-4 .quickSearchResultsWrap .search-block-title .text {
    letter-spacing: 0.05em;
    color: #232323;
    font-style: normal;
    font-weight: 700;
}
.skin-4 .halo-sidebar_language_currency .nav-currency-language .title {
    color: #232323;
    font-style: normal;
    font-weight: 600;
    background: #dedede;
}
.skin-4 .halo-sidebar_language_currency .nav-currency-language .text,
.skin-4 .halo-sidebar_menu .site-nav-mobile.nav .menu-lv__list .nav-title-mobile .icon-dropdown,
.skin-4 .halo-sidebar_menu .site-nav-mobile.nav .menu-lv__list .nav-title-mobile .text {
    color: #232323 !important;
}
.skin-4 .halo-cart-sidebar .previewCartItem-edit:hover svg {
    fill: #a1decd;
}
.skin-4 .haloCalculatorShipping .progress.progress .progress-meter {
    background-color: #a1decd;
}
.skin-4 .halo-edit-cart-popup .product-edit-itemCenter .product-edit-title {
    font-size: 16px;
    font-weight: 500;
}
.skin-4 .halo-edit-cart-popup .product-edit-itemCenter .price-item .money,
.skin-4 .halo-quick-view-popup .card-price .price-item .money,
.skin-4 .halo-quickshop-popup .halo-productView-right .card-price .price-item .money {
    font-size: 18px;
}
.skin-4 .halo-edit-cart-popup .product-edit-action {
    padding: 0 30px 30px;
}
.skin-4 .halo-sidebar_menu .disclosure__item .disclosure__link .text,
.skin-4 .productView-variants .form__label,
.skin-4 .productView-variants .form__label [data-header-option] {
    font-size: 16px !important;
}
.skin-4 .productView-variants .product-form__label .text {
    font-size: 14px;
    position: relative;
    top: 1px;
}
.skin-4 .halo-newsletter-popup .newsletter-popup-title {
    font-size: 24px;
    margin: 0 0 20px;
}
.skin-4 .halo-newsletter-popup .newsletter-form__field-wrapper .field__input {
    padding-top: 13px;
    padding-bottom: 13px;
}
.skin-4 .halo-sidebar_menu .site-nav-mobile.nav .menu-lv-item .menu-lv-1__action {
    font-weight: 600;
    letter-spacing: 0.05em;
    font-size: 16px;
}
.skin-4 .halo-sidebar_menu .site-nav-mobile.nav .menu-lv__list .nav-title-mobile {
    background: #dedede !important;
    font-size: 16px;
}
.skin-4 .halo-sidebar_menu .site-nav-mobile.nav .menu-lv__list .nav-title-mobile .icon-dropdown svg,
.skin-4 .halo-sidebar_menu .site-nav-mobile.nav .menu-lv__list .nav-title-mobile .text svg {
    fill: #232323 !important;
}
.skin-4 .halo-sidebar_menu .nav-currency-language .title {
    background: #dedede;
    color: #232323;
}
.skin-4 .previewCartItem-qty .btn-quantity {
    top: 2px;
}
.skin-4 .halo-quick-view-popup .productView-image img {
    width: 100%;
}
.skin-4 .halo-quick-view-popup .productView-wishlist svg {
    position: relative;
    top: -2px;
}
.skin-4 .productView-information .previewCartAction .previewCartCheckbox {
    margin: 20px 0 15px;
}
@media (min-width: 768px) {
    .skin-4 .halo-block-header {
        margin-bottom: 42px;
    }
}
